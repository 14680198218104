import { Component, Input, OnInit } from '@angular/core';
import { GettestService } from '../../../../services/gettest.service';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { ConfigService } from 'src/app/core/helpers/config.service';

@Component({
  selector: 'tbt-result-minsize',
  templateUrl: './density.component.html',
  styleUrls: ['./density.component.css']
})
export class DensityComponent implements OnInit {

  nIdTest:string = "last";
  sTestType = "LOGO";
  sShowMode = "project";
  sVisibility = "show";
  
  nProcessPercentage = 0;

  nStepconfig = 0;

  sLogoDistanceSrc = 'assets/images/no-image.jpg';
  sLogoProblemSrc = 'assets/images/no-image.jpg';

  aResultTestInfo:any;

  @Input() set pshowmode (value: string)
    {
      if(value)
        {
          this.sShowMode = value;
        }
    }

  @Input() set pvisibility (value: string)
    {
      if(value)
        {
          this.sVisibility = value;
        }
    }


  constructor(private httpService: CallhttpService, private router: Router, private route: ActivatedRoute,  private httpClient: HttpClient, private notifyService:NotifyService, private configService:ConfigService) { }
 
  ngOnInit()
    {
      let oThis = this;
      /*this.getResult.callResult(this.nIdTest, this.sTestType, "proportions-density",
        
        // on start check
        function(nIdcheck)
            {
              oThis.nStepconfig = 1;
            },
        // on progress check
        function(nPercentage)
            {
              oThis.nProcessPercentage = nPercentage;
            },         
        // on complete check
          function(aData)
            {
              oThis.aResultTestInfo = aData;
              if(aData.processed_logo_d && aData.processed_logo_f)
                {
                  oThis.sLogoDistanceSrc = oThis.configService.TBT_HOSTIMAGES_API + aData.processed_logo_d;
                  oThis.sLogoProblemSrc = oThis.configService.TBT_HOSTIMAGES_API + aData.processed_logo_f;
                  setTimeout(function(){
                    oThis.nStepconfig = 2;
                  },1000)
            
                }
            }                
      );*/
    }

    onChangeTest(a)
      {
        this.nIdTest = a;
        this.ngOnInit();
      }

}
