import { Component, OnInit } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { ConfigService } from '../core/helpers/config.service';
import { NotifyService } from '../core/helpers/notify.service';
import { AuthService } from '../core/services/auth.service';
import { User } from '../core/services/users.service';
import { CallhttpService } from '../core/helpers/callhttp.service';
import { IfStmt } from '@angular/compiler';
import { Router } from '@angular/router';
import { Alert } from 'selenium-webdriver';

@Component({
  selector: 'app-proyects',
  templateUrl: './proyects.component.html',
  styleUrls: ['./proyects.component.css']
})
export class ProyectsComponent implements OnInit {

  aProjects: any = [];
  aLoginUser: User;
  bShowEmptyProjects = false
  sBoxUserClassification = ""

  bConfirmDelete = "";
  quantityValue: number = 0;
  receivedData: any;

  constructor(private router: Router, private httpService: CallhttpService, private notifyService: NotifyService, private configService: ConfigService, private authService: AuthService) {
    this.authService.currentUser.subscribe(x => this.aLoginUser = x);
  }


  /**************************************************************/
  /*
  * METHOD: On init this component, call all project of the user
  * INPUT: --
  * OUTPUT: --
  */
  /*************************************************************/

  ngOnInit() {
    this.getDataTestQuantity();
    let oThis = this;
    this.httpService.callHttpGET(this.configService.TBT_HOST_API + 'projects-getall/',
      function (aData) {
        oThis.bShowEmptyProjects = true;
        oThis.aProjects = aData;
      },
      true
    );
  }


  getDataTestQuantity() {
    const user_id = localStorage.getItem('user_id');
    const url = `${this.configService.TBT_HOST_API_DJANGO}testquantity/${user_id}`;

    this.httpService.callHttpGET(url, (data: any) => {
      if (data) {
        this.receivedData = data;
        this.quantityValue = parseFloat(this.receivedData.quantity);

        if (this.receivedData.quantity == undefined) {
          this.quantityValue = 0
        } else {
          this.quantityValue = parseFloat(this.receivedData.quantity);
        }
      } else {
        console.error('Error: Unexpected data from the server or missing data.');
      }
    });
  }


  /**************************************************************/
  /*
  * METHOD: Click on box user clasfication, to choise 
    desgner or businessman
  * INPUT: user type: DESIGNER/BUSINESSMAN
  * OUTPUT: --
  */
  /*************************************************************/

  onClickInBoxUserClassification(sTypeUserClassified) {
    if (sTypeUserClassified == "SEND" && this.sBoxUserClassification != "") {
      let aDatos = { user_classification: this.sBoxUserClassification }
      let oThis = this;
      this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'user-set-classification/', aDatos,
        function (aData) {
          oThis.router.navigate(['/project/payment/new']);
        }
      );
    }
    else {
      this.sBoxUserClassification = sTypeUserClassified
    }
  }

  setLocalState(){localStorage.setItem('category_bar', 'shape')};


  /**************************************************************/
  /*
  * METHOD: deleteProject: Method to delete to call backend to delete project 
  * INPUT: id project
  * OUTPUT: --
  */
  /*************************************************************/

  deleteProject(nIdProject: string) {
    let oThis = this;
    if (this.bConfirmDelete == nIdProject) {
      this.httpService.callHttpDELETE(this.configService.TBT_HOST_API + 'projects-delete/' + nIdProject,
        function (aData) {
          oThis.bConfirmDelete = "";
          oThis.ngOnInit();
        }
      );
    }
    else {
      setTimeout(function () {
        oThis.bConfirmDelete = "";
      }, 2000);
    }
  }

  /**************************************************************/
  /*
  * METHOD: deleteVersioN(DEPRECATED.)
  * INPUT: --
  * OUTPUT: --
  */
  /*************************************************************/

  deleteVersion(nIdProject: string, nIdVersion: string) {
    let oThis = this;
    this.httpService.callHttpDELETE(this.configService.TBT_HOST_API + 'projects-version-delete/' + nIdProject + "/" + nIdVersion,
      function (aData) {
        oThis.ngOnInit();
      }
    );
  }

}