export class Point {


    constructor(
        private x: number,
        private y: string,

    ){}
 

    public toString = function(){
        return "x: " + this.x + ", y: " + this.y;
    };
    
    public rotateRight = function(p1, p2){
        // cross product, + is counterclockwise, - is clockwise
        return ((p2.x*this.y-p2.y*this.x) - (p1.x*this.y-p1.y*this.x) + (p1.x*p2.y-p1.y*p2.x))<0;
    };

}
