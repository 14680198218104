import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../core/services/auth.service';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { TranslateService } from '@ngx-translate/core'; 
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotifyService } from '../../../app/core/helpers/notify.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class SectionStaticHomeComponent implements OnInit, OnDestroy {

  btnArray = [
    '../../../assets/svg/icon_bar_1.svg',
    '../../../assets/svg/icon_bar_2.svg',
    '../../../assets/svg/icon_bar_3.svg',
    '../../../assets/svg/icon_bar_4.svg',
    '../../../assets/svg/icon_bar_5.svg',
  ];

  private modalRef: NgbModalRef;
  currentLanguage: string;
  request_partner_form: FormGroup;

  // Frases rotativas en español e inglés
  phrases2 = {
    en: [
      "Business owner,",
      "Business owner,",
      "Designer,",
      "Designer,"
    ],
    es: [
      "Emprendedor,",
      "Emprendedor,",
      "Diseñador,",
      "Diseñador,"
    ]
  };

  phrases = {
    en: [
      "HOW CAN YOU KNOW IF YOUR COMPANY’S LOGO WILL BE EFFECTIVE, WITHOUT BEING A DESIGNER?",
      "DO YOU KNOW IF YOUR LOGO WILL BE COSTLY TO REPRODUCE? IT’S NOT JUST DESIGN, IT’S PRACTICALITY",
      "HOW CAN YOU TURN CLIENT OPINIONS INTO PROOF WITH DESIGN EFFECTIVENESS TESTS?",
      "HOW CAN YOU TURN PERCEPTIONS INTO CERTAINTIES FOR YOUR DESIGN?"
    ],
    es: [
      "¿CÓMO IDENTIFICAR si EL LOGO DE TU EMPRESA SERÁ EFECTIVO, SIN SER DISEÑADOR?",
      "¿SABES SI TU LOGO SERÁ COSTOSO DE REPRODUCIR? NO ES SOLO DISEÑO, ES PRACTICIDAD.",
      "¿CÓMO REDUCIR LAS OPINIONES SUBJETIVAS DE TUS CLIENTES CON PRUEBAS DE EFICACIA EN EL DISEÑO?",
      "¿CÓMO TRANSFORMAR PERCEPCIONES EN CERTEZAS SOBRE TU DISEÑO?"
    ]
  };

  currentTitle: string;
  currentPhrase: string;
  private phraseIndex: number = 0;
  private intervalId: any;

  constructor(
    private notifyService: NotifyService,
    private modalService: NgbModal,
    private router: Router,
    private authService: AuthService,
    private httpService: CallhttpService,
    private configService: ConfigService,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.currentLanguage = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(() => {
      this.currentLanguage = this.translateService.currentLang;
      this.phraseIndex = 0; 
      this.updatePhrase(); 
    });

    this.updatePhrase();
    this.startRotating();

    this.request_partner_form = new FormGroup({
      fullname: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', Validators.required),
    });
  }

  startRotating(): void {
    this.intervalId = setInterval(() => {
      this.phraseIndex = (this.phraseIndex + 1) % this.phrases[this.currentLanguage].length;
      this.updatePhrase();
    }, 7000);
  }

  updatePhrase(): void {
    this.currentTitle = this.phrases2[this.currentLanguage][this.phraseIndex];
    this.currentPhrase = this.phrases[this.currentLanguage][this.phraseIndex];
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  goToWeAnalize(index) {
    this.router.navigate(['/weanalyze/' + index]);
  }

  openModal(content: any) {
    this.modalRef = this.modalService.open(content, { centered: true });
  }

  onSubmit(modal: NgbModalRef) {
    if (this.request_partner_form.valid) {
      const payload = {
        ...this.request_partner_form.value,
        "name": this.request_partner_form.value.fullname,
        "mobile_code": "+57",
        "mobile": "1234567890"
      }
      this.httpService.callHttpPOST(
        this.configService.TBT_HOST_API_DJANGO + 'contactus',
        payload,
        (aData: any) => {
          this.notifyService.createNotify('success', 'Message sent successfully');
          modal.close();
        }, true
      );
    } else {
      this.request_partner_form.markAllAsTouched();
    }
  }
}
