import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-image',
  templateUrl: './modal-image.component.html',
  styleUrls: ['./modal-image.component.scss']
})
export class ModalImageComponent {
  @Input() title: string;
  @Input() imageUrl: string;

  constructor(private modalService: NgbModal) { }

  openPopup(content: any) {
    this.modalService.open(content, { size: 'lg' });
  }
}

