import { AnimationStyleMetadata } from '@angular/animations';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { User } from 'src/app/core/services/users.service';
import { GettestService } from '../../../services/gettest.service';

@Component({
  selector: 'app-shape',
  templateUrl: './shape.component.html',
  styleUrls: ['./shape.component.css']
})

export class ShapeComponent implements OnInit {

  nIdTest:string = "last";
  sTestType:string = "logo";
  sResultName:string = "all";
  nIdProject = 0;

  aResultTestResults:any;
  res_title: string = 'Test'; 

  nProcessPercentage = 5;

  nStepconfig = 0;

  bShowLoading = false;

  bTestProcessError = false;

  aBrandTestInfo = { code:String, title:String };
  aBrandTestReportInfo = { code:String, title:String, info_results:{}, report_id:"" };

  sLastTestType = "shape";

  @HostListener("window:scroll", [])

  aLoginUser:User;

  onWindowScroll() {
      if( document.documentElement.scrollTop > 0 ){
        $('.ir-arriba').show();
      } else {
        $('.ir-arriba').hide();
      }
  }

  localStorageCategoryBar: string;
  surveyFormColor: FormGroup;
  surveyFormVisibility: FormGroup;
  surveyFormCompetitors: FormGroup;
  surveyFormMaterial: FormGroup;

  alertFormColorError = false;
  alertFormColorOk = false;
  surveyDataColorTitle = "Para ver otros resultados del análisis Indíquenos que información le interesa conocer:";
  surveyDataColor = [
    { id: 1, name: 'question-1' },
    { id: 2, name: 'question-2' },
    { id: 3, name: 'question-3' },
    { id: 4, name: 'question-4' },
    { id: 5, name: 'question-5' },
    { id: 6, name: 'question-6' },
    { id: 7, name: 'question-7' },
    { id: 8, name: 'question-8' }
  ];

  alertFormVisibilityError = false;
  alertFormVisibilityOk = false;
  surveyDataVisibilityTitle = "Para ver otros resultados del análisis Indíquenos que información le interesa conocer::"
  surveyDataVisibility = [
    { id: 1, name: 'question-1' },
    { id: 2, name: 'question-2' },
    { id: 3, name: 'question-3' },
    { id: 4, name: 'question-4' },
    { id: 5, name: 'question-5' },
    { id: 6, name: 'question-6' },
    { id: 7, name: 'question-7' },
    { id: 8, name: 'question-8' }
  ];

  alertFormCompetitorsError = false;
  alertFormCompetitorsOk = false;
  surveyDataCompetitorsTitle = "Para ver otros resultados del análisis Indíquenos que información le interesa conocer::"
  surveyDataCompetitors = [
    { id: 1, name: 'question-1' },
    { id: 2, name: 'question-2' },
    { id: 3, name: 'question-3' },
    { id: 4, name: 'question-4' },
    { id: 5, name: 'question-5' },
    { id: 6, name: 'question-6' },
    { id: 7, name: 'question-7' },
    { id: 8, name: 'question-8' }
  ];

  alertFormMaterialError = false;
  alertFormMaterialOk = false;
  surveyDataMaterialTitle = "Para ver otros resultados del análisis Indíquenos que información le interesa conocer::"
  surveyDataMaterial = [
    { id: 1, name: 'question-1' },
    { id: 2, name: 'question-2' },
    { id: 3, name: 'question-3' },
    { id: 4, name: 'question-4' },
    { id: 5, name: 'question-5' },
    { id: 6, name: 'question-6' },
    { id: 7, name: 'question-7' },
    { id: 8, name: 'question-8' }
  ];


  get surveyFormColorArray() {
    return this.surveyFormColor.controls.surveys as FormArray;
  }
  get surveyFormVisibilityArray() {
    return this.surveyFormVisibility.controls.surveys as FormArray;
  }
  get surveyFormCompetitorsArray() {
    return this.surveyFormCompetitors.controls.surveys as FormArray;
  }
  get surveyFormMaterialArray() {
    return this.surveyFormMaterial.controls.surveys as FormArray;
  }


  constructor(private formBuilder: FormBuilder, private animateScrollService: NgAnimateScrollService,private router: Router, private getTest:GettestService, private httpService: CallhttpService, private configService:ConfigService, public authService:AuthService, )
    {
      let oThis = this;
      this.getTest.aBrandTestInfo.subscribe(function(aData:any) 
        { 
          //get id project
          if(aData && aData.id_project)
            {
              oThis.aBrandTestInfo = aData;
              oThis.nIdProject = aData.id_project;
              oThis.httpService.callHttpGET(oThis.configService.TBT_HOST_API + 'report-getone/' + oThis.nIdProject + "/", 
                function(aData)
                  {
                    oThis.aBrandTestReportInfo = aData;
                  },
                  true
              );
            }
        });  

        // suscribe to service authuser to get the current user.
        this.authService.currentUser.subscribe(x => this.aLoginUser = x);

        this.startSurvey();
    }

  startSurvey()
    {
      this.surveyFormColor = this.formBuilder.group({ surveys: new FormArray([])});
      this.surveyDataColor.forEach(() => this.surveyFormColorArray.push(new FormControl(false)));

      this.surveyFormVisibility = this.formBuilder.group({ surveys: new FormArray([])});
      this.surveyDataVisibility.forEach(() => this.surveyFormVisibilityArray.push(new FormControl(false)));

      this.surveyFormCompetitors = this.formBuilder.group({ surveys: new FormArray([])});
      this.surveyDataCompetitors.forEach(() => this.surveyFormCompetitorsArray.push(new FormControl(false)));

      this.surveyFormMaterial = this.formBuilder.group({ surveys: new FormArray([])});
      this.surveyDataMaterial.forEach(() => this.surveyFormMaterialArray.push(new FormControl(false))); 
    }  


  ngOnInit()
    {
      let oThis = this;
      this.bShowLoading = true;
      this.getTest.callResult(oThis.nIdTest, oThis.sTestType, oThis.sResultName,

          // start processing
          function() { oThis.nStepconfig = 1; }, 

          // processing progress
          function(nPercentage) {  oThis.nProcessPercentage = nPercentage; }, 
          
          // processing succesfull!
          function() { oThis.ngOnInit(); }, 

          // processing error.
          function() { oThis.bTestProcessError =  true;  }, 

          // results info ready
          function(aData) { oThis.bShowLoading = false; oThis.nStepconfig = 2; }
      );

      
    }

  onChangeTest(a,b)
    {
      this.nIdTest = a;
      this.sResultName = b;
      this.ngOnInit()
     
    }  

  onSaveChangesInResult(sResultName, aDataToSave)
    {
      this.getTest.callToSaveChangesInResult(sResultName, aDataToSave);
    }  

  onCancelProccesingLogo() {
    let oThis = this;
    this.getTest.onCancelProccesingLogo(function (nIdProject) {
      oThis.router.navigate(['/brandtest/' + nIdProject + "/add/logo/"]);
    });

    const payload = { user_id: localStorage.getItem('user_id') };
    this.httpService.callHttpPOST(this.configService.TBT_HOST_API_DJANGO + 'takeonetest', payload, (aData) => { });
  }



  onClickInButtonBar(sTestType, sCategory)
    {
    this.sLastTestType = sCategory;
    localStorage.setItem('category_bar', sCategory);
    this.localStorageCategoryBar = localStorage.getItem('category_bar');
    //localStorage.setItem('category_bar', 'shape');

      /*if(this.aBrandTestInfo["loaded_" + sTestType])
        {
          if(sTestType != this.sLastTestType)
            {
              this.sLastTestType = sTestType;
              this.router.navigate(['/brandtest/' + this.nIdProject + '/' + sTestType + "/"]);
            }
          else
            {
              this.animateScrollService.scrollToElement(sCategory, 1500);
            }  
        }
      else
        {
          this.router.navigate(['/brandtest/' + this.nIdProject + "/add/" + sTestType]);
        }*/
    }

  onopenInPreview(elem) 
    {
      $(elem.target).parent().toggle();
      $(elem.target).parent().next().toggle();
    }

  oncloseInPreview(elem) 
    {
      $(elem.target).toggle();
      $(elem.target).prev().toggle();
    }


  /**************************************************************/
    /*
    * METHOD: onClickConfirmReport, confirm report and save current status.
    * INPUT: --
    * OUTPUT: --
    */
  /*************************************************************/	

  onClickConfirmReport()
  {
    let oThis = this;
    let nReportId = this.aBrandTestReportInfo.report_id;
    let nIdProject = this.nIdProject;

    if(oThis.aLoginUser && oThis.aLoginUser.user_type == 'G')
      { 
        oThis.router.navigate(["/register/"], { queryParams:{'r':'/brandtest/' + oThis.nIdProject, 'c': '/brandtest/' + oThis.nIdProject }});
        return false;
      }

    this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'report-generate-confirm/', { project_id: nIdProject, report_id:nReportId }, 
    function(aData)
      {
        if(aData && aData.report_code)
          {
            oThis.router.navigate(["/r/" + aData.report_code]);
            //oThis.sVisibility = (oThis.sVisibility == 'show') ? 'hide' : 'show';
          }
      }
    ); 
  }  


  submitSurveyColor() {
    const selectedSurveysIds = this.surveyFormColor.value.surveys
      .map((checked, i) => checked ? this.surveyDataColor[i].id : null)
      .filter(v => v !== null);
    this.saveInfoSurvey(selectedSurveysIds, "color");
  }

  submitSurveyVisibility() {
    const selectedSurveysIds = this.surveyFormVisibility.value.surveys
      .map((checked, i) => checked ? this.surveyDataVisibility[i].id : null)
      .filter(v => v !== null);
    this.saveInfoSurvey(selectedSurveysIds, "visibility");
  }

  submitSurveyCompetitors() {
    const selectedSurveysIds = this.surveyFormCompetitors.value.surveys
      .map((checked, i) => checked ? this.surveyDataCompetitors[i].id : null)
      .filter(v => v !== null);
    this.saveInfoSurvey(selectedSurveysIds, "competitors");
  }

  submitSurveyMaterial() {
    const selectedSurveysIds = this.surveyFormMaterial.value.surveys
      .map((checked, i) => checked ? this.surveyDataMaterial[i].id : null)
      .filter(v => v !== null);
    this.saveInfoSurvey(selectedSurveysIds, "material");
  }

  saveInfoSurvey(selectedSurveysIds,sType)
    {
      let oThis = this;
      if(selectedSurveysIds.length > 0)
        {
          let aDatos ={};
          aDatos["type"] = sType;
          aDatos["selected"] = selectedSurveysIds;
          this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'save-survey/', aDatos, 
          function(aData)
            {
              if(sType == "color")
                {
                  oThis.alertFormColorOk = true;
                }
              if(sType == "visibility")
                {
                  oThis.alertFormVisibilityOk = true;
                }
              if(sType == "competitors")
                {
                  oThis.alertFormCompetitorsOk = true;
                }
              if(sType == "material")
                {
                  oThis.alertFormMaterialOk = true;
                }
                
              oThis.startSurvey(); 
              setTimeout(function(){
                oThis.alertFormColorOk = false;
                oThis.alertFormVisibilityOk = false;
                oThis.alertFormCompetitorsOk= false;
                oThis.alertFormMaterialOk = false;
              },5000); 
            }
          ); 
        }
      else
        {
          if(sType == "color")
            {
              oThis.alertFormColorError = true;
            }
          if(sType == "visibility")
            {
              oThis.alertFormVisibilityError = true;
            }
          if(sType == "competitors")
            {
              oThis.alertFormCompetitorsError = true;
            }
          if(sType == "material")
            {
              oThis.alertFormMaterialError = true;
            }

          setTimeout(function(){
            oThis.alertFormColorError = false;
            oThis.alertFormVisibilityError = false;
            oThis.alertFormCompetitorsError = false;
            oThis.alertFormMaterialError = false;
          },5000);  

        }  
    }


}
