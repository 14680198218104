import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { NgAnimateScrollService } from 'ng-animate-scroll';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { GettestService } from '../../../services/gettest.service';

@Component({
  selector: 'app-naming',
  templateUrl: './naming.component.html',
  styleUrls: ['./naming.component.css']
})
export class NamingComponent implements OnInit {


  nIdTest:string = "last";
  sTestType:string = "name";
  sResultName:string = "all";
  nIdProject = 0;

  aResultTestResults:any;

  nProcessPercentage = 0;

  nStepconfig = 0;

  bShowLoading = false;

  bTestProcessError = false;

  aBrandTestInfo = { code:String, title:String };

  sLastTestType = "";

  @HostListener("window:scroll", [])

  onWindowScroll() {

    if( document.documentElement.scrollTop > 0 ){
      $('.ir-arriba').show();
    } else {
      $('.ir-arriba').hide();
    }
  }

  constructor(private animateScrollService: NgAnimateScrollService,private router: Router, private getTest:GettestService, private httpService: CallhttpService, private configService:ConfigService)
    { 
      let oThis = this;
      this.getTest.aBrandTestInfo.subscribe(function(aData:any) 
        { 
          //get id project
          if(aData && aData.id_project)
            {
              oThis.aBrandTestInfo = aData;
              oThis.nIdProject = aData.id_project;
            }
        });  
    }

  ngOnInit()
    {
      let oThis = this;
      this.bShowLoading = true;
      this.getTest.callResult(oThis.nIdTest, oThis.sTestType, oThis.sResultName,

          // start processing
          function() { oThis.nStepconfig = 1; }, 

          // processing progress
          function(nPercentage) {  oThis.nProcessPercentage = nPercentage; }, 
          
          // processing succesfull!
          function() { oThis.ngOnInit(); }, 

          // processing error.
          function() { oThis.bTestProcessError =  true;  }, 

          // results info ready
          function(aData) { oThis.bShowLoading = false; oThis.nStepconfig = 2; }
      );
    }

  onChangeTest(a,b)
    {
      this.nIdTest = a;
      this.sResultName = b;
      this.ngOnInit()
    }


  onCancelProccesingLogo() {
    let oThis = this;
    this.getTest.onCancelProccesingLogo(function(nIdProject){
      oThis.router.navigate(['/brandtest/' + nIdProject + "/add/name/"]);
    });

  }

  onClickInButtonBar(sTestType, sCategory)
    {
      if(this.aBrandTestInfo["loaded_" + sTestType])
        {
          if(sTestType != this.sLastTestType)
            {
              this.sLastTestType = sTestType;
              this.router.navigate(['/brandtest/' + this.nIdProject + '/' + sTestType + "/"]);
            }
          else
            {
              this.animateScrollService.scrollToElement(sCategory, 1500);
            }  
        }
      else
        {
          this.router.navigate(['/brandtest/' + this.nIdProject + "/add/" + sTestType]);
        }
    }

  onopenInPreview(elem) 
    {
      $(elem.target).parent().toggle();
      $(elem.target).parent().next().toggle();
    }

  oncloseInPreview(elem) 
    {
      $(elem.target).toggle();
      $(elem.target).prev().toggle();
    }

}
