import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../../core/services/auth.service';
import { LoadingService } from '../../core/services/loading.service';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})

export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  bAccountCreated = false
  sReturnUrl = "";
  sCancelUrl = "";

  constructor(private router: ActivatedRoute, private formBuilder: FormBuilder, private authService: AuthService, private loadingService: LoadingService, private httpService: CallhttpService, private configService: ConfigService, private route: Router) {
    this.bAccountCreated = false;
    this.router.queryParams.subscribe(params => {
      if (params['r']) { this.sReturnUrl = params['r']; }
      if (params['c']) { this.sCancelUrl = params['c']; }
    });
  }

  get f() { return this.registerForm.controls; }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      fullname: ['', [Validators.required, Validators.pattern("^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$")]],
      usertype: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}')]],
      code: ['GUEST', [Validators.required]],
      termsandconditions: [false, [Validators.requiredTrue]]
    });
  }

  onSubmit() {
    this.submitted = true;
    // Detener aquí si el formulario no es válido
    if (this.registerForm.invalid) {
      return;
    }

    // Capturar el contexto de "this"
    let oThis = this;

    this.httpService.callHttpPOST(
      this.configService.TBT_HOST_API_DJANGO + 'user/signup',
      {
        fullname: this.registerForm.value.fullname,
        usertype: this.registerForm.value.usertype,
        email: this.registerForm.value.email,
        password: this.registerForm.value.password,
        code: this.registerForm.value.code,
        termsandconditions: this.registerForm.value.termsandconditions
      },
      // Utilizar una función de flecha en lugar de una función anónima
      (aData) => {
        console.log('aData => ', aData);
        if (aData.success) {
          // Si el usuario está conectado, actualizar su información
          if (oThis.authService.checkIfLoggedIn()) {
            oThis.authService.refreshUserInfo(() => {
              if (oThis.sReturnUrl !== "") {
                oThis.route.navigate([oThis.sReturnUrl]);
              } else {
                oThis.bAccountCreated = true;
              }
            });
          } else {
            if (oThis.sReturnUrl !== "") {
              oThis.route.navigate([oThis.sReturnUrl]);
            } else {
              oThis.bAccountCreated = true;
            }
          }
        }
      }
    );
  }

}
