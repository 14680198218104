import { Component, OnInit, Input, ViewEncapsulation, EventEmitter, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { LoadingService } from 'src/app/core/services/loading.service';

@Component({
  selector: 'button-element',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonElement implements OnInit {
  @Input()
  pclass:string;
  @Input()
  pclasstype:string;
  @Input()
  ptype:string;
  @Input()
  ptext:string;
  @Input()
  ploading:string;
  @Input()
  proute:string;
  @Input()
  pgetparams:object;
  @Input()
  pclick:any;
  @Input()
  picon:any;
  @Input()
  pimgicon:any;
  @Input()
  pdisabled:string;
  
  ngOnChanges(changes: SimpleChanges) {
    /** Fire params changes */
    if (changes.pclasstype && changes.pclasstype.currentValue) {
      this.pclasstype =  changes.pclasstype.currentValue;
      this.ngOnInit();
    }

    if (changes.ploading && changes.ploading.currentValue) {
      this.ploading =  changes.ploading.currentValue;
      this.ngOnInit();
    }

  }


  liveCheckLoading = false;

  bIfAjaxLoadingIcon:boolean = false;

  bIfClicked:boolean = false;

  pclassPriv = "";

  constructor(private loadingService:LoadingService) { }


  ngOnInit()
    {
        this.pclassPriv = "";
        if(this.pclasstype == "mainButton")
          {
            this.pclassPriv = this.pclass + " mainButton colorYellow-background colorDarkGrey-font fontDisplay5-fontweigth"
          }
        if(this.pclasstype == "secondButton")
          {
            this.pclassPriv = this.pclass + " secondButton colorAqua-font colorAqua-border fontDisplay5-fontweigth"
          }
        if(this.pclasstype == "thirdButton")
          {
            this.pclassPriv = this.pclass + " thirdButton colorWhite-font colorAqua-border colorAqua-background fontDisplay5-fontweigth"
          }
        if(this.pclasstype == "dangerButton")
          {
            this.pclassPriv = this.pclass + " dangerButton colorWhite-font colorDanger-border  colorDanger-background fontDisplay5-fontweigth"
          }


        if(this.ploading =="true")
          {
            this.loadingService.bIfloading.subscribe(value => {
              if(value == true)
                { 
                  if(this.bIfClicked)
                    {
                      this.liveCheckLoading = value; 
                    }
                } 
              else 
                { 
                  if(this.bIfClicked)
                    {
                      this.bIfClicked = false; 
                      this.liveCheckLoading = false; 
                    }  
                }
              }
            );
            this.bIfAjaxLoadingIcon = true;
          }
    }




  @Output() clicked: EventEmitter<any> = new EventEmitter();
  clickFunction(){
    this.bIfClicked = true;
    this.clicked.emit("-"); // Pass any payload as argument
  }



}