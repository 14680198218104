import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-como-usa',
  templateUrl: './como-usa.component.html',
  styleUrls: ['./como-usa.component.scss']
})
export class SectionStaticHowtouseComponent implements OnInit {
  request_partner_form: FormGroup;

  path = [
    {
      number: "1",
      icon: "./../../../assets/svg/Icon-Upload-logo.svg",
      title: 'howtouse-1-title',
      titleBold: 'howtouse-1-title-bold',
      text: 'howtouse-1-content'
    },
    {
      number: "2",
      icon: "./../../../assets/svg/Icon-Register.svg",
      title: 'howtouse-2-title',
      titleBold: 'howtouse-2-title-bold',
      text: 'howtouse-2-content'
    },
    {
      number: "3",
      icon: "./../../../assets/svg/Icon-Report.svg",
      title: 'howtouse-3-title',
      titleBold: 'howtouse-3-title-bold',
      text: 'howtouse-3-content'
      /*link: "Ver las 3 opciones de Test"*/
    },
    {
      number: "4",
      icon: "./../../../assets/svg/Icon-Share-Report.svg",
      title: 'howtouse-4-title',
      titleBold: 'howtouse-4-title-bold',
      text: 'howtouse-4-content'
    }
  ];

  btnArray = [
    './../../../assets/svg/barra-02.svg',
    './../../../assets/svg/barra-03.svg',
    './../../../assets/svg/barra-04.svg',
    './../../../assets/svg/barra-05.svg',
    './../../../assets/svg/barra-06.svg'
  ];

  testBasic = [
    [
      {
        text: "consectetur",
        active: true
      },
      {
        text: "aliquia",
        active: false
      },
      {
        text: "commodo",
        active: false
      },
      {
        text: "veniam",
        active: false
      }
    ],
    [
      {
        text: "tempor",
        active: true
      },
      {
        text: "excepteur",
        active: true
      },
      {
        text: "reprehenderit",
        active: true
      },
      {
        text: "deserunt",
        active: false
      }
    ],
    [
      {
        text: "voluptate",
        active: true
      },
      {
        text: "cillum",
        active: true
      },
      {
        text: "proident",
        active: true
      },
      {
        text: "ullamco",
        active: false
      }
    ],
    [
      {
        text: "adipiscing",
        active: true
      },
      {
        text: "cupidatat",
        active: true
      },
      {
        text: "occaecat",
        active: false
      }
    ],
    [
      {
        text: "mollit",
        active: true
      },
      {
        text: "ullamco",
        active: false
      }
    ],
    [
      {
        text: "culpa",
        active: true
      },
      {
        text: "laborum",
        active: false
      }
    ]
  ]

  constructor(private authService: AuthService, public translate: TranslateService, private modalService: NgbModal) { }


  ngOnInit(): void {
    // Inicializa el FormGroup y sus controles
    this.request_partner_form = new FormGroup({
      fullname: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl('', Validators.required),
    });
  }


  scrollTest() {
    document.getElementById('test').scrollIntoView();
  }

  openModal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  // Método para enviar el formulario
  onSubmit() {
    if (this.request_partner_form.valid) {
      //fetchdata - api - solicitar api
      console.log('data ====> ', this.request_partner_form.value);


    } else {
      this.request_partner_form.markAllAsTouched();
    }
  }
}
