import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { IconPrefix } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-casos',
  templateUrl: './casos.component.html',
  styleUrls: ['./casos.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SectionStaticUsesCasesComponent implements OnInit {

  casosUsuariosAb = [
    {
      img: "./../../../assets/images/statics/usescases/Uso01.png",
      titulo: 'Solución número 1 para<br><span class="casos__item-titulo-bold">Usuario A</span> y <span class="casos__item-titulo-bold">Usuario B</span>',
      texto: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor",
      btn: "Probar Gratis"
    },
    {
      img: "./../../../assets/images/statics/usescases/Uso02.png",
      titulo: 'Solución <span class="casos__item-titulo-bold">número 2</span> para<br>Usuario A y Usuario B',
      texto: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat",
      btn: "Probar Gratis"
    },
    {
      img: "./../../../assets/images/statics/usescases/Uso03.png",
      titulo: 'Solución <span class="casos__item-titulo-bold">número 3</span> para<br>Usuario A y Usuario B',
      texto: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor",
      btn: "Probar Gratis"
    },
    {
      img: "./../../../assets/images/statics/usescases/Uso04.png",
      titulo: 'Solución <span class="casos__item-titulo-bold">número 4</span> para<br>Usuario A y Usuario B',
      texto: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      btn: "Probar Gratis"
    },
  ];

  casosUsuariosC = [
    {
      img: "./../../../assets/images/statics/usescases/Uso05.png",
      titulo: 'Solución número 5 para<br><span class="casos__item-titulo-bold">Usuario C</span>',
      texto: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor",
      btn: "Probar Gratis"
    },
    {
      img: "./../../../assets/images/statics/usescases/Uso06.png",
      titulo: 'Solución <span class="casos__item-titulo-bold">número 6</span> para<br>Usuario C',
      texto: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat",
      btn: "Probar Gratis"
    },
    {
      img: "./../../../assets/images/statics/usescases/Uso07.png",
      titulo: 'Solución <span class="casos__item-titulo-bold">número 7</span> para<br>Usuario C',
      texto: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor",
      btn: "Probar Gratis"
    },
    {
      img: "./../../../assets/images/statics/usescases/Uso08.png",
      titulo: 'Solución <span class="casos__item-titulo-bold">número 8</span> para<br>Usuario C',
      texto: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      btn: "Probar Gratis"
    },
  ];

  casosUsuariosD = [
    {
      img: "./../../../assets/images/statics/usescases/Uso09.png",
      titulo: 'Solución <span class="casos__item-titulo-bold">número 9</span> para<br>Usuario D',
      texto: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor",
      btn: "Probar Gratis"
    },
    {
      img: "./../../../assets/images/statics/usescases/Uso10.png",
      titulo: 'Solución <span class="casos__item-titulo-bold">número 10</span> para<br>Usuario D',
      texto: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat",
      btn: "Probar Gratis"
    },
    {
      img: "./../../../assets/images/statics/usescases/Uso11.png",
      titulo: 'Solución <span class="casos__item-titulo-bold">número 11</span> para<br>Usuario D',
      texto: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor",
      btn: "Probar Gratis"
    },
    {
      img: "./../../../assets/images/statics/usescases/Uso12.png",
      titulo: 'Solución <span class="casos__item-titulo-bold">número 12</span> para<br>Usuario D',
      texto: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      btn: "Probar Gratis"
    },
  ];


  styleAb = ['fas', 'far', 'fas', 'fas'];
  iconAb = ['search-dollar', 'clock', 'certificate', 'dollar-sign'];

  styleCd = ['far', 'far', 'fas', 'fas'];
  iconCd = ['address-card', 'clock', 'coins', 'file-invoice-dollar'];


  unaIdeaAb = [
    {
      style: this.styleAb[0] as IconPrefix,
      logo: this.iconAb[0] as IconName,
      subtitulo: "Solución número 1",
      texto: "Clientes con una verdadera necesidad."
    },
    {
      style: this.styleAb[1] as IconPrefix,
      logo: this.iconAb[1] as IconName,
      subtitulo: "Solución número 2",
      texto: "Clientes con una verdadera necesidad."
    },
    {
      style: this.styleAb[2] as IconPrefix,
      logo: this.iconAb[2] as IconName,
      subtitulo: "Solución número 3",
      texto: "Clientes con una verdadera necesidad."
    },
    {
      style: this.styleAb[3] as IconPrefix,
      logo: this.iconAb[3] as IconName,
      subtitulo: "Solución número 4",
      texto: "Clientes con una verdadera necesidad."
    },
  ];

  unaIdeaC = [
    {
      style: this.styleCd[0] as IconPrefix,
      logo: this.iconCd[0] as IconName,
      subtitulo: "Solución número 5",
      texto: "Clientes con una verdadera necesidad."
    },
    {
      style: this.styleCd[1] as IconPrefix,
      logo: this.iconCd[1] as IconName,
      subtitulo: "Solución número 6",
      texto: "Clientes con una verdadera necesidad."
    },
    {
      style: this.styleCd[2] as IconPrefix,
      logo: this.iconCd[2] as IconName,
      subtitulo: "Solución número 7",
      texto: "Clientes con una verdadera necesidad."
    },
    {
      style: this.styleCd[3] as IconPrefix,
      logo: this.iconCd[3] as IconName,
      subtitulo: "Solución número 8",
      texto: "Clientes con una verdadera necesidad."
    },
  ];

  unaIdeaD = [
    {
      style: this.styleCd[0] as IconPrefix,
      logo: this.iconCd[0] as IconName,
      subtitulo: "Solución número 9",
      texto: "Clientes con una verdadera necesidad."
    },
    {
      style: this.styleCd[1] as IconPrefix,
      logo: this.iconCd[1] as IconName,
      subtitulo: "Solución número 10",
      texto: "Clientes con una verdadera necesidad."
    },
    {
      style: this.styleCd[2] as IconPrefix,
      logo: this.iconCd[2] as IconName,
      subtitulo: "Solución número 11",
      texto: "Clientes con una verdadera necesidad."
    },
    {
      style: this.styleCd[3] as IconPrefix,
      logo: this.iconCd[3] as IconName,
      subtitulo: "Solución número 12",
      texto: "Clientes con una verdadera necesidad."
    },
  ];
  constructor() { }

  ngOnInit(): void {
  }

}
