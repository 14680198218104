import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'window-result',
  templateUrl: './window-result.component.html',
  styleUrls: ['./window-result.component.css']
})
export class WindowResultComponent implements OnInit {

  @Input()
  pclass:string;

  @Input()
  ptitle:string;

  @Input()
  piconimg:string;

  constructor() { }

  ngOnInit() {
  }

}
