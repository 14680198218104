import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GettestService } from '../../../../services/gettest.service';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { Colorwheel } from '../harmony/colorwheel';

@Component({
  selector: 'tbt-result-visibility',
  templateUrl: './visibility.component.html',
  styleUrls: ['./visibility.component.css']
})
export class VisibilityComponent implements OnInit {

  @Output() ponchangetest: EventEmitter<any> = new EventEmitter();
  @Output() ponchangetosave: EventEmitter<any> = new EventEmitter();


  sMainLogoSrc = 'assets/images/no-image.jpg';

  sTestName = "color-visibility";
  sTestType = "logo";

  sShowMode = "project";
  sVisibility = "show";
  
  nProcessPercentage = 0;

  nStepconfig = 0;

  aResultTestInfo:any;

  aTestPerformed:any;
  aTestPerformedTBT:any;

  RGBBackList = [
    "#ff0000",
    "#ff4000",
    "#ff8000",
    "#ffc000",
    "#ffff00",
    "#ffff60",
    "#bfff00",
    "#7fff00",
    "#3fff00",
    "#00ff00",
    "#00ff40",
    "#00ff80",
    "#00ffc0",
    "#00ffff",
    "#00bfff",
    "#007fff",
    "#003fff",
    "#0000ff",
    "#4000ff",
    "#8000ff",
    "#c000ff",
    "#ff00ff",
    "#ff00bf",
    "#ff007f",
    "#ff003f"
  ];
  
  RGBBackList2 = [
    "#d4d4d4",
    "#b4b4b4",
    "#909090",
    "#636363",
    "#494848"
  ];


  /*RGBBackList = [
    "#FF2201",
    "#FF7600",
    "#FFFC00",
    "#77FB00",
    "#08FA00",
    "#04FB76",
    "#00FDFF",
    "#0077FF",
    "#002EFF",
    "#7630FF",
    "#FE3AFF",
    "#FE2876"
];*/

  colorook = []
  colorbad = []
  colorbad_2 = []


  @Input() set pshowmode (value: string)
    {
      if(value)
        {
          this.sShowMode = value;
        }
    }

  @Input() set pvisibility (value: string)
    {
      if(value)
        {
          this.sVisibility = value;
        }
    }

  aBrandTestResults:any;
  
  constructor(private getTest:GettestService, private httpService: CallhttpService, private router: Router, private route: ActivatedRoute,  private httpClient: HttpClient, private notifyService:NotifyService, private configService:ConfigService) { }

  ngOnInit()
    {
      let oThis = this;
      this.getTest.aBrandTestResults.subscribe(function(aData) 
        { 
          oThis.aBrandTestResults = aData;
          if(oThis.aBrandTestResults[oThis.sTestName])
            {
              let aData = oThis.aBrandTestResults[oThis.sTestName];
              oThis.aResultTestInfo = aData;
              if(aData.colors && aData.transparent_logo_simple)
                  {
                    oThis.nStepconfig = 2;

                    oThis.aTestPerformed = aData.info_tests_performed;
                    oThis.aTestPerformedTBT = aData.info_tests_tbt;

                    oThis.nStepconfig = 2;
                    setTimeout(function(){
                      oThis.sMainLogoSrc = aData.transparent_logo_simple;                    
                      oThis.drawBackgroundCompare(aData.colors);  
                    },500)

                  }
            }
        }); 

    }

  drawBackgroundCompare(RGBList) {

    let colorWheel = new Colorwheel();

    var elem =  this;
    var colors = [];
    var nc = 0
    RGBList = colorWheel.sortByKey(RGBList, 2);
    RGBList.forEach(function (color) {
        var [h, s, v, i] = colorWheel.rgbToHsv(color[0], color[1], color[2]);
        var ifGrey = (Math.abs(color[0]-color[1]) < 40 && Math.abs(color[0]-color[2]) < 40 && Math.abs(color[1]-color[2]) < 40)
        if(!colors.includes(i) && (s > 0.1 && v > 0.1 && !ifGrey) && colors.length < 4)
          {
            var b = elem.hexComplimentary(color[0], color[1], color[2], 120);
            elem.colorook.push(b);

            var bc = elem.hexComplimentary(color[0], color[1], color[2], 180);
            elem.colorook.push(bc);

            var ac = elem.hexComplimentary(color[0], color[1], color[2], 160);
            elem.colorook.push(ac);

            var a = elem.hexComplimentary(color[0], color[1], color[2], 90);
            elem.colorook.push(a);



            var b1 = elem.hexComplimentary(color[0], color[1], color[2], 10);
            elem.colorbad.push(b1);

            var b2 = elem.hexComplimentary(color[0], color[1], color[2], 20);
            elem.colorbad.push(b2);

            var b3 = elem.hexComplimentary(color[0], color[1], color[2], -10);
            elem.colorbad_2.push(b3);

            var b4 = elem.hexComplimentary(color[0], color[1], color[2], -20);
            elem.colorbad_2.push(b4);



            colors[nc] = i;
            nc++;
          }
    });

      elem = this;

      console.log(colors);  
      colors.forEach(function (color) {
          // background correctos
          let diff = Math.abs(23 - color);
          if(color<12) { diff = 12; }
          /*checkcolorOK(diff, colors, color, 3)  
          
          console.log(elem.colorook)


          var colorbad1 = color - 6    
          var colorbad2 = color + 6    
      
          colorbad1 = (colorbad1 < 0 ? 23 : colorbad1);
          colorbad1 = (colorbad1 > 23 ? 0 : colorbad1);
      
          colorbad2 = (colorbad2 < 0 ? 23 : colorbad2);
          colorbad2 = (colorbad2 > 23 ? 0 : colorbad2);
      
          if(!elem.colorbad.includes(colorbad1))
            {
              elem.colorbad.push(colorbad1);
            }
      
          if(!elem.colorbad_2.includes(colorbad2))
            {
              elem.colorbad_2.push(colorbad2);
            }


          function checkcolorOK(diff, colors, color, mult=2)
            {
              console.log("color:" + color);
              for(var i=0;i<diff;i++)
                {
                  let ctp = color + i;
                  let j=0;
                  for(let pc in colors)
                    {
                      if(Math.abs(ctp - colors[pc]) >= mult)
                        {
                          let k=0;
                          for(let cok in elem.colorook)
                            {
                              if(Math.abs(ctp - elem.colorook[cok]) >= mult)
                                {
                                  k++;
                                }
                            }      
                          if(k == elem.colorook.length)
                            {
                              j++;
                            }    
                        }
                    }

                  if(j == colors.length)
                    {
                      if(!elem.colorook.includes(ctp))
                        {
                          console.log("complement:" + ctp);
                          elem.colorook.push(ctp);
                          return false;
                        }  
                    }  
                }


            }*/
      
      });

  }

  hexComplimentary(r, g, b, sum){

    var max = Math.max(r, g, b);
    var min = Math.min(r, g, b);
    var h, s, l = (max + min) / 2.0;

    if(max == min) {
        h = s = 0;  //achromatic
    } else {
        var d = max - min;
        s = (l > 0.5 ? d / (2.0 - max - min) : d / (max + min));

        if(max == r && g >= b) {
            h = 1.0472 * (g - b) / d ;
        } else if(max == r && g < b) {
            h = 1.0472 * (g - b) / d + 6.2832;
        } else if(max == g) {
            h = 1.0472 * (b - r) / d + 2.0944;
        } else if(max == b) {
            h = 1.0472 * (r - g) / d + 4.1888;
        }
    }

    h = h / 6.2832 * 360.0 + 0;

    // Shift hue to opposite side of wheel and convert to [0-1] value
    h+= sum;
    if (h > 360) {  h -= 360; }
    h /= 360;

    h = Math.abs(h);

    if(s === 0){
        r = g = b = l; // achromatic
    } else {
        var hue2rgb = function hue2rgb(p, q, t){
            if(t < 0) t += 1;
            if(t > 1) t -= 1;
            if(t < 1/6) return p + (q - p) * 6 * t;
            if(t < 1/2) return q;
            if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
            return p;
        };

        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;

        r = hue2rgb(p, q, h + 1/3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1/3);
    }

    r = Math.round(r);
    g = Math.round(g); 
    b = Math.round(b);

    // Convert r b and g values to hex
    //rgb = b | (g << 8) | (r << 16); 
    return [r,g,b]
}

onChangeTest(nIdTest)
  {
    this.ponchangetest.emit([nIdTest, this.sTestName]); 
    this.sMainLogoSrc = 'assets/images/no-image.jpg';
  }


}
