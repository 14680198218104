import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { User } from 'src/app/core/services/users.service';

@Component({
  selector: 'app-list-reports',
  templateUrl: './list-reports.component.html',
  styleUrls: ['./list-reports.component.css']
})
export class ListReportsComponent implements OnInit {

  aLoginUser:User;
  nIdProject:string;

  constructor(private route: ActivatedRoute, private httpService: CallhttpService, private configService:ConfigService, public authService:AuthService)
    {
      this.authService.currentUser.subscribe(x => this.aLoginUser = x);
    }

  /**************************************************************/
    /*
    * METHOD: methd to call all info necesary to render screen
    * INPUT: idproject if edit
    * OUTPUT: --
    */
  /*************************************************************/	


  ngOnInit()
    {
      this.route.paramMap.subscribe(params => {
        this.nIdProject = params.get("id_project")
        if(this.nIdProject)
          {      
            let oThis = this;
            this.httpService.callHttpGET(this.configService.TBT_HOST_API + 'reports-getall/' + this.nIdProject, 
              function(aData)
                {
                  if(aData.list_reports)
                    {
                      let i= 1;
                      if(aData.list_reports.length > 0)
                        {
                          $("#idBlocProjectReports").html("");                          
                        }
                      aData.list_reports.forEach(rData => {
                        $("#idBlocProjectReports").append("<tr><th scope='row'>"+i+"</th><td>"+rData.proyect+"</td><td>"+rData.code+"</td><td>"+rData.visualizations+"</td><td>"+rData.date+"</td> <td><a href='/r/"+ rData.code +"'>VER</a></td></tr>");
                         i++; 
                        //oThis.aPricesPlans[rData.reference] = rData;
                      });
                    }
                }
            );  
          }
      });     
    }







}
