import { ViewportScroller } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { min } from 'rxjs/operators';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { PriceLogoService } from 'src/app/core/services/price-logo.service';

@Component({
  selector: 'app-precios',
  templateUrl: './precios.component.html',
  styleUrls: ['./precios.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class SectionStaticPricesComponent implements OnInit {

  dateInit: string;
  dateEnd: string;
  userId: number;
  transactions: any[]; 

  selectedValue: number = 2;
  aPricesPlans = [];
  aSliderQuantity = [];

  comission: number = 0;
  min_price: number = 2;
  max_price: number = 100;
  comission_value: number = 0;
  comission_type: string = '';

  receivedData: any;
  quantityValue: number = 0;

  localUserId = localStorage.getItem("user_id");;

  aSliderSelected = 1;
  nSliderCurrentQuantity = [];

  aSliderPrice = [];
  aSliderDiscount = [];

  tableArray = [
    {
      header: "Free",
      headerClass: "header-left demo",
      titleClass: "title-left colorMediumGrey-background",
      textClass: "text-left",
      colorBar: "demo",
      testItems: ["5 Tests"],
      certItems: ["Unlimited"],
      servItems: [{ value: "Unlimited", class: "fontDisplay5-fontweigth" }],
      toolsItems: [{ value: "Project Planning", class: "fontDisplay5-fontweigth" }, { value: "-", class: "fontDisplay5-fontweigth" }, { value: "-", class: "fontDisplay5-fontweigth" }],
      resItems: [{ value: "Shape", class: "colorVeryLightGrey-background" }, { value: "Efficiency", class: "fontDisplay5-fontweigth" }, { value: "Color", class: "colorVeryLightGrey-background" }, { value: "-", class: "fontDisplay5-fontweigth" }, { value: "Proportions", class: "colorVeryLightGrey-background" }, { value: "-", class: "fontDisplay5-fontweigth" }, { value: "-", class: "fontDisplay5-fontweigth" }, { value: "-", class: "fontDisplay5-fontweigth" }, { value: "Competitors", class: "colorVeryLightGrey-background" }, { value: "-", class: "fontDisplay5-fontweigth" }, { value: "Simulations", class: "colorVeryLightGrey-background" }, { value: "-", class: "fontDisplay5-fontweigth" }],
      clubItems: [{ value: "-", class: "" }, { value: "-", class: "" }, { value: "-", class: "" }, { value: "-", class: "border-rounded" }],
      btn: "START NOW",
      btnUrl: "/prices"
    },
    {
      header: "Basic",
      headerClass: "header-left basic",
      titleClass: "title-left colorGreenLight-background",
      textClass: "text-left",
      colorBar: "basic",
      testItems: ["1, 2, 5 ó 10 Tests"],
      certItems: ["Unlimited"],
      servItems: [{ value: "Unlimited", class: "fontDisplay5-fontweigth" }],
      toolsItems: [{ value: "Project Planning", class: "fontDisplay5-fontweigth" }, { value: "Briefing", class: "fontDisplay5-fontweigth" }, { value: "-", class: "fontDisplay5-fontweigth" }],
      resItems: [{ value: "Shape", class: "colorVeryLightGrey-background" }, { value: "Efficiency", class: "fontDisplay5-fontweigth" }, { value: "Color", class: "colorVeryLightGrey-background" }, { value: "Contrast", class: "fontDisplay5-fontweigth" }, { value: "Proportions", class: "colorVeryLightGrey-background" }, { value: "Visibility", class: "fontDisplay5-fontweigth" }, { value: "-", class: "fontDisplay5-fontweigth" }, { value: "-", class: "fontDisplay5-fontweigth" }, { value: "Competitors", class: "colorVeryLightGrey-background" }, { value: "Similarities", class: "fontDisplay5-fontweigth" }, { value: "Simulations", class: "colorVeryLightGrey-background" }, { value: "Digital Environment", class: "fontDisplay5-fontweigth" }],
      clubItems: [{ value: "-", class: "" }, { value: "-", class: "" }, { value: "-", class: "" }, { value: "-", class: "border-rounded" }],
      btn: "ENABLE"
    },
    {
      header: "Pro",
      headerClass: "header-left",
      titleClass: "title-left colorAquaLight-background",
      textClass: "text-left",
      colorBar: "pro",
      testItems: ["20, 30, 50 ó 100 Tests"],
      certItems: ["Unlimited"],
      servItems: [{ value: "Unlimited", class: "fontDisplay5-fontweigth" }],
      toolsItems: [{ value: "Project Planning", class: "fontDisplay5-fontweigth" }, { value: "Briefing", class: "fontDisplay5-fontweigth" }, { value: "Budget", class: "fontDisplay5-fontweigth" }],
      resItems: [{ value: "Shape", class: "colorVeryLightGrey-background" }, { value: "Efficiency", class: "fontDisplay5-fontweigth" }, { value: "Color", class: "colorVeryLightGrey-background" }, { value: "Contrast", class: "fontDisplay5-fontweigth" }, { value: "Proportions", class: "colorVeryLightGrey-background" }, { value: "Visibility", class: "fontDisplay5-fontweigth" }, { value: "Balance", class: "fontDisplay5-fontweigth" }, { value: "Minimum Sizes", class: "fontDisplay5-fontweigth" }, { value: "Competitors", class: "colorVeryLightGrey-background" }, { value: "Similarities", class: "fontDisplay5-fontweigth" }, { value: "Simulations", class: "colorVeryLightGrey-background" }, { value: "Digital Environment", class: "fontDisplay5-fontweigth" }],
      clubItems: [{ value: "-", class: "" }, { value: "-", class: "" }, { value: "-", class: "" }, { value: "-", class: "border-rounded" }],
      btn: "ENABLE"
    }
  ];

  constructor(private notifyService: NotifyService, private scroll: ViewportScroller, private httpService: CallhttpService, private configService: ConfigService, public authService: AuthService, private router: Router, private httpClient: HttpClient, private priceLogoService: PriceLogoService) { }

  /**************************************************************/
  /*
  * METHOD: ngOnInit: Call to backend info about prices.
  * INPUT: URL audio
  * OUTPUT: --
  */
  /*************************************************************/

  ngOnInit() {
    let oThis = this;
    this.min_price = 2;
    this.getDataTestQuantity();

    const user_id = localStorage.getItem("user_id");
    this.priceLogoService.getPriceLogo()
      .subscribe(res => {
        if (res && res.data && res.data.price) {
          this.selectedValue = res.data.price;
        } else {
          // Manejar el caso en que res.data sea undefined o no tenga la propiedad price
          console.error("No se pudo obtener el precio del logo.");
        }
      });

   /*  this.httpService.callHttpGET(this.configService.TBT_HOST_API + 'tbt-get-plans/',
      function (aData) {
        if (aData) {
          aData.forEach(rData => {

            let nPrice;
            let nPriceDiscount;
            let aDescription = rData.description.split("***");
            let sBigTitle = aDescription.shift();
            let aQuantity = rData.quantity.split("*");
            if (aQuantity.length > 1) {
              oThis.aSliderQuantity[rData.reference] = aQuantity;
              oThis.aSliderPrice[rData.reference] = rData.real_price.split("*");
              oThis.aSliderDiscount[rData.reference] = rData.total_price.split("*");
              oThis.nSliderCurrentQuantity[rData.reference] = oThis.aSliderQuantity[rData.reference][oThis.aSliderSelected];

              nPrice = oThis.aSliderPrice[rData.reference][oThis.aSliderSelected];
              nPriceDiscount = oThis.aSliderDiscount[rData.reference][oThis.aSliderSelected];

            }
            else {
              nPrice = rData.real_price;
              nPriceDiscount = rData.total_price;
            }

            oThis.aPricesPlans.push({
              icon: "./../../../assets/svg/precios-" + rData.reference + ".svg",
              header: rData.title,
              colorBar: rData.reference,
              flag: sBigTitle,
              slider: aQuantity.length > 1 ? true : false,
              sliderStart: 2,
              results: aDescription,
              priceSmall: nPrice,
              priceBig: nPriceDiscount,
              btn: "ENABLE",
              disabled: rData.disabled == 'Y' ? true : false
            });

            //oThis.aPricesPlans[rData.reference] = rData;
          });
        }
      }, true
    ); */

    this.httpService.callHttpGET(this.configService.TBT_HOST_API_DJANGO + `comission/${user_id}`,
      (aData) => {
        if (aData) {
          if (aData.type == 'percentage') {
            this.comission_value = this.selectedValue * aData.value / 100;
            this.comission = this.selectedValue - this.comission_value;
          } else {
            this.comission = this.selectedValue - aData.value;
          }
        } else {
          console.error("No se pudo obtener la comisión del logo.");
        }
      }, true
    );

    this.httpService.callHttpGET(this.configService.TBT_HOST_API_DJANGO + `pricerange/${user_id}`,
      (aData) => {
        if (aData) {
          this.max_price = aData.max_price !== undefined ? aData.max_price : 100;
          this.min_price = aData.min_price !== undefined ? (aData.min_price < 1.50 ? 1.50 : aData.min_price) : 1;
        } else {
          console.error("No se pudo obtener la comisión del logo.");
        }
      }, true
    );

  }



  getDataTestQuantity() {
    const user_id = localStorage.getItem('user_id');
    const url = `${this.configService.TBT_HOST_API_DJANGO}testquantity/${user_id}`;

    this.httpService.callHttpGET(url, (data: any) => {
      if (data) {
        this.receivedData = data;
        if (this.receivedData.quantity == undefined) {
          this.quantityValue = 0
        } else {
          this.quantityValue = parseFloat(this.receivedData.quantity);
        }
      } else {
        console.error('Error: Unexpected data from the server or missing data.');
      }
    });
  }

  getSliderValue(event: any, sReference: any) {
    var nSelectedValue = event.target.value - 1;
    document.querySelector(".idBlockSlideValue-" + sReference).innerHTML = this.aSliderQuantity[sReference][nSelectedValue];
    this.nSliderCurrentQuantity[sReference] = this.aSliderQuantity[sReference][nSelectedValue];

    document.querySelector(".idBlockSlidePrice-" + sReference).innerHTML = this.aSliderPrice[sReference][nSelectedValue];
    document.querySelector(".idBlockSlidePriceDiscount-" + sReference).innerHTML = this.aSliderDiscount[sReference][nSelectedValue];
  }

  /* CLICK EN "ACTIVAR BOTON EN PLANES DE PRECIOS */
  /* LLAMO AL BACKEND Y DEJO EL SESION EL PEDIDO PARA LA SIGUIENTE PANTALLA */
  onClickInBuyPlan(sPlanReference = "basic") {
    let nSelectedQuantity;
    if (sPlanReference == "basic" || sPlanReference == "pro") {
      //get value of slider
      nSelectedQuantity = this.nSliderCurrentQuantity[sPlanReference];
    }
    else {
      nSelectedQuantity = 1;
    }

    let oThis = this;
    this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'tbt-set-selected-plan/', { plan_type: sPlanReference, plan_quantity: nSelectedQuantity },
      function (aData) {
        if (aData.redirect) {
          oThis.router.navigate(['/' + aData.redirect + '/']);
        }
      }
    );
  }

  onClickSetPriceLogo() {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache'
    });

    const options = {
      headers: httpHeaders,
      withCredentials: true
    };

    const payload = {
      user_id: this.localUserId,
      price: this.selectedValue
    };

    this.httpClient.put<any>(this.configService.TBT_HOST_API_DJANGO + 'pricelogo/edit', payload, options).subscribe(
      (res) => {
        if (res.response) {
          const language = localStorage.getItem('locale');
          if (language == 'es') {
            this.notifyService.createNotify('success', res.code_es);
          } else {
            this.notifyService.createNotify('success', res.code_en);
          }
        } else {
          console.error('Error updating price logo:', res.error);
        }
      },
      (error) => {
        console.error('Error updating price logo:', error);
      }
    );
  }

  scrollToTop() {
    this.scroll.scrollToPosition([0, 0]);
  }

  onSliderChange(event: any) {
    this.selectedValue = event.target.valueAsNumber;
    const user_id = localStorage.getItem("user_id");
    this.httpService.callHttpGET(this.configService.TBT_HOST_API_DJANGO + `comission/${user_id}`,
      (aData) => {
        if (aData) {
          
          if (aData.type == 'percentage') {
            this.comission = this.selectedValue * aData.value / 100
            if (this.selectedValue <= this.min_price) {
              this.comission = 0;
            }
            if (this.selectedValue - (this.selectedValue * aData.value / 100) <= this.min_price) {
              this.comission = this.selectedValue - this.min_price
            }
          } else {
            if (this.selectedValue <= this.min_price) {
              this.comission = 0;
            } else {
              this.comission = this.selectedValue - this.min_price;
              this.min_price = Math.round(aData.value);
            }
          }

        } else {
          console.error("No se pudo obtener la comisión del logo.");
        }
      }, false
    );
  }
}


