import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { User } from 'src/app/core/services/users.service';

@Component({
  selector: 'app-list-tests',
  templateUrl: './list-tests.component.html',
  styleUrls: ['./list-tests.component.css']
})

export class ListTestsComponent implements OnInit {

  aLoginUser:User;
  nIdProject:string;

  constructor(private route: ActivatedRoute, private httpService: CallhttpService, private configService:ConfigService, public authService:AuthService)
    {
      // suscribe to service authuser to get the current user.
      this.authService.currentUser.subscribe(x => this.aLoginUser = x);
    }

  /**************************************************************/
    /*
    * METHOD: On init this component, call all test from this user
    * INPUT: --
    * OUTPUT: --
    */
  /*************************************************************/	

  ngOnInit()
    {
      let oThis = this;
      this.httpService.callHttpGET(this.configService.TBT_HOST_API + 'brandtest-getall/', 
        function(aData)
          {
            if(aData.list_tests)
              {
                let i= 1;
                $("#idBlocProjectTests").html("");
                aData.list_tests.forEach(rData => {
                  //console.log(rData);
                  $("#idBlocProjectTests").append("<tr><td scope='row'>"+i+"</td><td>"+(rData.test_type=='logo' ? 'LOGO':'NOMBRE')+"</td><td class='text-center'>"+(rData.test_type=='logo' ? "<img width='50' src="+oThis.configService.TBT_HOSTIMAGES_API + atob(rData.test_object) +" />":"<span class='text-uppercase'>"+rData.test_object+"</span>")+"</td><td>"+rData.project+"</td><td>"+rData.code+"</td><td>"+rData.date+"</td></tr>");
                    i++; 
                });
              }
          }
      );  
    }

  }
