/// <reference path="../../../../node_modules/@types/googlemaps/index.d.ts" />

import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { find, get, pull } from 'lodash';

@Component({
  selector: 'tbt-input-tags',
  templateUrl: './input-tags.component.html',
  styleUrls: ['./input-tags.component.css'],
})

/**************************************************************/
    /*
    * INPUT TAG: Component to create a input to inset tags. 
    */
/*************************************************************/	

export class InputTagsComponent implements OnInit {

  @ViewChild('tagInput', {static: false}) tagInputRef: ElementRef;

  @Input()
  pifinvalid:string;

  @Input()
  pmode:string;

  @Input()
  pplaceholder:string;

  @Output() ponchange: EventEmitter<any> = new EventEmitter();


  tags: string[] = []; // ['inital value'];
  tagsInfo = []; // ['inital value'];

  form: FormGroup;

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.form = this.fb.group({
      tag: [undefined],
    });
  }

  ngAfterViewInit() {
    if(this.pmode == 'autocompleteregions')
      {
        this.getPlaceAutocomplete();
      }
  }

  /**************************************************************/
      /*
      * METHOD: focusTagInput: Method to set the focus in the input tag.
      * INPUT: --
      * OUTPUT: --
      */
  /*************************************************************/	

  focusTagInput(): void
    {
      this.tagInputRef.nativeElement.focus();
    }


  /**************************************************************/
      /*
      * METHOD: onKeyUp: this methot is called on key up input
      * INPUT: event objct
      * OUTPUT: --
      */
  /*************************************************************/	

  onKeyUp(event: KeyboardEvent): void {
    const inputValue: string = this.form.controls.tag.value;
    if (event.code === 'Backspace' && !inputValue) {
      this.removeTag("");
      return;
    } else {
      if(this.pmode == 'onlytext')
      {
        if (event.code === 'Comma' || event.code === 'Space') {
          this.addTag(inputValue, {name: inputValue});
        }
      }
    }
  }

  /**************************************************************/
      /*
      * METHOD: addTag: this method add a tag in the array of tags.
      * INPUT: string tag, object info.
      * OUTPUT: --
      */
  /*************************************************************/	

  addTag(tag: string, tagInfo:any): void {
    if (tag[tag.length - 1] === ',' || tag[tag.length - 1] === ' ') {
      tag = tag.slice(0, -1);
    }
    if (tag.length > 0 && !find(this.tags, tag)) {
      this.tags.push(tag);
      this.tagsInfo.push(tagInfo);
      this.form.controls.tag.setValue('');
      this.tagInputRef.nativeElement.focus();
      this.tagInputRef.nativeElement.click();
      this.ponchange.emit(this.tagsInfo);
    }
  }

  /**************************************************************/
      /*
      * METHOD: removeTag: Method to remove a tag from the array.
      * INPUT: URL audio
      * OUTPUT: --
      */
  /*************************************************************/	

  removeTag(tag: string): void {
    if(tag)
      {
        const index: number = this.tags.indexOf(tag);
        if (index !== -1) {
            this.tags.splice(index, 1);
            this.tagsInfo.splice(index, 1);
            this.ponchange.emit(this.tagsInfo);
          }    
    
      }
    else
      {
        this.tags.splice(-1);
        this.tagsInfo.splice(-1);
        this.ponchange.emit(this.tagsInfo);
      }
  }

  /**************************************************************/
      /*
      * METHOD: getPlaceAutocomplete: Method to convert input text in autocomplete google maps.
      * INPUT: --
      * OUTPUT: --
      */
  /*************************************************************/	

private getPlaceAutocomplete() {
  let oThis = this;
  const autocomplete = new google.maps.places.Autocomplete(this.tagInputRef.nativeElement,
      {
          /*componentRestrictions: { country: 'US' },*/
          types: ['(regions)']  // 'establishment' / 'address' / 'geocode'
      });

  google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      if(place.place_id)
        {
          let rInfo= { name:place.name, formatted_address:place.place_id, place_id:place.place_id, type:place.types}
          this.addTag(place.name, rInfo);
        }
      else
        {
          this.form.controls.tag.setValue('');
        }  
  });
}


}
