import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-successfulpayment',
  templateUrl: './successfulpayment.component.html',
  styleUrls: ['./successfulpayment.component.css']
})
export class SuccessfulpaymentComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  redirect() {
    this.router.navigate([`project/edit/new`]);
  }
}
