import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { User } from 'src/app/core/services/users.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  public isMenuCollapsed = true;
  aLoginUser: User;
  profile_name: string = '';
  user_name: string = '';
  languageImages = {
    'en': './../../../assets/images/langIcon/en.png',
    'es': './../../../assets/images/langIcon/es.png'
  };

  constructor(public authService: AuthService, private router: Router, public translate: TranslateService) {
    this.authService.currentUser.subscribe(x => {
      this.aLoginUser = x;
      this.setProfileNameFromCurrentUser();
    });
  }

  ngOnInit(): void {
    const storedLanguage = localStorage.getItem('locale');
    if (storedLanguage) {
      this.translate.use(storedLanguage);
    }
  }

  getLanguageImage(): string {
    const lang = this.translate.currentLang;
    return this.languageImages[lang];
  }

  changeLang(event: Event, language: string) {
    event.preventDefault();
    localStorage.setItem('locale', language);
    this.translate.use(language);
  }

  goto(sUrl) {
    this.router.navigate([sUrl]);
  }

  // Función para configurar el nombre a partir del usuario actual
  setProfileNameFromCurrentUser() {
    if (this.aLoginUser && this.aLoginUser.name) {
      this.user_name = this.aLoginUser.name;
      const names = this.aLoginUser.name.split(' '); 
      const initials = names.map(name => name.charAt(0)).join(''); 
      this.profile_name = initials.toUpperCase(); 
    }
  }
}
