import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { NotifyService } from '../helpers/notify.service';
import { UsersService, User } from './users.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ConfigService } from '../helpers/config.service';
import { JwtHelperService } from "@auth0/angular-jwt";
import { CallhttpService } from '../helpers/callhttp.service';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root',
})
export class AuthService {

  sReturnUrl: string;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  jwtHelperService = new JwtHelperService();

  /**************************************************************/
  /*
  * SERVICE: Service to manage login user.(JWT TOKEN)
  */
  /*************************************************************/


  constructor(private httpService: CallhttpService, private router: Router, private notifyService: NotifyService, private route: ActivatedRoute, private userService: UsersService, private configService: ConfigService, public translate: TranslateService) {
    this.sReturnUrl = "";
    this.route.queryParams.subscribe(params => {
      this.sReturnUrl = params['returnUrl'];
    });

    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('current_user')));
    this.currentUser = this.currentUserSubject.asObservable();

  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**************************************************************/
  /*
  * METHOD: myInsert: Method to call login to backend and set token.
  * INPUT: URL, DATA AND ON COMPLETE FUNCION.
  * OUTPUT: --
  */
  /*************************************************************/

  myInsert(email: string, password: string, onComplete = function () { }) {

    let aDatos = { email: email, password: password };
    let oThis = this;
    this.httpService.callHttpPOST(this.configService.TBT_HOST_API_DJANGO + 'auth-login/', aDatos,
      function (aData) {
        localStorage.setItem('access_token', aData.token);
        localStorage.setItem('user_id', aData.user.id);
        localStorage.setItem('user_name', aData.user.name);
        if (aData.user) {
          localStorage.setItem('current_user', JSON.stringify(aData.user));
          oThis.currentUserSubject.next(aData.user);
        }

        onComplete();
      }
    );
  }


  /**************************************************************/
  /*
  * METHOD: myInsert: Method to call login to backend and set token.
  * INPUT: URL, DATA AND ON COMPLETE FUNCION.
  * OUTPUT: --
  */
  /*************************************************************/

  refreshUserInfo(onComplete = function () { }) {

    let oThis = this;
    this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'auth-user-refresh/', {},
      function (aData) {
        if (aData.user) {
          localStorage.setItem('current_user', JSON.stringify(aData.user));
          oThis.currentUserSubject.next(aData.user);
        }

        onComplete();
      }
    );
  }



  /**************************************************************/
  /*
  * METHOD: logout: Method to logout user.
  * INPUT: --
  * OUTPUT: --
  */
  /*************************************************************/

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('current_user');
    this.currentUserSubject.next(null);
    this.router.navigate(["/"]);
  }

  /**************************************************************/
  /*
  * METHOD: loggedIn: Method to check if login
  * INPUT: --
  * OUTPUT: --
  */
  /*************************************************************/

  public get loggedIn(): boolean {
    return this.checkIfLoggedIn();
  }

  /**************************************************************/
  /*
  * METHOD: checkIfLoggedIn: Method to check if login
  * INPUT: --
  * OUTPUT: --
  */
  /*************************************************************/

  checkIfLoggedIn(): boolean {
    return localStorage.getItem('access_token') !== null && !this.jwtHelperService.isTokenExpired(localStorage.getItem('access_token'));
  }

  /**************************************************************/
  /*
  * METHOD: onClickStartTrialGuest: Method to create a user guest
  * INPUT: --
  * OUTPUT: --
  */
  /*************************************************************/


  onClickStartTrialGuest() {
    let oThis = this;
    if (!this.checkIfLoggedIn()) {
      this.myInsert("guest@guest.com", "guest", function () {
        let aDatosProject = {};
        aDatosProject["info"] = { title: oThis.translate.instant('upload-my-firsrt-project'), }
        aDatosProject["categories"] = aDatosProject["regions"] = aDatosProject["nizaclasses"] = [];
        oThis.httpService.callHttpPOST(oThis.configService.TBT_HOST_API + 'projects-edit/', aDatosProject,
          function (aData) {
            if (aData.id_project) {
              oThis.router.navigate(['/brandtest/' + aData.id_project + '/']);
            }
          }
        );
      });
    }
    else {
      this.httpService.callHttpGET(this.configService.TBT_HOST_API + 'projects-getone/last/',
        function (aData) {
          if (aData.id_project) {
            oThis.router.navigate(['/brandtest/' + aData.id_project + '/']);
          }
        }, false);
    }
  }



}