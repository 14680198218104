import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {
  language: string;

  constructor() { }

  ngOnInit() {
    this.language = localStorage.getItem('locale');
    console.log('locale', this.language);
    window['Tydical'].init();
  }
}
