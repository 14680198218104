import { Component, OnInit } from '@angular/core';
import { GettestService } from '../../services/gettest.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { FormBuilder } from '@angular/forms';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { ConfigService } from 'src/app/core/helpers/config.service';

@Component({
  selector: 'app-create-report',
  templateUrl: './create-report.component.html',
  styleUrls: ['./create-report.component.css']
})
export class CreateReportComponent implements OnInit {

  aBrandTestInfo:any;

  nIdProject:string;
  aBrandTestReportInfo = { code:String, title:String, info_results:{}, report_id:"" };

  constructor(private getTest:GettestService,  private router: Router, private httpService: CallhttpService, private route: ActivatedRoute, private formBuilder: FormBuilder, private notifyService:NotifyService, private configService:ConfigService)
    {
      this.getTest.aBrandTestInfo.subscribe(data => { this.aBrandTestInfo = data; });
    }


  ngOnInit()
    {
        this.nIdProject = this.aBrandTestInfo.id_project;

        let oThis = this;
        this.httpService.callHttpGET(this.configService.TBT_HOST_API + 'report-getone/' + this.nIdProject + "/", 
          function(aData)
            {
              oThis.aBrandTestReportInfo = aData;
            },
            true
        );
    }


  /**************************************************************/
    /*
    * METHOD: onClickConfirmReport, confirm report and save current status.
    * INPUT: --
    * OUTPUT: --
    */
  /*************************************************************/	

  onClickConfirmReport()
    {
      let oThis = this;
      let nReportId = this.aBrandTestReportInfo.report_id;
      let nIdProject = this.nIdProject;

      this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'report-generate-confirm/', { project_id: nIdProject, report_id:nReportId }, 
      function(aData)
        {
          if(aData && aData.report_code)
            {
              oThis.router.navigate(["/r/" + aData.report_code]);
              //oThis.sVisibility = (oThis.sVisibility == 'show') ? 'hide' : 'show';
            }
        }
      ); 
    }  


}
