import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

/**************************************************************/
/*
* MANAGES SOME CONFIG VALUES.
*/
/*************************************************************/

export class ConfigService {

  constructor() { }

  //public TBT_HOST_API = "http://localhost:8012/es_ES/";  
  //public TBT_STRIPE_PUBLIC_KEY = "pk_test_51IWRYCAqqjk7gMmqcYcNaD3ZuRMXwNZ4Ewb7rtiwp46WEjnomHEu5YHF57M1AP4xbJAR7n4Z2YOe7tmLEZFuM4Z600W9g9bBWz";  

  public TBT_HOST_API = "https://betester.com/backend/es_ES/";

  
  //public TBT_HOST_API_DJANGO = "http://127.0.0.1:8000/api/";
  public TBT_HOST_API_DJANGO = "https://django.betester.com/api/";

  public TBT_STRIPE_PUBLIC_KEY = "pk_test_51IWRYCAqqjk7gMmqcYcNaD3ZuRMXwNZ4Ewb7rtiwp46WEjnomHEu5YHF57M1AP4xbJAR7n4Z2YOe7tmLEZFuM4Z600W9g9bBWz";

  public TBT_HOSTIMAGES_API = "https://storage.googleapis.com/";

  public TBT_RETURN_LOGIN_IF_EMPTY = "/projects";

}
