import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { PriceLogoService } from 'src/app/core/services/price-logo.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {

  dateInit: string;
  dateEnd: string;
  userId: number;
  transactions: any[] = [];
  localUserId = localStorage.getItem('user_id');
  transactionForm: FormGroup;

  constructor(
    private httpService: CallhttpService,
    private configService: ConfigService,
    public authService: AuthService,
    private priceLogoService: PriceLogoService,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    this.dateInit = this.formatDate(firstDayOfMonth);
    this.dateEnd = this.formatDate(lastDayOfMonth);

    this.transactionForm = this.fb.group({
      dateInit: [this.dateInit],
      dateEnd: [this.dateEnd]
    });

    this.fetchTransactions();
  }

  onDateChange(): void {
    this.dateInit = this.transactionForm.get('dateInit').value;
    this.dateEnd = this.transactionForm.get('dateEnd').value;
    this.fetchTransactions();
  }

  private fetchTransactions(): void {
    const payload = {
      date_init: this.dateInit,
      date_end: this.dateEnd,
      user_id: this.localUserId || '0'
    };
    this.httpService.callHttpPOST(this.configService.TBT_HOST_API_DJANGO + 'transactions', payload,
      (aData) => {
        this.transactions = aData;
      }
    );
  }


  withdrawTransactions(): void {
    const payload = {
      user_id: this.localUserId || '0'
    };

    this.httpService.callHttpPOST(
      this.configService.TBT_HOST_API_DJANGO + 'transactions/withdraw',
      payload,
      (aData: any) => {
        console.log('Transactions withdrawn:', aData);
        this.fetchTransactions();
      }
    );
  }

  withdrawAllPendingTransactions(): void {
    const payload = {
      user_id: this.localUserId || '0'
    };

    this.httpService.callHttpPOST(
      this.configService.TBT_HOST_API_DJANGO + 'transactions/withdrawals',
      payload,
      (aData: any) => {
        console.log('All pending transactions withdrawn:', aData);
        // Actualizar la lista de transacciones después del retiro masivo
        this.fetchTransactions();
      }
    );
  }

  private formatDate(date: Date): string {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  }
}
