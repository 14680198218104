import { Component, Input, OnInit } from '@angular/core';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { ConfigService } from 'src/app/core/helpers/config.service';

@Component({
  selector: 'tbt-result-linguistic',
  templateUrl: './linguistic.component.html',
  styleUrls: ['./linguistic.component.css']
})
export class LinguisticComponent implements OnInit {

  nIdTest:string = "last";
  sTestType = "NAME";
  sShowMode = "project";
  sVisibility = "show";
  
  nProcessPercentage = 0;

  nStepconfig = 0;

  sMainAudioEsSrc = ""
  sMainAudioEnSrc = ""
  sMainAudioPtSrc = ""
  sMainAudioZhSrc = ""
  sMainAudioItSrc = ""
  sMainAudioFrSrc = ""

  aResultTestInfo:any;

  @Input() set pshowmode (value: string)
    {
      if(value)
        {
          this.sShowMode = value;
        }
    }

  @Input() set pvisibility (value: string)
    {
      if(value)
        {
          this.sVisibility = value;
        }
    }
  constructor(private httpService: CallhttpService, private router: Router, private route: ActivatedRoute,  private httpClient: HttpClient, private notifyService:NotifyService, private configService:ConfigService) { }

  ngOnInit()
    {
      let oThis = this;
      /*this.getResult.callResult(this.nIdTest, this.sTestType, "naming-linguistic",
        
        // on start check
        function(nIdcheck)
            {
              oThis.nStepconfig = 1;
            },
        // on progress check
        function(nPercentage)
            {
              oThis.nProcessPercentage = nPercentage;
            },         
        // on complete check
          function(aData)
            {
              oThis.aResultTestInfo = aData;
              if(aData.processed_name_audio_es)
                {
                  oThis.sMainAudioEsSrc = oThis.configService.TBT_HOSTIMAGES_API + aData.processed_name_audio_es
                  oThis.sMainAudioEnSrc = oThis.configService.TBT_HOSTIMAGES_API + aData.processed_name_audio_en
                  oThis.sMainAudioPtSrc = oThis.configService.TBT_HOSTIMAGES_API + aData.processed_name_audio_pt
                  oThis.sMainAudioZhSrc = oThis.configService.TBT_HOSTIMAGES_API + aData.processed_name_audio_zh
                  oThis.sMainAudioItSrc = oThis.configService.TBT_HOSTIMAGES_API + aData.processed_name_audio_it
                  oThis.sMainAudioFrSrc = oThis.configService.TBT_HOSTIMAGES_API + aData.processed_name_audio_fr

                  oThis.nStepconfig = 2;
                }
            }                
      );*/
    }

  onChangeTest(a)
    {
      this.nIdTest = a;
      this.ngOnInit();
    }


}
