import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { count } from 'rxjs/operators';


@Component({
  selector: 'app-info-popup',
  templateUrl: './info-popup.component.html',
  styleUrls: ['./info-popup.component.scss']
})


                        
export class InfoPopupComponent implements OnInit {

  @Input()
  ptype:string;

  @Input()
  ptimeout:number;

  @Input()
  pcode:string;

  sTitlePopUpDefault = "";
  sImagePopUpDefault = "assets/images/loadingpage.gif";


  sTitlePopUp = this.sTitlePopUpDefault;
  sImagePopUp = this.sImagePopUpDefault;
  bLoading = false;

  @ViewChild('mymodal', { static: false }) private content;

  bCheckHelpToHide = false;
  nCounterToEnable = 5;

  constructor( private translate: TranslateService, private modalService: NgbModal, public configService:ConfigService,  private httpService: CallhttpService, private cookieService: CookieService) { 
    translate.addLangs(['en', 'es']);
    translate.setDefaultLang('es');
  }


  ngOnInit()
    {
      let oThis = this;
      if(this.ptype == "timer" && !this.cookieService.get("dontshow-" + this.pcode))
        {
          //check if user hide this help.
          this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'tbt-boxinfo-get-user-hide/', { code:this.pcode }, 
          function(aData)
            {
              if(aData && aData.show_help && aData.show_help == true)
                {
                  setTimeout(function(){
                    oThis.open(oThis.content);
                    oThis.countDownToEnable();
                  },  oThis.ptimeout)    
                }
            });  
        }

    }

  open(content) {
    let oThis = this;
    this.bLoading = true;
    this.sTitlePopUp = this.sTitlePopUpDefault;
    this.sImagePopUp = this.sImagePopUpDefault;

    oThis.modalService.open(content, { size: 'lg', backdrop: 'static' });
    this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'tbt-boxinfo-get/', { code:this.pcode }, 
    function(aData)
      {
        oThis.sTitlePopUp = aData.title;
        oThis.sImagePopUp = aData.popup;
        oThis.bLoading = false;

        //oThis.modalService.open(content);
      }
    );
  }
  
  dontShowAgain(values:any)
    {
      if(values.currentTarget.checked)
        {
          this.bCheckHelpToHide = true;
        }
      else
        {
          this.bCheckHelpToHide = false;
        }  
    }

  onCloseModalHelp(modal:any)
    {
      if(this.bCheckHelpToHide)
        {
          this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'tbt-boxinfo-set-user-hide/', { code:this.pcode }, 
          function(aData)
            {
              modal.dismiss();
            }
          );       
        }
      else
        {
          modal.dismiss();
        }  
    }


  countDownToEnable()
    {
      let oThis = this;
      if(this.nCounterToEnable>0)
        {
          oThis.nCounterToEnable = oThis.nCounterToEnable - 1;
          setTimeout(() => {
            oThis.countDownToEnable();
          }, 1000);
        }
      }    


}
