import { Component, OnInit } from '@angular/core';
import { CallhttpService } from '../core/helpers/callhttp.service';
import { ConfigService } from '../core/helpers/config.service';
import { AuthService } from '../core/services/auth.service';
import { User } from '../core/services/users.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  aLoginUser:User;

  constructor(private httpService: CallhttpService, private configService:ConfigService, public authService:AuthService)
    {
      // suscribe to service authuser to get the current user.
      this.authService.currentUser.subscribe(x => this.aLoginUser = x);
    }

  /**************************************************************/
    /*
    * METHOD: On init this component, call info about the account of user
    * INPUT: --
    * OUTPUT: --
    */
  /*************************************************************/	

  ngOnInit()
    {
      let oThis = this;
      this.httpService.callHttpGET(this.configService.TBT_HOST_API + 'user-get-accountinfo/', 
        function(aData)
          {
            // for now, only orders
            if(aData.orders)
              {
                aData.orders.forEach(rData => {

                  $("#idBlockMyAccountPurchases").append("<tr><th scope='row'>"+rData.order+"</th><td>"+ rData.quantity+"</td><td>"+ rData.plan+"</td><td>"+rData.subtotal+"</td><td>"+rData.discount+"</td><td>"+rData.total+"</td><td>"+((rData.status=='P') ? "PAID":"NOT PAID")+"</td><td>"+((rData.processed=='Y') ? "COMPLETED":"IN PROCESS...")+"</td><td>"+rData.date+"</td></tr>");
                  //oThis.aPricesPlans[rData.reference] = rData;
                });
              }
          }
      );  
    }
}
