import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { BehaviorSubject, Observable, pipe } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { switchMap, map } from 'rxjs/operators';
import { ConfigService } from 'src/app/core/helpers/config.service';

@Component({
  selector: 'image-element',
  templateUrl: './img.component.html'

})

export class ImgComponent implements OnChanges  {
  // This code block just creates an rxjs stream from the src
  // this makes sure that we can handle source changes
  // or even when the component gets destroyed
  // So basically turn src into src$
  sNoImage = "assets/images/no-image.jpg";
  @Input() private src: string = this.sNoImage;

  @Input()
  pclass:string;
  @Input()
  pstyle:string;

  ngOnChanges(): void {
    try {
      this.src = this.configService.TBT_HOSTIMAGES_API + window.atob(this.src);
    } catch(e) {

    }
    if(this.src == "") 
      {
        this.src = this.sNoImage;
      }
  }

  // this stream will contain the actual url that our img tag will load
  // everytime the src changes, the previous call would be canceled and the
  // new resource would be loaded
 /* dataUrl$2 =""
  dataUrl$ = this.src$.pipe(switchMap(url => this.loadImage(url)));
  */
  // we need HttpClient to load the image
  constructor(private httpClient: HttpClient, private domSanitizer: DomSanitizer, public configService:ConfigService) { 
  }

  /*private loadImage(url: string): Observable<any> {
    return this.httpClient
      // load the image as a blob
      
      .get(this.configService.TBT_HOSTIMAGES_API + atob(url), {responseType: 'blob'})
      // create an object url of that blob that we can use in the src attribute
      .pipe(map(e => this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(e))))
  }*/
}