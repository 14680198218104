import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'functionality-bar-min-score',
  templateUrl: './bar-min-score.component.html',
  styleUrls: ['./bar-min-score.component.scss']
})
export class BarMinScoreComponent implements OnInit {

  @Input()
  nvalue:string;

  @Input()
  nmodo:string;

  @Input()
  ptext:string;

  @Input()
  pcolor:string;

  constructor() { }

  ngOnInit() {
  }

}
