import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { ConfigService } from 'src/app/core/helpers/config.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {

  forgotPassForm: FormGroup;
  submitted = false;
  bPasswordChanged = false
  sReturnUrl = "";
  sCancelUrl = "";
  constructor( private router: ActivatedRoute, private formBuilder: FormBuilder, private authService:AuthService, private loadingService:LoadingService, private httpService: CallhttpService, private configService:ConfigService)
    {
      this.router.queryParams.subscribe(params => {
        if(params['r']) { this.sReturnUrl = params['r']; }
        if(params['c']) { this.sCancelUrl = params['c']; }
      });
    }

  get f() { return this.forgotPassForm.controls; }

  ngOnInit()
    {
      this.forgotPassForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]]
      });
    }

  onSubmit()
    {
      this.submitted = true;
      // stop here if form is invalid
      if (this.forgotPassForm.invalid) {
        return;
      }
      let oThis = this;
      this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'auth-forgot-password/', { email:this.forgotPassForm.value.email }, 
        function(aData)
          {
            if(aData.result)
              {
                oThis.bPasswordChanged = true;
              }
          }
      );  
    }

}
