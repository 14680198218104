import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ConfigService } from '../helpers/config.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PriceLogoService {

  userId = localStorage.getItem("user_id");

  constructor(private httpClient: HttpClient, private configService: ConfigService, private authService: AuthService) { }

  getPriceLogo(): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type' : 'application/json',
      'Cache-Control': 'no-cache'
    }); 

    let options = {
      headers: httpHeaders,
      withCredentials: true
    };   
    
    if (!this.userId) {
      return of(false);
    } else {
      return this.httpClient.get<number>(`${this.configService.TBT_HOST_API_DJANGO}pricelogo/${this.userId}`, options)
    }
      
  }

}
