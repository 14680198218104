import { Component, Input, OnInit } from '@angular/core';
import { GettestService } from '../../../../services/gettest.service';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { ConfigService } from 'src/app/core/helpers/config.service';

@Component({
  selector: 'tbt-result-digital',
  templateUrl: './digital.component.html',
  styleUrls: ['./digital.component.css']
})
export class DigitalComponent implements OnInit {

  sLogoTrasparentSrc = 'assets/images/no-image.jpg';

  nIdTest:string = "last";
  sTestType = "LOGO";
  sShowMode = "project";
  sVisibility = "show";
  
  nProcessPercentage = 0;

  nStepconfig = 0;

  aResultTestInfo:any;

  @Input() set pshowmode (value: string)
    {
      if(value)
        {
          this.sShowMode = value;
        }
    }

  @Input() set pvisibility (value: string)
    {
      if(value)
        {
          this.sVisibility = value;
        }
    }
  constructor(private httpService: CallhttpService, private router: Router, private route: ActivatedRoute,  private httpClient: HttpClient, private notifyService:NotifyService, private configService:ConfigService) { }

  ngOnInit()
    {
        let oThis = this;
        /*this.getResult.callResult(this.nIdTest, this.sTestType, "compatibility-digital",
          
          // on start check
          function(nIdcheck)
              {
                oThis.nStepconfig = 1;
              },
          // on progress check
          function(nPercentage)
              {
                oThis.nProcessPercentage = nPercentage;
              },         
          // on complete check
            function(aData)
              {
                oThis.aResultTestInfo = aData;
                if(aData.transparent_logo)
                  {
                    oThis.sLogoTrasparentSrc = oThis.configService.TBT_HOSTIMAGES_API + aData.transparent_logo;
                    setTimeout(function(){
                      oThis.nStepconfig = 2;
                    },1000)                  
                  }
              }                
        );*/
      }

    onChangeTest(a)
      {
        this.nIdTest = a;
        this.ngOnInit();
      }

}
