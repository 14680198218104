import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'functionality-circle-score',
  templateUrl: './circle-score.component.html',
  styleUrls: ['./circle-score.component.scss']
})
export class CircleScoreComponent implements OnInit {

  @Input()
  nvalue:string; 

  @Input()
  nicon:string;

  @Input()
  nimage:string;

  @Input()
  nsize:string;

  @Input()
  pcolor:string;

  @Input()
  ncardimage:string;   

  styleObject = {};

  constructor() { }
  styles = []
  ngOnInit() {

  }

  getCircleValue(nValue)
    {
      if(nValue == "0")
        {
          return "10";
        }
      if(nValue == "1")
        {
          return "20";
        }
      if(nValue == "2")
        {
          return "30";
        }
      if(nValue == "3")
        {
          return "40";
        }
      if(nValue == "4")
        {
          return "50";
        }                                
      if(nValue == "5")
        {
          return "60";
        }  
      if(nValue == "6")
        {
          return "70";
        }  
      if(nValue == "7")
        {
          return "80";
        }  
      if(nValue == "8")
        {
          return "90";
        }  
      if(nValue == "9")
        {
          return "100";
        }  

    }

}
