import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GettestService } from '../../services/gettest.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { stringify } from 'querystring';
import { IfStmt } from '@angular/compiler';
import { Router } from '@angular/router';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'box-result-simulated',
  templateUrl: './box-result-simulated.component.html',
  styleUrls: ['./box-result-simulated.component.css']
})
export class BoxResultSimulatedComponent implements OnInit {

  @Input()
  ptitle:string;

  @Input()
  pbackground:string;


  constructor( private router: Router, private httpService: CallhttpService, private getTest:GettestService, public configService:ConfigService, private modalService: NgbModal)
    {
 
    }


  ngOnInit()
    {

    }



}