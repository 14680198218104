import { Injectable, EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

}
export interface User { name: string, user_classification: string, user_type: string, id: string }
