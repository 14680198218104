import { Component, OnInit, Input, SimpleChanges, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams, JsonpClientBackend } from '@angular/common/http';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { GettestService } from '../../../../services/gettest.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


declare var $: any;

@Component({
  selector: 'tbt-result-simplicity-summary',
  templateUrl: './simplicity-summary.component.html',
  styleUrls: ['./simplicity-summary.component.scss']
})
export class SimplicitySummaryComponent implements OnInit {

  @Output() ponchangetest: EventEmitter<any> = new EventEmitter();
  @Output() ponchangetosave: EventEmitter<any> = new EventEmitter();


  sLogoSrc = 'assets/images/no-image.jpg';
  sLogosCientificMode = 'assets/images/brandtest/functionality/grid.png';
  nMainLogoValue = 0;
  
  sTestName = "shape-simplicity";
  sTestType = "logo";

  sShowMode = "project";
  sVisibility = "show";


  nProcessPercentage = 0;

  nStepconfig = 0;

  aResultTestInfo:any;

  aDetail:any;

  aDescription =['Deficiente', 'Poco Eficiente', 'Mejorable', 'Eficiente', 'Muy Eficiente'];
  aColor =['colorInvalid', 'colorOrange', 'colorGreen', 'colorGreen', 'colorGreen'];

  aTestPerformed:any;
  aTestPerformedTBT:any;

  aTestSelectedToCompare = [];
  aTestSelectedToCompareLetter = ["A", "B", "C" ,"D" ,"E","F"];

  sPositionLogo = "";

  @Input() set pshowmode (value: string)
    {
      if(value)
        {
          this.sShowMode = value;
        }
    }

  @Input() set pvisibility (value: string)
    {
      if(value)
        {
          this.sVisibility = value;
        }
    }
  aBrandTestResults:any;

  constructor(private modalService: NgbModal,private getTest:GettestService, private httpService: CallhttpService, private router: Router, private route: ActivatedRoute,  private httpClient: HttpClient, private notifyService:NotifyService, private configService:ConfigService)
    {
      
    }

  ngOnInit()
    {
      let oThis = this;
      this.sLogoSrc = 'assets/images/no-image.jpg';
      this.sLogosCientificMode = 'assets/images/brandtest/functionality/grid.png';
      this.nMainLogoValue = 0;
      this.getTest.aBrandTestResults.subscribe(function(aData) 
        { 
          oThis.aBrandTestResults = aData;
          if(oThis.aBrandTestResults[oThis.sTestName])
            {
              let aData = oThis.aBrandTestResults[oThis.sTestName];
              oThis.aResultTestInfo = aData;
              if(aData.simplicity_score && aData.transparent_logo)
                  {
                    oThis.nStepconfig = 2;
                    oThis.sLogosCientificMode = aData.transparent_logo;
                    oThis.sLogoSrc  = aData.transparent_logo_simple;

                    oThis.nMainLogoValue = aData.simplicity_score;
                    oThis.aDetail = aData.detail.rep_mode;

                    oThis.aTestPerformed = aData.info_tests_performed;
                    oThis.aTestPerformedTBT = aData.info_tests_tbt;

                    oThis.sPositionLogo = aData.position_logo;


                    setTimeout(function(){

                      if(aData.result_status_info && aData.result_status_info.compare_tests)
                        {
                          oThis.aTestSelectedToCompare = aData.result_status_info.compare_tests;
                        }  

                    },500);

                  }
            }
        });  
    }

  /* MAIN FUNCIONS*/

  onChangeTest(nIdTest)
    {
      this.ponchangetest.emit([nIdTest, this.sTestName]); 
      this.sLogoSrc = 'assets/images/no-image.jpg';
      this.sLogosCientificMode = 'assets/images/brandtest/functionality/grid.png';
      this.nMainLogoValue = 0;
      this.aDetail = [];
    }

  saveChangesInResult()
    {
      let aDataToSave = {};
      
      // save test compare 
      aDataToSave["compare_tests"] = this.aTestSelectedToCompare;

      //save if result is showing
      aDataToSave["show_result"] = true;

      this.ponchangetosave.emit([this.sTestName, aDataToSave]); 
    }  

  /* FINISH MAIN FUNCIONS*/


  calculatePoint(nPuntaje)
    {
      nPuntaje = 100 - nPuntaje
      if(nPuntaje > 98) { nPuntaje = 98}
      if(nPuntaje < 2) { nPuntaje = 2 }


      let nNota = Math.floor(nPuntaje/10)

      if(nPuntaje > -1 &&  nPuntaje < 21)
        {
          return [nNota, this.aDescription[0], this.aColor[0]];
        }

      if(nPuntaje > 20 &&  nPuntaje < 41)
          {
            return [nNota, this.aDescription[1], this.aColor[1]];
          }

      if(nPuntaje > 40 &&  nPuntaje < 61)
          {
            return [nNota, this.aDescription[2], this.aColor[2]];
          }

      if(nPuntaje > 60 &&  nPuntaje < 81)
          {
            return [nNota, this.aDescription[3], this.aColor[3]];
          }          

      if(nPuntaje > 80 &&  nPuntaje < 101)
          {
            return [nNota, this.aDescription[4], this.aColor[4]];
          }          


    }  

    calculateValue(nPuntaje)
      {
        nPuntaje = 100 - nPuntaje
        if(nPuntaje > 98) { nPuntaje = 98}
        if(nPuntaje < 2) { nPuntaje = 2 }
        return nPuntaje
      } 


  onClickOpenBox(elem, stype)
    {
      let oParent = $(elem).parent().parent().parent();
      $('.boxInfo', oParent).slideToggle();    
      if(stype =="open")
        {
          $("#openbox", $(elem).parent()).css("display","none");
          $("#closebox", $(elem).parent()).css("display","block");
        }
      else
        {
          $("#openbox", $(elem).parent()).css("display","block");
          $("#closebox", $(elem).parent()).css("display","none");
        }  

    } 

  onClickOpenBoxAnalysisDetails(elem)
    {
      $(elem).hide();
      let oParent = $(elem).next();
      oParent.slideToggle();    
    } 

  onClickCloseBoxAnalysisDetails(elem)
    {
      let oParent = $(elem).parent();
      oParent.slideToggle(function(){
        oParent.prev().show();    

      });    
    }   




  onClickInCompare(content)
    {
      this.modalService.open(content, { size: 'lg', backdrop: 'static' });
    }

  onClickInTestToCompare(nIdTest, aInfo)
    {
      if(this.aTestSelectedToCompare.length<6)
        {
          let aInfoAux = Object.assign({}, aInfo);
          aInfoAux.id_test = nIdTest;
          aInfoAux.transparent_logo_simple = this.configService.TBT_HOSTIMAGES_API + atob(aInfo.transparent_logo_simple);
          this.aTestSelectedToCompare.push(aInfoAux);
          this.saveChangesInResult();
        }
    }

  checkIfExistTestInTestTocompare(nIdTestToCheck, bCurrent =false) 
    {
      if(bCurrent)
        {
          return true;
        }
        
      for (let n in this.aTestSelectedToCompare)
        { 
          if(this.aTestSelectedToCompare[n].id_test == nIdTestToCheck)
            {
              return true;
            }
        }
      return false;  
    }


  onClickCompareTestClose(nIdTestToCheck)
    {
      for (let n in this.aTestSelectedToCompare)
        { 
          if(this.aTestSelectedToCompare[n].id_test == nIdTestToCheck)
            {
              ///console.log(n);
              this.aTestSelectedToCompare.splice(Number(n), 1);
            }
        }
      this.saveChangesInResult();
    }


  getReproductionModePuntaje(rTestInfo, nCode)
    {
      if(rTestInfo && rTestInfo.detail  && rTestInfo.detail['rep_mode'] && rTestInfo.detail['rep_mode'][nCode])
        {
            return rTestInfo.detail['rep_mode'][nCode].puntaje;
        }
      return false;  
    }


  getReproductionSystemPuntaje(rTestInfo, nCodeMode, nCodeSystem)
    {
      if(rTestInfo && rTestInfo.detail  && rTestInfo.detail['rep_mode'] && rTestInfo.detail['rep_mode'][nCodeMode])
        {
          if(rTestInfo.detail['rep_mode'][nCodeMode].systems && rTestInfo.detail['rep_mode'][nCodeMode].systems[nCodeSystem])
            {
              return rTestInfo.detail['rep_mode'][nCodeMode].systems[nCodeSystem].puntaje;
            }
        }
      return false;  
    }


  getReproductionAnalysisPuntaje(rTestInfo, nCodeMode, nCodeSystem, nCodeAnalisis)
    {
      if(rTestInfo && rTestInfo.detail  && rTestInfo.detail['rep_mode'] && rTestInfo.detail['rep_mode'][nCodeMode])
        {
          if(rTestInfo.detail['rep_mode'][nCodeMode].systems && rTestInfo.detail['rep_mode'][nCodeMode].systems[nCodeSystem])
            {
              if(rTestInfo.detail['rep_mode'][nCodeMode].systems[nCodeSystem].analysis && rTestInfo.detail['rep_mode'][nCodeMode].systems[nCodeSystem].analysis[nCodeAnalisis])

              return rTestInfo.detail['rep_mode'][nCodeMode].systems[nCodeSystem].analysis[nCodeAnalisis].puntaje;
            }
        }
      return false;  
    }


}

