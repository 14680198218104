export class Convexhull {

    oHull:any;
  
    constructor(
        private aPoints:any
    ){}
  
    public calculate() 
      {
        this.oHull = new Array();
        this.oHull.sort(function compare(p1,p2) {return p1.x - p2.x;});
  
        var upperHull = new Array();
        this.calcUpperhull(upperHull);
        for(var i = 0; i < upperHull.length; i++)
            this.oHull.push(upperHull[i]);
  
        var lowerHull = new Array();
        this.calcLowerhull(lowerHull);
        for(var i = 0; i < lowerHull.length; i++)
            this.oHull.push(lowerHull[i]);
    };
  
    calcUpperhull(upperHull)
      {
        var i = 0;
        upperHull.push(this.aPoints[i]);
        i++;
        upperHull.push(this.aPoints[i]);
        i++;
        // Start upperHull scan
        for(i; i < this.aPoints.length; i++){
            upperHull.push(this.aPoints[i]);
            while(
                upperHull.length>2 && // more than 2 points 
                !upperHull[upperHull.length-3].rotateRight(upperHull[upperHull.length-1],upperHull[upperHull.length-2]) // last 3 points make left turn
            )
                upperHull.splice(upperHull.indexOf(upperHull[upperHull.length-2]), 1); // remove middle point
        }
      };
  
    calcLowerhull(lowerHull)
      {
        var i = this.aPoints.length-1;
        lowerHull.push(this.aPoints[i]);
        i--;
        lowerHull.push(this.aPoints[i]);
        i--;
        // Start lowerHull scan
        for(i; i >= 0; i--){
            lowerHull.push(this.aPoints[i]);
            while(
                lowerHull.length>2 && // more than 2 points 
                !lowerHull[lowerHull.length-3].rotateRight(lowerHull[lowerHull.length-1],lowerHull[lowerHull.length-2]) // last 3 points make left turn
            )
                lowerHull.splice(lowerHull.indexOf(lowerHull[lowerHull.length-2]), 1); // remove middle point
        }
      };


}
