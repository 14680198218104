import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'window-pop-up',
  templateUrl: './window-pop-up.component.html',
  styleUrls: ['./window-pop-up.component.css']
})
export class WindowPopUpComponent implements OnInit {

  @Input()
  pclass:string;

  @Input()
  ptitle:string;


  constructor() { }

  ngOnInit() {
  }

}
