import { Component, OnInit, Input, SimpleChanges, ViewChild, ElementRef, Output, EventEmitter, } from '@angular/core';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams, JsonpClientBackend } from '@angular/common/http';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { GettestService } from '../../../../services/gettest.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/core/services/users.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';


declare var $: any;

@Component({
  selector: 'tbt-result-simplicity',
  templateUrl: './simplicity.component.html',
  styleUrls: ['./simplicity.component.scss']
})
export class SimplicityComponent implements OnInit {

  isScrolled = false;

  toggleScroll() {
    this.isScrolled = !this.isScrolled;
    this.scrollPage();
  }

  scrollPage() {
    if (this.isScrolled) {
      window.scrollTo({
        top: window.pageYOffset + 500,
        behavior: 'smooth'
      });
    }
  }

  get getResultTbtBar(): string {
    switch (this.category_bar) {
      case 'large':
        return this.translate.instant('result-logo-large');
      case 'small':
        return this.translate.instant('result-logo-small');
      case 'digital':
        return this.translate.instant('result-logo-digital');
      case 'survey':
        return this.translate.instant('result-logo-survey');
      default:
        return this.translate.instant('result-logo-simplicity-title');
    }
  }

  get ptitle(): string {
    switch (this.category_bar) {
      case 'large':
        return this.translate.instant('result-logo-large');
      case 'small':
        return this.translate.instant('result-logo-small');
      case 'digital':
        return this.translate.instant('result-logo-digital');
      case 'survey':
        return this.translate.instant('result-logo-survey');
      default:
        return this.translate.instant('result-logo-simplicity-title');
    }
  }

  @Output() ponchangetest: EventEmitter<any> = new EventEmitter();
  @Output() ponchangetosave: EventEmitter<any> = new EventEmitter();

  sLogoSrc = 'assets/images/no-image.jpg';
  sLogosCientificMode = 'assets/images/brandtest/functionality/grid.png';
  nMainLogoValue = 0;
  percentageScores = {}
  sTestName = "shape-simplicity";
  sTestType = "logo";

  sShowMode = "project";
  sVisibility = "show";

  category_bar: any = "shape";

  nProcessPercentage = 0;

  nStepconfig = 0;

  aResultTestInfo: any;

  aDetail: any;
  filteredDetail: any;

  aDescription = ['deficient', 'littleefficient', 'improvable', 'efficient', 'veryefficient'];
  aColor = ['colorInvalid', 'colorOrange', 'colorOrange', 'colorGreen', 'colorGreen'];

  aTestPerformed: any;
  aTestPerformedTBT: any;

  aTestSelectedToCompare = [];
  aTestSelectedToCompareLetter = ["A", "B", "C", "D", "E", "F"];

  sPositionLogo = "";

  modalSwitch: boolean;

  valueBarLogoTest: number;

  @Input() set pshowmode(value: string) {
    if (value) {
      this.sShowMode = value;
    }
  }

  @Input() set pvisibility(value: string) {
    if (value) {
      this.sVisibility = value;
    }
  }
  aBrandTestResults: any;

  aLoginUser: User;
  nIdProject = 0;

  constructor(
    public authService: AuthService, private modalService: NgbModal, private getTest: GettestService, private httpService: CallhttpService, private router: Router, private route: ActivatedRoute, private httpClient: HttpClient, private notifyService: NotifyService, private configService: ConfigService, public translate: TranslateService) {
    // suscribe to service authuser to get the current user.
    this.authService.currentUser.subscribe(x => this.aLoginUser = x);

    // Configuración del módulo de traducción
    this.translate.setDefaultLang('en');
  }

  ngOnInit() {
    let oThis = this;
    this.sLogoSrc = 'assets/images/no-image.jpg';
    this.sLogosCientificMode = 'assets/images/brandtest/functionality/grid.png';
    this.nMainLogoValue = 0;

    this.getTest.aBrandTestInfo.subscribe(function (aData: any) {
      //get id project
      if (aData && aData.id_project) {
        oThis.nIdProject = aData.id_project;
      }
    });

    this.getTest.aBrandTestResults.subscribe(function (aData) {
      oThis.aBrandTestResults = aData;

      if (oThis.aBrandTestResults[oThis.sTestName]) {
        let aData = oThis.aBrandTestResults[oThis.sTestName];
        oThis.aResultTestInfo = aData;
        if (aData.simplicity_score && aData.transparent_logo) {
          oThis.nStepconfig = 2;
          oThis.sLogosCientificMode = aData.transparent_logo;
          oThis.sLogoSrc = aData.transparent_logo_simple;

          oThis.nMainLogoValue = aData.simplicity_score;

          oThis.aDetail = aData.detail.rep_mode;

          oThis.aTestPerformed = aData.info_tests_performed;
          oThis.aTestPerformedTBT = aData.info_tests_tbt;

          oThis.sPositionLogo = aData.position_logo;

          oThis.filterDetail();

          setTimeout(function () {

            if (aData.result_status_info && aData.result_status_info.compare_tests) {
              oThis.aTestSelectedToCompare = aData.result_status_info.compare_tests;
            }
          }, 500);
        }
      }
    });
    setTimeout(function () {
      $('.idBlockToolTipHelp').tooltip();
    }, 500);
  }

  filterDetail() {
    this.category_bar = localStorage.getItem('category_bar') || 'shape';
    let filteredDetailCodes;
    let totalScore = 0;
    const shape_list = [1, 13, 15, 16, 2, 5, 6, 4, 11, 12, 3, 7, 9, 8]
    const large_list = [1, 13, 15, 16, 2]
    const small_list = [4, 11, 12, 3, 7, 9, 8]
    const digital_list = [5, 6]

    switch (this.category_bar) {
      case 'shape':
        filteredDetailCodes = shape_list;
        break;
      case 'large':
        filteredDetailCodes = large_list;
        break;
      case 'small':
        filteredDetailCodes = small_list;
        break;
      case 'digital':
        filteredDetailCodes = digital_list;
        break;
      default:
        filteredDetailCodes = Object.keys(this.aDetail).map(code => parseInt(code));
    }

    this.filteredDetail = Object.keys(this.aDetail)
      .filter(code => filteredDetailCodes.includes(parseInt(this.aDetail[code].code)))
      .map(code => this.aDetail[code]);

    totalScore = this.filteredDetail.reduce((acc, detail) => acc + parseInt(detail.puntaje), 0);


    let valuePerLogoScore = 0;


    this.filteredDetail.map(detail => {
      switch (this.category_bar) {
        case 'shape':
          if (shape_list.includes(parseInt(detail.code))) {
            valuePerLogoScore += Number(this.calculateValue(parseInt(detail.puntaje)));
            this.valueBarLogoTest = Math.floor(valuePerLogoScore / shape_list.length);
          }
          break;
        case 'large':
          if (large_list.includes(parseInt(detail.code))) {
            valuePerLogoScore += Number(this.calculateValue(parseInt(detail.puntaje)));
            this.valueBarLogoTest = Math.floor(valuePerLogoScore / large_list.length);
          }
          break;
        case 'small':
          if (small_list.includes(parseInt(detail.code))) {
            valuePerLogoScore += Number(this.calculateValue(parseInt(detail.puntaje)));
            this.valueBarLogoTest = Math.floor(valuePerLogoScore / small_list.length);
          }
          break;
        case 'digital':
          if (digital_list.includes(parseInt(detail.code))) {
            valuePerLogoScore += Number(this.calculateValue(parseInt(detail.puntaje)));
            this.valueBarLogoTest = Math.floor(valuePerLogoScore / digital_list.length);
          }
          break;
        default:
          filteredDetailCodes = Object.keys(this.aDetail).map(code => parseInt(code));
      }
    });
  }


  calculateValue(nPuntaje) {
    nPuntaje = 100 - nPuntaje
    if (nPuntaje > 98) { nPuntaje = 98 }
    if (nPuntaje < 10) { nPuntaje = 10 }
    /*     console.log('nPuntaje', nPuntaje) */
    return nPuntaje
  }



  /* MAIN FUNCIONS*/
  onChangeTest(nIdTest) {
    this.ponchangetest.emit([nIdTest, this.sTestName]);
    this.sLogoSrc = 'assets/images/no-image.jpg';
    this.sLogosCientificMode = 'assets/images/brandtest/functionality/grid.png';
    this.nMainLogoValue = 0;
    this.aDetail = [];
  }

  saveChangesInResult() {
    let aDataToSave = {};

    // save test compare 
    aDataToSave["compare_tests"] = this.aTestSelectedToCompare;

    //save if result is showing
    aDataToSave["show_result"] = true;

    this.ponchangetosave.emit([this.sTestName, aDataToSave]);
  }

  /* FINISH MAIN FUNCIONS*/
  calculatePoint(nPuntaje) {

    nPuntaje = 100 - nPuntaje
    if (nPuntaje > 98) { nPuntaje = 98 }
    if (nPuntaje < 2) { nPuntaje = 2 }

    let nNota = Math.floor(nPuntaje / 10)

    // rojo  
    if (nPuntaje > -1 && nPuntaje < 21) {
      return [nNota, this.aDescription[0], this.aColor[0]];
    }

    //naranja  
    if (nPuntaje > 20 && nPuntaje < 41) {
      return [nNota, this.aDescription[1], this.aColor[1]];
    }

    //naranja    
    if (nPuntaje > 40 && nPuntaje < 61) {
      return [nNota, this.aDescription[2], this.aColor[2]];
    }
    //verde
    if (nPuntaje > 60 && nPuntaje < 81) {
      return [nNota, this.aDescription[3], this.aColor[3]];
    }
    //vernder  
    if (nPuntaje > 80 && nPuntaje < 101) {
      return [nNota, this.aDescription[4], this.aColor[4]];
    }
  }

  onClickOpenBox(elem, stype, nD = 2, bFirst = false) {
    $(".openBoxAnimation").removeClass("openBoxAnimation");
    if (this.aLoginUser && this.aLoginUser.user_type == 'G' && bFirst) {
      this.router.navigate(["/register/"], { queryParams: { 'r': '/brandtest/' + this.nIdProject, 'c': '/brandtest/' + this.nIdProject } });
      return false;
    }

    let oParent = $(elem).parent().parent();
    $(oParent).next().slideToggle(nD < 5 ? 800 : 1000);
    if (stype == "open") {
      $("#openbox", $(elem).parent()).css("display", "none");
      $("#closebox", $(elem).parent()).css("display", "block");
    }
    else {
      $("#openbox", $(elem).parent()).css("display", "block");
      $("#closebox", $(elem).parent()).css("display", "none");
    }
  }

  countArrayElems(arr) {
    var count = 0
    for (var i in arr) {
      count++
    }
    return count
  }

  onClickOpenBoxAnalysisDetails(elem) {
    $(elem).hide();
    let oParent = $(elem).next();
    oParent.slideToggle();
  }

  onClickCloseBoxAnalysisDetails(elem) {
    let oParent = $(elem).parent();
    oParent.slideToggle(function () {
      oParent.prev().show();

    });
  }

  onClickInCompare(content) {
    this.modalService.open(content, { size: 'lg', backdrop: 'static' });
  }

  onClickInTestToCompare(nIdTest, aInfo) {
    if (this.aTestSelectedToCompare.length < 6) {
      let aInfoAux = Object.assign({}, aInfo);
      aInfoAux.id_test = nIdTest;
      aInfoAux.transparent_logo_simple = this.configService.TBT_HOSTIMAGES_API + atob(aInfo.transparent_logo_simple);
      this.aTestSelectedToCompare.push(aInfoAux);
      this.saveChangesInResult();
    }
  }

  checkIfExistTestInTestTocompare(nIdTestToCheck, bCurrent = false) {
    if (bCurrent) {
      return true;
    }

    for (let n in this.aTestSelectedToCompare) {
      if (this.aTestSelectedToCompare[n].id_test == nIdTestToCheck) {
        return true;
      }
    }
    return false;
  }

  onClickCompareTestClose(nIdTestToCheck) {
    for (let n in this.aTestSelectedToCompare) {
      if (this.aTestSelectedToCompare[n].id_test == nIdTestToCheck) {
        ///console.log(n);
        this.aTestSelectedToCompare.splice(Number(n), 1);
      }
    }
    this.saveChangesInResult();
  }

  getReproductionModePuntaje(rTestInfo, nCode) {
    if (rTestInfo && rTestInfo.detail && rTestInfo.detail['rep_mode'] && rTestInfo.detail['rep_mode'][nCode]) {
      return rTestInfo.detail['rep_mode'][nCode].puntaje;
    }
    return false;
  }

  getReproductionSystemPuntaje(rTestInfo, nCodeMode, nCodeSystem) {
    if (rTestInfo && rTestInfo.detail && rTestInfo.detail['rep_mode'] && rTestInfo.detail['rep_mode'][nCodeMode]) {
      if (rTestInfo.detail['rep_mode'][nCodeMode].systems && rTestInfo.detail['rep_mode'][nCodeMode].systems[nCodeSystem]) {
        return rTestInfo.detail['rep_mode'][nCodeMode].systems[nCodeSystem].puntaje;
      }
    }
    return false;
  }

  getReproductionAnalysisPuntaje(rTestInfo, nCodeMode, nCodeSystem, nCodeAnalisis) {
    if (rTestInfo && rTestInfo.detail && rTestInfo.detail['rep_mode'] && rTestInfo.detail['rep_mode'][nCodeMode]) {
      if (rTestInfo.detail['rep_mode'][nCodeMode].systems && rTestInfo.detail['rep_mode'][nCodeMode].systems[nCodeSystem]) {
        if (rTestInfo.detail['rep_mode'][nCodeMode].systems[nCodeSystem].analysis && rTestInfo.detail['rep_mode'][nCodeMode].systems[nCodeSystem].analysis[nCodeAnalisis])

          return rTestInfo.detail['rep_mode'][nCodeMode].systems[nCodeSystem].analysis[nCodeAnalisis].puntaje;
      }
    }
    return false;
  }



}
