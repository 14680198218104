import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { User } from 'src/app/core/services/users.service';
import { GettestService } from '../../services/gettest.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-show-report',
  templateUrl: './show-report.component.html',
  styleUrls: ['./show-report.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ShowReportComponent implements OnInit {

  aLoginUser:User;
  aBrandTestReportInfo = { code:String, title:"", logo_main:"", report_results:[], report_id:"", owner_name:"", owner_classification:"", report_repro_mode:[], report_niza:[], report_regions:[] };
  aBrandTestResults:any;

  sCodeReport:string;
  sVae="";
  nIdProject:string;
  shareForm: FormGroup;
  submitted = false;
  modalReference:any;
  showFromEmail = false;
  bCopyLink = false;
  constructor(private modalService: NgbModal, private getTest:GettestService, public authService:AuthService, private router: Router, private httpService: CallhttpService, private route: ActivatedRoute, private formBuilder: FormBuilder, private notifyService:NotifyService, private configService:ConfigService)
    { 
      this.authService.currentUser.subscribe(x => this.aLoginUser = x);
      this.getTest.aBrandTestResults.subscribe(data => { this.aBrandTestResults = data; });
    }

  get f() { return this.shareForm.controls; }


  ngOnInit()
    {
      this.shareForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]]
      });

      this.route.queryParams.subscribe(params => {
        let fromemail = params['email'];
        if(fromemail)
          {
            this.showFromEmail = true;
          }
      });

      this.route.paramMap.subscribe(params => {
        this.sCodeReport = params.get("code");
        if(this.sCodeReport)
          {
              let oThis = this;
              this.sVae = this.sCodeReport;
              this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'report-show/', { code: this.sCodeReport, fromeemail:this.showFromEmail }, 
                function(aData)
                  {
                    if(aData && aData.report_results)
                      {
                        oThis.nIdProject = aData.id_project;

                        oThis.aBrandTestReportInfo.title = aData.title.toUpperCase();
                        oThis.aBrandTestReportInfo.report_results = aData.report_results;
                        oThis.aBrandTestReportInfo.owner_name = aData.owner_name;
                        oThis.aBrandTestReportInfo.owner_classification = aData.owner_classification;

                        oThis.aBrandTestReportInfo.report_repro_mode = aData.report_repro_mode;
                        oThis.aBrandTestReportInfo.report_niza = aData.report_niza;
                        oThis.aBrandTestReportInfo.report_regions = aData.report_regions;

                        if(aData.report_results["shape-simplicity"])
                          {
                              let sSimpliciy = aData.report_results["shape-simplicity"];
                              oThis.aBrandTestReportInfo.logo_main = sSimpliciy.transparent_logo_simple;  
                          } 

                        //load the results test
                        oThis.getTest.aBrandTestResultsSubject.next(aData.report_results);
                      }
                  },
                true
              );
          }
      });
    }  

  checkIfShowResultInReport(sNameResult)
    {
        return (this.aBrandTestReportInfo.report_results[sNameResult]);
    }  


  onShare(sType)
    {
        this.submitted = true;
        // stop here if form is invalid
        if (this.shareForm.invalid) {
          return;
        }

        let oThis = this;
        this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'report-send-by-email/', { code:this.sCodeReport, email:this.shareForm.value.email }, 
          function(aData)
            {
              if(aData.result)
                {
                  oThis.modalReference.close();
                  oThis.notifyService.createNotify("success", "Reporte enviado con éxito.");
                }
            }
        );  

      }  

    open(content)
      {
        let oThis = this;
        this.modalReference = oThis.modalService.open(content, { size: 'md', backdrop: 'static' });
      }

  copyCurrentUrlButton()
    {
      let oThis = this;
      this.bCopyLink = true;
      var dummy = document.createElement('input'),
      text = window.location.href;
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand('copy');
      document.body.removeChild(dummy);
      setTimeout(function(){
        oThis.bCopyLink = false;
      }, 3000);
    }    

}


