import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PaymentService } from '../../services/payment.service';
import { ConfigService } from '../config.service';
import { CallhttpService } from '../callhttp.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { PriceLogoService } from '../../services/price-logo.service';

@Injectable({
  providedIn: 'root'
})
export class PriceLogoGuard implements CanActivate {

  constructor(private authService: AuthService, private priceLogoService: PriceLogoService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Observable<boolean | UrlTree>((observer) => {
      this.priceLogoService.getPriceLogo().subscribe((precioPago: any) => {
        if (precioPago.data && precioPago.data.price > 0) {
          observer.next(true);
          observer.complete();
        } else {
          observer.next(this.router.parseUrl('/prices'));
          observer.complete();
        }
      }, error => {
        observer.next(false);
        observer.complete();
      });
    });
  }

}
