import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  totalAmount: any = "0"; 
  transactionID: any;

  constructor() { }

  async createOrder(data: any, actions: any) {
    
  }
}
