import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap,map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'secured-audio',
  templateUrl: './audio.component.html'
})
export class AudioComponent implements OnChanges  {
  // This code block just creates an rxjs stream from the src
  // this makes sure that we can handle source changes
  // or even when the component gets destroyed
  // So basically turn src into src$
  @Input() public src: string;
  private src$ = new BehaviorSubject(this.src);

  @Input()
  pclass:string;
  @Input()
  pstyle:string;

  ngOnChanges(): void {
    this.src$.next(this.src);
  }


  constructor(private httpClient: HttpClient) {}


  /**************************************************************/
      /*
      * METHOD: loadUrl: Method to play audio file.
      * INPUT: URL audio
      * OUTPUT: --
      */
  /*************************************************************/	

  loadUrl(url: string) {
    return this.httpClient
      .get(url, { responseType: 'blob' })
      .subscribe(e => {
          var audio = new Audio();
          audio.src = URL.createObjectURL(e); 
          audio.onload = function (evt) {
            URL.revokeObjectURL(URL.createObjectURL(e));
          };
          audio.play();
      })
  }


}