import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { PaymentService } from 'src/app/core/services/payment.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { PriceLogoService } from 'src/app/core/services/price-logo.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-paymentprocess',
  templateUrl: './paymentprocess.component.html',
  styleUrls: ['./paymentprocess.component.scss']
})
export class PaymentprocessComponent implements OnInit {
  amount = 0;
  @ViewChild('paymentRef', { static: true }) paymentRef!: ElementRef;
  @ViewChild('link_paypal_ref', { static: true }) link_paypal_ref!: ElementRef;
  private checkInterval = 10000; // 10 segundos
  private intervalId: any;

  submitted: boolean = false;
  newProjectForm: FormGroup;
  // edit
  nIdProject: string;
  // new
  nNewIdProject: string;

  payStepComplete = false;

  nStepComplete: number = 1;

  sProjectName = "";
  aProjectsCategories = [];
  aProjectsNizaClasses = [];
  aProjectsCategoriesSelected = {};

  bSelectedRegionsEmpty = false;
  aSelectedRegions = [];

  aSelectedClassNiza = [];

  /* PROJECT CATEGORIESBAR RESIZE */
  nHeightProjectCategories = 0;
  bIfMousePressedInBar: boolean;
  oStartElementPressed: any;
  nStartElementPressedPosition: number;
  nStartElementPressedWidth: number;
  nCountProjectCategoriesBoxes: number;
  nCountProjectCategoriesBoxesWidth: number;
  nStartElementPressedDirection = 0;

  bShowButtonOmitirReproductionMode = true;
  price: number | undefined;

  paypal: any;
  cantidadTest: number = 1;

  show_button: boolean = false;
  bCopyLink: boolean = false;
  id_code: string = '';
  constructor(private router: Router, private route: ActivatedRoute, private httpService: CallhttpService, private notifyService: NotifyService, private configService: ConfigService, private formBuilder: FormBuilder, private authService: AuthService, private loadingService: LoadingService, private paymentService: PaymentService, private priceLogoService: PriceLogoService, private httpClient: HttpClient) {

  }

  /**************************************************************/
  /*
  * METHOD: methd to call all info necesary to render screen
  * INPUT: idproject if edit
  * OUTPUT: --
  */
  /*************************************************************/
  ngOnDestroy() {
    // Limpiar el intervalo cuando el componente se destruya para evitar fugas de memoria
    clearInterval(this.intervalId);
  }

  ngOnInit() {
    //generate project form
    this.newProjectForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(30), Validators.minLength(2), Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$')]]
    });

    this.intervalId = setInterval(() => {
      this.checkOrderStatus();
    }, this.checkInterval);

    this.updateAmount();
    //get data from project if editing  
    this.route.paramMap.subscribe(params => {
      this.nIdProject = params.get("id_project");
      this.priceLogoService.getPriceLogo()
        .subscribe(res => this.amount = res.data.price);
      if (this.nIdProject != "new") {
        let oThis = this;
        this.httpService.callHttpGET(this.configService.TBT_HOST_API + 'projects-getone/' + this.nIdProject,
          function (aData) {
            oThis.newProjectForm.controls['title'].setValue(aData.title);
          },
          true
        );
      }
      else {
        let oThis = this;
        this.httpService.callHttpGET(this.configService.TBT_HOST_API + 'projects-getnew/',
          function (aData) {
            //get categories
            if (aData.categories) {
              let aDataDivided = [];
              var i, j, chunk = 5;
              for (i = 0, j = aData.categories.length; i < j; i += chunk) {
                aDataDivided.push(aData.categories.slice(i, i + chunk));
              }
              oThis.aProjectsCategories = aDataDivided;
            }

            //get niza classes    
            if (aData.classesniza) {
              let aDataDivided = [];
              var i, j, chunk = 4;
              for (i = 0, j = aData.classesniza.length; i < j; i += chunk) {
                aDataDivided.push(aData.classesniza.slice(i, i + chunk));
              }

              oThis.aProjectsNizaClasses = aData.classesniza;
            }
          },
          true
        );
        this.nIdProject = "";
      }
    });

    // drawn and start project categories bar.
    let e = this;
    $(function () {
      e.onStartProjectCategoriesBarResize();
    });

    this.fetchPriceFromBackend();

    window.paypal.Buttons({
      createOrder: async (data: any, actions: any) => {
        if (data.paymentSource == "card") {
          const transactionId = await actions.order.create({
            purchase_units: [
              {
                reference_id: this.cantidadTest,
                amount: {
                  value: this.price * this.cantidadTest,
                  currency_code: "USD"
                }
              }
            ]
          });
          return transactionId;

        } else if (data.paymentSource == "paypal") {
          const payload = [{
            value: this.amount,
            quantity: this.cantidadTest,
            user_id: localStorage.getItem('user_id')
          }];
          const options = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Cache-Control': 'no-cache'
            })
          };
          return this.httpClient.post<any>(this.configService.TBT_HOST_API_DJANGO + 'createorder', payload, options)
            .toPromise()
            .then(res => {
              this.show_button = true;
              this.bCopyLink = false;
              this.id_code = res.data.id;
              const paypalLink = res.data.links[1].href;
              if (this.link_paypal_ref && this.link_paypal_ref.nativeElement) {
                this.link_paypal_ref.nativeElement.value = paypalLink;
              } else {
                console.error("this.link_paypal_ref.nativeElement is not defined or null");
              }
            })
            .catch(error => {
              console.error('Error al generar la orden:', error);
              throw new Error('Error al generar la orden');
            });
        }
      },

      onApprove: (data: any, actions: any) => {

        const options = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
          })
        };

        return actions.order.capture().then((details) => {
          if (details.status === "COMPLETED") {
            const payload = {
              ...details,
              user_id: localStorage.getItem('user_id'),
              quantity: this.cantidadTest,
            };
            this.httpClient.post<any>(this.configService.TBT_HOST_API_DJANGO + 'payment/card', payload, options)
              .toPromise()
              .then(res => {
                this.show_button = false;
                this.bCopyLink = false;
                if (!!res.data) {
                  this.paymentService.transactionID = details.id;
                  this.paymentRef.nativeElement.style.display = "none";
                  this.payStepComplete = true;
                }
              })
              .catch(error => {
                console.error('Error al generar la orden:', error);
                throw new Error('Error al generar la orden');
              });
          }
        });
      },
    }).render(this.paymentRef.nativeElement);
  }


  navigate() {
    if (!this.nIdProject) {
      this.router.navigate([`project/edit/new`]);
    } else {
      this.router.navigate([`project/edit/${this.nIdProject}`]);
    }
  }

  get f() { return this.newProjectForm.controls; }



  /**************************************************************/
  /*
  * METHOD: on click in confirm name project, go to step2
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/

  onConfirmNameProject() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newProjectForm.invalid) {
      return;
    }

    this.sProjectName = this.newProjectForm.value.title;

    this.onConfirmNizaClassProject()

    //this.nStepComplete = 2;
    //this.submitted = false;
    //window.scroll(0,0);

  }


  /**************************************************************/
  /*
  * METHOD: on click in confirm categories project, go to screen project.
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/

  onConfirmCategoriesProject() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newProjectForm.invalid) {
      return;
    }
    this.nStepComplete = 3;
    this.submitted = false;
    window.scroll(0, 0);
  }


  /**************************************************************/
  /*
  * METHOD: on click in confirm categories project, go to screen project.
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/

  onConfirmRegionsProject() {
    this.submitted = true;
    if (!(this.aSelectedRegions.length > 0)) {
      //return;
    }

    this.nStepComplete = 4;
    this.submitted = false;
    window.scroll(0, 0);

  }


  onConfirmNizaClassProject() {
    this.submitted = true;
    if (!(this.aSelectedClassNiza.length > 0)) {
      //return;
    }

    this.submitted = false;
    let oThis = this;
    let aDatosProject = {};
    aDatosProject["info"] = this.newProjectForm.value;
    aDatosProject["categories"] = this.aProjectsCategoriesSelected;
    aDatosProject["regions"] = this.aSelectedRegions;
    aDatosProject["nizaclasses"] = this.aSelectedClassNiza;
    this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'projects-edit/' + this.nIdProject, aDatosProject,
      function (aData) {
        if (aData.id_project) {
          //oThis.nStepComplete = 5;
          oThis.submitted = false;
          window.scroll(0, 0);
          oThis.nNewIdProject = aData.id_project
          setTimeout(function () {
            $('#idBlockToolTipHelp').tooltip();
          }, 200);
          oThis.router.navigate(['/brandtest/' + aData.id_project + '/']);
        }
        else {
          oThis.router.navigate(['projects']);
        }
      }
    );
  }

  onGoToCreateTest() {
    this.router.navigate(['/brandtest/' + this.nNewIdProject + '/']);
  }


  /**************************************************************/
  /*
  * METHOD: start view of resize category project
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/

  onStartProjectCategoriesBarResize() {


    let oThis = this;
    //check if mouse press in bar, enable and calculate all      
    $(document).mousemove(function (e) {

      if (oThis.bIfMousePressedInBar) {

        //get info about box resizing  
        var nPrevElemtWith = $(oThis.oStartElementPressed).width();
        var nNewElementWidth = oThis.nStartElementPressedWidth + (e.pageX - oThis.nStartElementPressedPosition) * oThis.nStartElementPressedDirection;

        // if correct change width of the element
        if (nNewElementWidth >= 10 && nNewElementWidth <= (oThis.nCountProjectCategoriesBoxesWidth - (10 * (oThis.nCountProjectCategoriesBoxes - 1)))) {

          if (nNewElementWidth > 30) {
            $(".boxInfo", oThis.oStartElementPressed).show();
            //$(oThis.oStartElementPressed).tooltip("disable");
          }
          else {
            $(".boxInfo", oThis.oStartElementPressed).hide();
            //$(oThis.oStartElementPressed).tooltip("enable");
          }


          $(oThis.oStartElementPressed).width(Math.ceil(nNewElementWidth));

          // change width of rest the elemets, proportionality   
          var nElementWidthDifference = (nPrevElemtWith - nNewElementWidth)
          $("#idBlockBoxResizeProjectConfig .box").each(function () {
            if (!$(this).hasClass("resizing")) {
              var nBoxPrevWidth = $(this).width();
              var nBoxNewWidth = nBoxPrevWidth + (nElementWidthDifference / (oThis.nCountProjectCategoriesBoxes - 1));
              if (nBoxNewWidth >= 10) {
                $(this).width(Math.ceil(nBoxNewWidth));
                if (nBoxNewWidth > 30) {
                  $(".boxInfo", this).show();
                  //$(this).tooltip("disable");
                }
                else {
                  $(".boxInfo", this).hide();
                  //$(this).tooltip('enable');
                }
              }
              else {
                $(".boxInfo", this).hide();
                //$(this).tooltip('enable');
              }
            }

            // calculate percentajre all box and show this.
            var nCurrentBoxWidthPartial = $(this).width();
            var nCurrentBoxWidthPercentaje = ((nCurrentBoxWidthPartial * 100) / oThis.nCountProjectCategoriesBoxesWidth).toFixed(0);
            $(".boxInfo span", this).html(nCurrentBoxWidthPercentaje + "%");
            var sIdBox = $(this).attr("id");
            var nIdBox = sIdBox.split("-")[1];
            oThis.aProjectsCategoriesSelected[nIdBox] = nCurrentBoxWidthPercentaje;

          });
        }
        else {
          if (nNewElementWidth < 10) {
            $(".boxInfo", oThis.oStartElementPressed).hide();
            //$(oThis.oStartElementPressed).tooltip('enable');    
          }
        }
      }
    });

    //check if mouse up in bar, disable all
    $(document).mouseup(function () {
      if (oThis.bIfMousePressedInBar) {
        $(oThis.oStartElementPressed).removeClass("resizing");
        oThis.bIfMousePressedInBar = false;
      }
    });

  }


  /**************************************************************/
  /*
  * METHOD: Click on box to select project categories
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/

  onClickInConfigProjectCategory(event) {

    if ($(event.target).hasClass("selected")) {
      $(event.target).removeClass("selected")
      if ($(".selected", "#idBlockProjectSelectCategoriesConfig").length == 0) {
        $("#idBlockProjectSelectCategoriesConfig").animate({ "height": this.nHeightProjectCategories }, 300, false);
        $("#idBlockProjectResizeCategoriesConfig").slideUp(300);
        this.bShowButtonOmitirReproductionMode = true;
      }
      this.onAddRemoveElementInProjectCategoriesResizeBar(event.target)
    }
    else {
      $(event.target).addClass("selected")
      if ($(".selected", "#idBlockProjectSelectCategoriesConfig").length == 1) {
        this.nHeightProjectCategories = $("#idBlockProjectSelectCategoriesConfig").height();
        $("#idBlockProjectSelectCategoriesConfig").animate({ "height": this.nHeightProjectCategories }, 300, false);
        $("#idBlockProjectResizeCategoriesConfig").slideDown(300);
        this.bShowButtonOmitirReproductionMode = false;
      }
      this.onAddRemoveElementInProjectCategoriesResizeBar(event.target)
    }

    $("#idBlockBoxResizeProjectConfig .box").tooltip("enable");
  }


  /**************************************************************/
  /*
  * METHOD: clone and add a item in resize category project bar
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/

  onAddRemoveElementInProjectCategoriesResizeBar(oClickElem) {
    let oThis = this;

    let oBoxClone = $(".boxClone", oClickElem);
    let oBoxCloneContainer = $(".box", oBoxClone);

    let sIdBoxToClone = oBoxCloneContainer.attr("id");
    let aIdBoxToClone = sIdBoxToClone.split("-");
    let nIdBoxToClone = aIdBoxToClone[1];

    let bIfExistBlockInBar = ($("#idBlockInBar-" + nIdBoxToClone, "#idBlockBoxResizeProjectConfig").length) ? true : false;
    if (bIfExistBlockInBar) {
      $("#idBlockInBar-" + nIdBoxToClone, "#idBlockBoxResizeProjectConfig").remove()
      delete this.aProjectsCategoriesSelected[nIdBoxToClone];
    }
    else {
      $("#idBlockBoxResizeProjectConfig").append(oBoxClone.html());
      $("#idBlockBoxResizeProjectConfig .box:last-child").attr("id", "idBlockInBar-" + nIdBoxToClone);
    }

    // count all elemnts in container
    oThis.nCountProjectCategoriesBoxes = $("#idBlockBoxResizeProjectConfig .box").length;
    oThis.nCountProjectCategoriesBoxesWidth = $("#idBlockBoxResizeProjectConfig").width();

    let nCalculateBoxesWidth = (100 / this.nCountProjectCategoriesBoxes);

    $("#idBlockBoxResizeProjectConfig .box").width(nCalculateBoxesWidth + '%');
    if (this.nCountProjectCategoriesBoxes % 2) {
      $("#idBlockBoxResizeProjectConfig .box:last-child").addClass("colorVeryLightGrey-background");
    }
    else {
      $("#idBlockBoxResizeProjectConfig .box:last-child").addClass("colorWhiteSoft-background");
    }

    $("#idBlockBoxResizeProjectConfig .box span").html(nCalculateBoxesWidth.toFixed(0) + '%')

    if (!bIfExistBlockInBar) {
      this.aProjectsCategoriesSelected[nIdBoxToClone] = nCalculateBoxesWidth.toFixed(1);
      for (let i in this.aProjectsCategoriesSelected) {
        this.aProjectsCategoriesSelected[i] = nCalculateBoxesWidth.toFixed(1);
      }

      $("#idBlockBoxResizeProjectConfig .box:last-child").mouseover(function () {
        if (!oThis.bIfMousePressedInBar && oThis.nCountProjectCategoriesBoxes > 1) {
          $(this).addClass("hover");
        }
      })
        .mouseout(function () {
          $(this).removeClass("hover");
        });

      $(".controls1", $("#idBlockBoxResizeProjectConfig .box:last-child")).mousedown(function (e) {
        if (oThis.nCountProjectCategoriesBoxes > 1) {
          oThis.oStartElementPressed = $(this).parent();
          oThis.nStartElementPressedPosition = e.pageX;
          oThis.nStartElementPressedWidth = $(this).parent().width();
          $(oThis.oStartElementPressed).addClass("resizing");
          oThis.bIfMousePressedInBar = true;
          oThis.nStartElementPressedDirection = -1;
        }
      });

      $(".controls2", $("#idBlockBoxResizeProjectConfig .box:last-child")).mousedown(function (e) {
        if (oThis.nCountProjectCategoriesBoxes > 1) {
          oThis.oStartElementPressed = $(this).parent();
          oThis.nStartElementPressedPosition = e.pageX;
          oThis.nStartElementPressedWidth = $(this).parent().width();
          $(oThis.oStartElementPressed).addClass("resizing");
          oThis.bIfMousePressedInBar = true;
          oThis.nStartElementPressedDirection = 1;
        }
      });
    }
  }


  onChangeCompleteRegions(aSelectedRegions: []) {
    this.aSelectedRegions = aSelectedRegions;
  }


  onClickInConfigProjectClassNiza(nClass) {
    nClass = nClass.toString();
    const index: number = this.aSelectedClassNiza.indexOf(nClass);
    if (index !== -1) {
      this.aSelectedClassNiza.splice(index, 1);
    }
    else {
      this.aSelectedClassNiza.push(nClass);
    }
  }


  // Obtener precio
  fetchPriceFromBackend() {
    const id = localStorage.getItem('user_id')
    if (Number(id) > 0 && id) {
      this.httpService.callHttpGET(this.configService.TBT_HOST_API_DJANGO + `pricelogo/${id}`,
        (data) => {
          if (!!data && data.price > 0) {
            this.price = data.price;
          } else {
            this.router.navigate(['/prices']);
            console.error('No price data available.');
          }
        },
        true
      );
    }
  }

  copyToClipboard(inputElement: HTMLInputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.bCopyLink = true;
  }

  checkOrderStatus() {
    // Llamar a la API para verificar el estado de la orden
    let orderId = this.id_code; // Obtener el ID de la orden que se está procesando
    if (orderId) {

      const payload = { id_code: orderId };
      const options = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        })
      };
      return this.httpClient.post<any>(this.configService.TBT_HOST_API_DJANGO + 'verifyorder', payload, options)
        .toPromise()
        .then(res => {
          if (res.order_status === "COMPLETED") {
            this.notifyService.createNotify('success', 'COMPLETED');
            this.router.navigate(['/projects']);
            this.show_button = false;
            this.bCopyLink = false;
            this.paymentService.transactionID = res.data.id;
            this.paymentRef.nativeElement.style.display = "none";
            this.payStepComplete = true;
            setTimeout(() => {
              this.router.navigate(['/projects']);
            }, 1000);
          }
        })
        .catch(error => {
          console.error('Error al generar la orden:', error);
          throw new Error('Error al generar la orden');
        });
    }
  }

  onCantidadChange(value: string) {
    this.cantidadTest = +value;
    this.updateAmount();
  }

  incrementCantidad() {
    this.cantidadTest++;
    this.updateAmount();
  }

  decrementCantidad() {
    if (this.cantidadTest > 1) {
      this.cantidadTest--;
      this.updateAmount();
    }
  }

  updateAmount() {
    if (this.price) {
      this.amount = this.price * this.cantidadTest;
    }
  }

  updateCantidadTest(newCantidad: number) {
    this.cantidadTest = newCantidad;
    this.updateAmount();
  }

}