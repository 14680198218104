import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-failedpayment',
  templateUrl: './failedpayment.component.html',
  styleUrls: ['./failedpayment.component.scss']
})
export class FailedpaymentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
