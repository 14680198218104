import { Component, OnInit } from '@angular/core';
import { GettestService } from '../../../../services/gettest.service';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { ConfigService } from 'src/app/core/helpers/config.service';

@Component({
  selector: 'app-cbalancing',
  templateUrl: './cbalancing.component.html',
  styleUrls: ['./cbalancing.component.css']
})
export class CbalancingComponent implements OnInit {

  sMainLogoSrc = 'assets/images/no-image.jpg';
  sMainLogoSrcOriginal = 'assets/images/no-image.jpg';

  nMainLogoValue = 0;
  nMainLogoValue2 = 0;
  nIdVersion:string;
  nIdProject:string;
  
  nProcessPercentage = 0;

  nStepconfig = 0;

  constructor(private getTest:GettestService, private httpService: CallhttpService, private router: Router, private route: ActivatedRoute,  private httpClient: HttpClient, private notifyService:NotifyService, private configService:ConfigService) { }

  ngOnInit()
    {
      /*this.route.parent.paramMap.subscribe(params => {
        this.nIdVersion = params.get("id_version")
        this.nIdProject = params.get("id_project")
        if(this.nIdVersion && this.nIdProject)
          {
            let oThis = this;
            this.getTest.callTest(this.nIdProject, this.nIdVersion, "color-balancing",
              
              // on start check
              function(nIdcheck)
                  {
                    oThis.nStepconfig = 1;
                  },
              // on progress check
              function(nPercentage)
                  {
                    oThis.nProcessPercentage = nPercentage;
                  },         
              // on complete check
               function(aData)
                  {
                    if(aData.processed_logo_balancing)
                      {
                        oThis.nStepconfig = 2;
                        setTimeout(function(){
                          oThis.sMainLogoSrc = oThis.configService.TBT_HOSTIMAGES_API + aData.processed_logo_balancing;
                          oThis.sMainLogoSrcOriginal = oThis.configService.TBT_HOSTIMAGES_API + aData.processed_logo_np;
                          oThis.nMainLogoValue = aData.processed_balancing_score;
                          oThis.nMainLogoValue2 = aData.processed_balancing_lr_score;

                          $("#idBlockProgressBarColor").animate({ width: oThis.nMainLogoValue+"%" }, 1000);

                        },1000)
                      }
                  }                
            );
          }
        });     */
    }


}
