


import { Component, OnInit } from '@angular/core';
import { NotifyService } from '../../core/helpers/notify.service';
import { FormBuilder } from '@angular/forms';
import { ConfigService } from '../../core/helpers/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CallhttpService } from '../../core/helpers/callhttp.service';
import { GettestService } from '../services/gettest.service';
@Component({
  selector: 'app-addtest',
  templateUrl: './addtest.component.html',
  styleUrls: ['./addtest.component.css']
})

export class AddtestComponent implements OnInit {
  nIdProject: string;

  nIdTestLogoToConfirm: string;
  nIdTestNameToConfirm: string;

  sUploadedLogoToConfirm:string;
  sUploadedNameToConfirm:string;

  sUploadType: string;

  aBrandTestInfo:any;
  nStepUploadLogo = 0;

  constructor(private getTest:GettestService,private router: Router, private httpService: CallhttpService, private route: ActivatedRoute, private formBuilder: FormBuilder, private notifyService: NotifyService, private configService: ConfigService)
    {
      this.getTest.aBrandTestInfo.subscribe(data => { this.aBrandTestInfo = data; });
    }
    /**************************************************************/
  /*
  * METHOD: on start screen, call info necessary
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.sUploadType = params.get("upload_type");
      this.nIdProject = this.aBrandTestInfo.id_project;
      if(this.aBrandTestInfo["test_in_progress"])
        {
          // standardizering??
          if(this.aBrandTestInfo["test_in_progress"]["test_standardizing"])
            {
              // asign el id_test to call check status of test.
              this.nIdTestLogoToConfirm = this.aBrandTestInfo["test_in_progress"]["test_standardizing"][0];
              this.sUploadType = "logo";
            }

          // here put code if naming require some process.
        }    
      
      if(this.sUploadType != "none")
        {
          this.onClickInBoxUploadSelection(this.sUploadType);
        }

    });
  }
  /**************************************************************/
  /*
  * METHOD: on lcik in select upload logo.
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/
  onClickInBoxUploadSelection(sTypeUpload) {
    $("#idBlockBoxSelectUpload").hide();
    if (sTypeUpload == "logo") {
      $("#idBlockBoxUploadLogo").show();
    }
    else {
      $("#idBlockBoxUploadName").show();
    }
  }

  onProcessUpload(nTestToConfirm)
    {
      this.nIdTestLogoToConfirm = nTestToConfirm;
    }


  /**************************************************************/
  /*
  * METHOD: event on finish upload logo.
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/
  onFinishUpload(sLogoUpload, nTestToConfirm) {
    this.sUploadedLogoToConfirm = sLogoUpload;
    this.nIdTestLogoToConfirm = nTestToConfirm;

    /*descomentar quitar ultima linea si se quiere confrimacionde upload*/
    //this.nStepUploadLogo = 1;
    this.onClickConfirmLogoProcessed();
  }
  /**************************************************************/
  /*
  * METHOD: event on buton change logo or name
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/
  onClickChangeUpload() {
    let oThis = this;
    this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'brandtest-cancel-processing-test/' + this.nIdProject, { }, function (aData) {
      oThis.nStepUploadLogo = 0;
    });
  }
  
  /**************************************************************/
  /*
  * METHOD: event on buton cofirm logo and go to result analysis.
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/
  onClickConfirmLogoProcessed() {
    
    //this.nStepUploadLogo = 1;
    let oThis = this;
    
    this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'brandtest-confirm-logo/' + this.nIdProject, { test_id: this.nIdTestLogoToConfirm }, function (aData) {
      oThis.router.navigate(['/brandtest/' + oThis.nIdProject + "/logo"]);
    });

  }
  /**************************************************************/
  /*
  * METHOD: event on buton cofirm name and go to result analysis.
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/
  onNameProcessed(sNameConfirmed) {
    this.sUploadedNameToConfirm = sNameConfirmed;
    this.nStepUploadLogo = 2;
  }
  /**************************************************************/
  /*
  * METHOD: event on buton to confirm name upload and show result
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/
  onClickConfirmNameProcessed() {
    this.nStepUploadLogo = 2;
    let oThis = this;
    this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'brandtest-upload-name/' + this.nIdProject, { brand_name: this.sUploadedNameToConfirm }, function (aData) {
      oThis.router.navigate(['/brandtest/' + oThis.nIdProject + "/name"]);
    });
  }
}