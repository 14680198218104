import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'tbt-bar',
  templateUrl: './tbt-bar.component.html',
  styleUrls: ['./tbt-bar.component.scss']
})

export class TbtBarComponent implements OnInit {

  @Output() pclick: EventEmitter<any> = new EventEmitter();

  @Input()
  pbuttonselected:string;

  @Input()
  penabled:string;

  btnArray = [
    '../../../assets/svg/barra-02.svg',
    '../../../assets/svg/barra-03.svg',
    '../../../assets/svg/barra-04.svg',
    '../../../assets/svg/barra-05.svg',
    '../../../assets/svg/barra-06.svg'
  ];


  constructor() { }

  ngOnInit()
    {
    
    }

  /**************************************************************/
    /*
    * METHOD: onclick in any button of tbt bar
    * INPUT: Category Test.
    * OUTPUT: --
    */
  /*************************************************************/	    

  onClickButtonBar(oElem, sTestType, sCategory)
    {
      if(this.penabled=="true")
      {
        console.log("TBT-BAR: ",  sTestType, sCategory);
          $(".selected").removeClass("selected");
          $(oElem.target).addClass("selected")
          this.pclick.emit([sTestType, sCategory]);    
        }
    }
}
