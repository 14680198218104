import { Component, Input, OnInit } from '@angular/core';
import { GettestService } from '../../../../services/gettest.service';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { ConfigService } from 'src/app/core/helpers/config.service';

@Component({
  selector: 'tbt-result-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.css']
})
export class LegalComponent implements OnInit {

  nIdTest:string = "last";
  sTestType = "NAME";
  sTestName = "naming-legal";

  sShowMode = "project";
  sVisibility = "show";
  
  nProcessPercentage = 0;

  nStepconfig = 0;

  aProcessedNameTrademark = []
  aProcessedNameDomaincom = []
  aProcessedNameDomainnet = []

  aResultTestInfo:any;

  @Input() set pshowmode (value: string)
    {
      if(value)
        {
          this.sShowMode = value;
        }
    }

  @Input() set pvisibility (value: string)
    {
      if(value)
        {
          this.sVisibility = value;
        }
    }

  aBrandTestResults:any;

  constructor(private getTest:GettestService, private httpService: CallhttpService, private router: Router, private route: ActivatedRoute,  private httpClient: HttpClient, private notifyService:NotifyService, private configService:ConfigService)
    { 
      let oThis = this;
      this.getTest.aBrandTestResults.subscribe(function(aData) 
        { 
          oThis.aBrandTestResults = aData;
          if(oThis.aBrandTestResults[oThis.sTestName])
            {
                let aData = oThis.aBrandTestResults[oThis.sTestName];
                oThis.aResultTestInfo = aData;
                if(aData.processed_name_trademark)
                  {
                    oThis.aProcessedNameTrademark = aData.processed_name_trademark
                    oThis.aProcessedNameDomaincom = aData.processed_name_domaincom
                    oThis.aProcessedNameDomainnet = aData.processed_name_domainnet
                    oThis.nStepconfig = 2;
                  }
            }
        });      
    }

  ngOnInit()
    {



       /* let oThis = this;
        this.getResult.callResult(this.nIdTest, this.sTestType, "naming-legal",
          
          // on start check
          function(nIdcheck)
              {
                oThis.nStepconfig = 1;
              },
          // on progress check
          function(nPercentage)
              {
                oThis.nProcessPercentage = nPercentage;
              },         
          // on complete check
            function(aData)
              {
                oThis.aResultTestInfo = aData;
                if(aData.processed_name_trademark)
                  {
                    oThis.aProcessedNameTrademark = aData.processed_name_trademark
                    oThis.aProcessedNameDomaincom = aData.processed_name_domaincom
                    oThis.aProcessedNameDomainnet = aData.processed_name_domainnet

                    oThis.nStepconfig = 2;
                    setTimeout(function(){

                    },1000)
                  }
              }
            );
          */
      }

  onChangeTest(a)
      {
        this.nIdTest = a;
        this.ngOnInit();
      }


}
