
import { Point } from './point';
import { Convexhull } from './convexhull';

export class Colorwheel {

    constructor() { }

    oContainer: any;
    aPoints = new Array();
    oCanvas:any
  
    init(elementContainer:any)
      {
        this.aPoints = new Array();
        this.oContainer = elementContainer;
        var diameter = this.oContainer.offsetWidth;
        var canvas = document.createElement('canvas');
        canvas.height = diameter;
        canvas.width = diameter,
        this.oCanvas = canvas;
        this.oContainer.appendChild(canvas);
      }
  
    renderColorMap()
      {
        var canvas = this.oCanvas;
        var ctx = canvas.getContext('2d');
  
        var radius = canvas.width / 2;
        var toRad = (2 * Math.PI) / 360;
        var step = 1 / radius;
        
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        
        var cx, cy = radius;
        for(var i = 0; i < 360; i += step) {
            var rad = i * toRad;
            var x = radius * Math.cos(rad),
                y = radius * Math.sin(rad);
            
            ctx.strokeStyle = 'hsl(' + i + ', 100%, 50%)';
           
            ctx.beginPath();
            ctx.moveTo(radius, radius);
            ctx.lineTo(cx + x, cy + y);
            ctx.stroke();
        }
  
        // draw saturation gradient
        var grd = ctx.createRadialGradient(cx,cy,0,cx,cx,radius);
        grd.addColorStop(0,"white");
        grd.addColorStop(1,'rgba(255, 255, 255, 0)');
        ctx.fillStyle = grd;
        //ctx.fillStyle = 'rgb(255, 255, 255)';
        ctx.beginPath();
        ctx.arc(cx, cy, radius, 0, Math.PI * 2, true);
        ctx.closePath();
        ctx.fill();
        
        // render the rainbow box here ----------
      }
  
    renderMouseCircle(x, y)
      {
        var canvas = this.oCanvas;
        var ctx = canvas.getContext('2d');
  
        ctx.strokeStyle = 'rgb(0, 0, 0)';
        ctx.fillStyle = 'rgb(0, 0, 0)'
        ctx.lineWidth = '2';
        ctx.beginPath();
        ctx.arc(x, y, 8, 0, Math.PI * 2, true);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
  
        this.aPoints.push(new Point(x,y));
      }
  
    sortByKey = function(array, key) {
        return array.sort(function(a, b) {
            var x = a[key]; var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    renderConvexHull =  function()
      {
          var canvas = this.oCanvas;
          var ctx = canvas.getContext('2d');
          // Draw convex hull
          console.log(this.aPoints);
          this.aPoints = this.sortByKey(this.aPoints, "x");
          
          var convexHull = new Convexhull(this.aPoints);
          convexHull.calculate();
          for(var i = 1; i < convexHull.oHull.length; i++)
              {
                  var p1 = convexHull.oHull[i-1];
                  var p2 = convexHull.oHull[i];
                  ctx.beginPath();
                  ctx.moveTo(p1.x, p1.y);
                  ctx.lineTo(p2.x, p2.y);
                  ctx.closePath();
                  ctx.stroke();
              }            
      }
  
      rgbToHsv(r, g, b){
        r = r/255, g = g/255, b = b/255;
        var max = Math.max(r, g, b), min = Math.min(r, g, b);
        var h, s, v = max;
  
        var d = max - min;
        s = max == 0 ? 0 : d / max;
  
        if(max == min){
            h = 0; // achromatic
        }else{
            switch(max){
                case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                case g: h = (b - r) / d + 2; break;
                case b: h = (r - g) / d + 4; break;
            }
            h /= 6;
        }
  
        var nP = (100/24) / 100;
        var hVal = h;
        for (var i=0; i<=23; i++)
            {
                if(h >= ((nP*i)-(nP)) && h <= ((nP*i)+(nP)))
                    {
                        hVal = nP*i;
                        break;
                    }
            }
  
        return [hVal, s/*0.80*/, v, i];
    }
    

    plotHsv = function(h, s, v, i) {
        var canvas = this.oCanvas;
        
        //var [h, s, v, i] = this.rgbToHsv(r, g, b);
  
        var theta = h * 2 * Math.PI;
        var maxRadius = canvas.width / 2;
        var r:any;
        r = s * maxRadius;
        var x = r * Math.cos(theta) + maxRadius,
            y = r * Math.sin(theta) + maxRadius;
        this.renderMouseCircle(x, y);
        return i;    
  
    }    
  
    calculateQuadrant = function(i)
      {
          var c = 0
          if(i >= 0 && i <= 6)
              {
                  c = 1
              }
          if(i >= 6 && i <= 12)
              {
                  c = 2
              }
  
          if(i >= 12 && i <= 18)
              {
                  c = 3
              }
  
          if(i >= 18 && i <= 24)
              {
                  c = 4
              }
  
          return c;
      }
  
    groupSimilarColors = function (aColors)
        {
            const distinto = (valor, indice, self) => {
                return self.indexOf(valor) === indice;
            }            
            return aColors.filter(distinto);            
        }  

}
