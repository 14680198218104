import { Component, HostListener, Inject, OnInit } from '@angular/core';

import { NotifyService } from '../core/helpers/notify.service';
import { FormBuilder } from '@angular/forms';
import { ConfigService } from '../core/helpers/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CallhttpService } from '../core/helpers/callhttp.service';
import { GettestService } from './services/gettest.service';

@Component({
  selector: 'app-main',
  templateUrl: './brandtest.component.html',
  styleUrls: ['./brandtest.component.scss']
})
export class BrandtestComponent implements OnInit {

  nIdProject:string;
  aBrandTestInfo = { code:String, title:String };
  sLastTestType = "";
  
  constructor(private getTest:GettestService,  private router: Router, private httpService: CallhttpService, private route: ActivatedRoute, private formBuilder: FormBuilder, private notifyService:NotifyService, private configService:ConfigService)
    {

    }

  /**************************************************************/
    /*
    * METHOD: on start screen, call info necessary
    * INPUT: event
    * OUTPUT: --
    */
  /*************************************************************/	    

  ngOnInit()
    {
      this.route.paramMap.subscribe(params => {
        this.nIdProject = params.get("id_project");
        if(this.nIdProject)
          {
            let oThis = this;
            this.httpService.callHttpGET(this.configService.TBT_HOST_API + 'brandtest-getone/' + this.nIdProject + "/", 
              function(aData)
                {
                  oThis.aBrandTestInfo = aData;
                  oThis.getTest.aBrandTestInfoSubject.next(aData);

                  //first check if some test if in progress  
                  if(oThis.aBrandTestInfo["test_in_progress"])
                    {
                      // standardizering??
                      if(oThis.aBrandTestInfo["test_in_progress"]["test_standardizing"])
                        {
                          // if yes, go to add test section and continue there.
                          oThis.router.navigate(['/brandtest/' + oThis.nIdProject + '/add/logo']);
                        }
                      // processing???  
                      if(oThis.aBrandTestInfo["test_in_progress"]["test_processing"])
                        {
                          // if yes, go to show result section and continue there.
                          oThis.router.navigate(['/brandtest/' + oThis.nIdProject + "/" + oThis.aBrandTestInfo["test_in_progress"]["test_processing"][1]]);
                        }
                    }

                  // if not processing...   
                  else
                    {
                      // check if have test ready to show.
                      if(oThis.aBrandTestInfo["last_test_used_type"])
                        {
                          oThis.sLastTestType = oThis.aBrandTestInfo["last_test_used_type"];
                          oThis.router.navigate(['/brandtest/' + oThis.nIdProject + "/" + oThis.aBrandTestInfo["last_test_used_type"]]);
                        }
                      // if not, go to add section and continue there.  
                      else
                        {
                          oThis.router.navigate(['/brandtest/' + oThis.nIdProject + "/add/logo/"]);
                        }  
                    }  
                },
                true
            );
          }
        });   

    }

}
