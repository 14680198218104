import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthService } from '../../core/services/auth.service';
import { LoadingService } from '../../core/services/loading.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/app/core/helpers/config.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})

export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  activedAccount = ""
  sReturnUrl = "";
  sCancelUrl = "";

  constructor( private router: ActivatedRoute, private formBuilder: FormBuilder, private authService:AuthService, private loadingService:LoadingService, private route: Router, private configService:ConfigService)
    {
      this.router.queryParams.subscribe(params => {
        this.activedAccount = params['active'];
        if(params['r']) { this.sReturnUrl = params['r']; }
        if(params['c']) { this.sCancelUrl = params['c']; }
      });
    }

  get f() { return this.loginForm.controls; }

  ngOnInit()
    {
      this.loginForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]]
      });
    }


  onSubmit()
    {
      this.submitted = true;
      // stop here if form is invalid
      if (this.loginForm.invalid) {
        return;
      }
      // display form values on success
      let bGoTo = true;
      let oThis = this;
      if(this.sReturnUrl != ""){ bGoTo = false;}
      this.authService.myInsert(this.loginForm.value.email, this.loginForm.value.password, function(){

        if(!bGoTo)
          {
            oThis.route.navigate([oThis.sReturnUrl]);
          }
        else
          {
            oThis.route.navigate([oThis.configService.TBT_RETURN_LOGIN_IF_EMPTY]);                
          }  

      });
    }

}
