import { Component, Input, OnInit } from '@angular/core';
import { GettestService } from '../../../../services/gettest.service';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { ConfigService } from 'src/app/core/helpers/config.service';

@Component({
  selector: 'tbt-result-ccolor',
  templateUrl: './ccolor.component.html',
  styleUrls: ['./ccolor.component.css']
})
export class CcolorComponent implements OnInit {

  nIdTest:string = "last";
  sTestType = "LOGO";
  sShowMode = "project";
  sVisibility = "show";
  
  nProcessPercentage = 0;

  nStepconfig = 0;

  RGBBackList = [
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "13",
    "12"

  ];
  

  RGBBackList2 = [
    "#d4d4d4",
    "#b4b4b4",
    "#909090",
    "#636363",
    "#494848"
  ];

  aCompetitionByColors = [];
  sLogoCurrentSrc = 'assets/images/no-image.jpg';
  nLogoColorPosition = 0;
  aCompetitionByColorsAvgHsv = []

  aResultTestInfo:any;

  @Input() set pshowmode (value: string)
    {
      if(value)
        {
          this.sShowMode = value;
        }
    }

  @Input() set pvisibility (value: string)
    {
      if(value)
        {
          this.sVisibility = value;
        }
    }

  constructor(private httpService: CallhttpService, private router: Router, private route: ActivatedRoute,  private httpClient: HttpClient, private notifyService:NotifyService, public configService:ConfigService) { }

  ngOnInit()
    {
        let oThis = this;
        /*this.getResult.callResult(this.nIdTest, this.sTestType, "competition-color",
          
          // on start check
          function(nIdcheck)
              {
                oThis.nStepconfig = 1;
              },
          // on progress check
          function(nPercentage)
              {
                oThis.nProcessPercentage = nPercentage;
              },         
          // on complete check
            function(aData)
              {
                oThis.aResultTestInfo = aData;
                if(aData.competition_by_colors)
                  {
                    //oThis.RGBBackList = [ ...oThis.RGBBackList, ...oThis.RGBBackList2];
                    oThis.aCompetitionByColors = aData.competition_by_colors;
                    oThis.sLogoCurrentSrc = oThis.configService.TBT_HOSTIMAGES_API + aData.processed_logo;
                    oThis.nLogoColorPosition = aData.processed_logo_by_colors;
                    
                    oThis.aCompetitionByColorsAvgHsv = aData.competition_by_colors_average_hsv;

                    //oThis.sLogoDistanceSrc = oThis.configService.TBT_HOSTIMAGES_API + aData.processed_logo_d;
                    //oThis.sLogoProblemSrc = oThis.configService.TBT_HOSTIMAGES_API + aData.processed_logo_f;
                    setTimeout(function(){
                      oThis.nStepconfig = 2;
                    },1000)
              
                  }
              }                
        );*/
    }

  onChangeTest(a)
    {
      this.nIdTest = a;
      this.ngOnInit();
    }


}
