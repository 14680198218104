import { Injectable, OnInit } from '@angular/core';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';
import { NotifyService } from 'src/app/core/helpers/notify.service';

@Injectable({
  providedIn: 'root'
})
export class GettestService {

  nIdCheckProcessId = 0;
  nCountCallsProcess = 0;
  nLimitCalltoCheck = 120;
  nTimeCalltoCheck = 1000;

  nLastChangePercentage = -1;

  public nIdProject = 0;

  /* COMPLETE INFO OF CURRENT TEST */
  public aBrandTestInfoSubject: BehaviorSubject<{}>;
  public aBrandTestInfo: Observable<{}>;

  public aBrandTestResultsSubject: BehaviorSubject<{}>;
  public aBrandTestResults: Observable<{}>;

  public aDataStatusResultToSave = {};

  constructor(private getTest:GettestService, private router: Router, private httpService: CallhttpService, private configService:ConfigService, private authService:AuthService, private notifyService:NotifyService)
    { 
      let oThis = this;

      this.aBrandTestInfoSubject = new BehaviorSubject<{}>({ });
      this.aBrandTestInfo = this.aBrandTestInfoSubject.asObservable();

      this.aBrandTestResultsSubject = new BehaviorSubject<{}>({ });
      this.aBrandTestResults = this.aBrandTestResultsSubject.asObservable();

      this.aBrandTestInfo.subscribe(function(aData:any) 
        { 
          //get id project
          if(aData && aData.id_project)
            {
              oThis.nIdProject = aData.id_project;
            }
        });  


    }
    
  callResult(nIdTest, sTypeTest, sTestName, onStartCheck=function(nIdCheck) { }, onProgressCheck=function(nPercentage) { }, onFinishCheck=function() { }, onError=function() { }, onComplete=function(aData) { })
    {
      let oThis = this;
      if(sTestName == "all")
        {
          oThis.aBrandTestResultsSubject.next([]);
        }
       else
        {
          let aT = oThis.aBrandTestResultsSubject;
          aT[sTestName] = {}
          oThis.aBrandTestResultsSubject.next(aT);
        } 
      this.httpService.callHttpGET(this.configService.TBT_HOST_API + 'brandtest-getresult-bytype' + '/' + this.nIdProject + "/" + sTypeTest + "/" + nIdTest + "/" + sTestName + '/' , 
        function(aData)
          {
            if(aData.check_id)
              {
                onStartCheck(aData.check_id);
                oThis.nCountCallsProcess = 0;
                let sLastUrlCheck = "";
                oThis.callCheck(aData.check_id, onFinishCheck, onProgressCheck, onError, sLastUrlCheck);
                return false;
              }

            if(aData.result)
              {
                onComplete(aData.result);
                setTimeout(function(){
                  oThis.aBrandTestResultsSubject.next(aData.result);
                },100);
              }

          },
          true    
      );  
    }

    callCheck(nIdCheckProcessId, onFinishCheck=function() { }, onProgressCheck=function(aData) { }, onError=function() { }, sLastUrlCheck)
      {
        let oThis = this;
        this.httpService.callHttpGET(this.configService.TBT_HOST_API + 'brandtest-getresult-bytype-check/' + nIdCheckProcessId + "/" + this.nIdProject, 
          function(aData)
            {
              if(aData.status == "F") 
                {
                  onProgressCheck(100);
                  setTimeout(function(){
                    onFinishCheck();
                  }, 100);
                }
              else if(aData.status == "P" || aData.status == "N")
                {
                  if(aData.status == "P")
                    {
                      if(oThis.nLastChangePercentage != aData.percentage)
                        {
                          oThis.nLastChangePercentage = aData.percentage;
                          oThis.nCountCallsProcess = 0;
                          onProgressCheck(aData.percentage);    
                        }
                    }

                  if(aData.status == "N")
                    {
                      onProgressCheck(5);
                    }

                    //check max calls
                    if(oThis.nCountCallsProcess < oThis.nLimitCalltoCheck )
                      {
                        //check if get out of page
                        if(sLastUrlCheck == "") { sLastUrlCheck = oThis.router.url; }
                        if(sLastUrlCheck == oThis.router.url)
                          {
                            setTimeout(function(){
                              oThis.callCheck(nIdCheckProcessId, onFinishCheck, onProgressCheck, onError, sLastUrlCheck); 
                            }, oThis.nTimeCalltoCheck); 

                            oThis.nCountCallsProcess = oThis.nCountCallsProcess + 1;    
                          }
                      }
                    else
                      {
                        // si se acaba el tiempo puedo emitir un mensaje. que no se pudo completar el proceso   
                        onError(); 
                      }  

                } else if(aData.status == "E")
                  {
                    //oThis.notifyService.createNotify('error', 'unexpected error');
                    onError(); 
                  }
            }
        );    
      }

    callToSaveChangesInResult(sResultName, aDataToSave)
      {
        //convert to json
        let sDataToSave = JSON.stringify(aDataToSave)
        //convert to base 64
        let sBase64DataToSave = btoa(sDataToSave);

        let bCheckSave = false;
        if(this.aDataStatusResultToSave[sResultName])
          {
            if(this.aDataStatusResultToSave[sResultName] == sBase64DataToSave)
              {
                //alert("es igual no me sirve");
              }
            else
              {
                //alert("es diferente tengo que guardar");
                bCheckSave = true;
              }  
          }
        else
          {
            bCheckSave = true;
            //alert("first time");
          }  

          if(bCheckSave)
            {
              this.aDataStatusResultToSave[sResultName] = sBase64DataToSave;
              let oThis = this;
              this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'brandtest-save-changes-results/', { project_id: this.nIdProject, reference: sResultName, status: this.aDataStatusResultToSave[sResultName] }, 
                function(aData)
                  {
                    //alert("cambios guardados");                    
                  }
              );    
            }

      }

    onCancelProccesingLogo(onFinish=function(nIdProject) { })
      {
        let oThis = this;
        this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'brandtest-cancel-processing-test/' + this.nIdProject, { }, function (aData) {
          onFinish(oThis.nIdProject);
        });
      }

}

@Injectable({ providedIn: 'root' })
export class getTestGuardService implements CanActivate {

    constructor(
        private router: Router,
        private getTest:GettestService 
    ) { }


    /**************************************************************/
        /*
        * METHOD: this is a service to check if have access to view a url.
        * INPUT: --
        * OUTPUT: --
        */
    /*************************************************************/	

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        {
            if (this.getTest.nIdProject) {
                return true;
            }

            this.router.navigate(['/projects']);
            return false;
        }
}