import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compatibility',
  templateUrl: './compatibility.component.html',
  styleUrls: ['./compatibility.component.css']
})
export class CompatibilityComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
