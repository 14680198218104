import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { NotifyService } from './notify.service';
import { LoadingService } from '../services/loading.service';

@Injectable({
  providedIn: 'root',
})
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(private notifyService: NotifyService, private loadingService: LoadingService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingService.loadingSubject.next(true);

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Si la respuesta es una HttpResponse exitosa
          this.notifyService.destroyAll();
          this.loadingService.loadingSubject.next(false);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.loadingService.loadingSubject.next(false);

        // Verificar si el error contiene mensajes específicos code_es y code_en
        let errorMessage = '';
        if (error.error && (error.error.code_es || error.error.code_en)) {
          errorMessage = error.error.code_es || error.error.code_en;
        } else {
          // Mensaje genérico si no hay mensajes específicos
          errorMessage = 'Ups!, no pudimos comunicarnos con el servidor: ' + error.statusText;
        }

        // Mostrar notificación de error según el idioma
        const language = localStorage.getItem('locale');
        if (language === 'es' && error.error && error.error.code_es) {
          this.notifyService.createNotify('error', 'Error: ' + error.error.code_es);
        } else if (language !== 'es' && error.error && error.error.code_en) {
          this.notifyService.createNotify('error', 'Error: ' + error.error.code_en);
        } else {
          this.notifyService.createNotify('error', 'Error: ' + errorMessage);
        }

        // Devolver el error para manejo adicional si es necesario
        return throwError(error);
      })
    );
  }
}
