import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { GettestService } from '../../../../services/gettest.service';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { Colorwheel } from './colorwheel';

@Component({
  selector: 'tbt-result-harmony',
  templateUrl: './harmony.component.html',
  styleUrls: ['./harmony.component.css']
})


export class HarmonyComponent implements OnInit {
  @ViewChild("colorspace", null) masterMenu: ElementRef;

  @Output() ponchangetest: EventEmitter<any> = new EventEmitter();
  @Output() ponchangetosave: EventEmitter<any> = new EventEmitter();


  sMainLogoSrc = 'assets/images/no-image.jpg';

  sTestName = "color-visibility";
  sTestType = "logo";

  sShowMode = "project";
  sVisibility = "show";
  
  nProcessPercentage = 0;

  nStepconfig = 0;

  aResultTestInfo:any;

  aTestPerformed:any;
  aTestPerformedTBT:any;
  
  bIfMonchromatic = true;
  bResultComplete = false;

  RGBBackList = [];
  RGBBackListFilter = [];

  @Input() set pshowmode (value: string)
    {
      if(value)
        {
          this.sShowMode = value;
        }
    }

  @Input() set pvisibility (value: string)
    {
      if(value)
        {
          this.sVisibility = value;
        }
    }

  aBrandTestResults:any;


  constructor(private getTest:GettestService,private httpService: CallhttpService, private router: Router, private route: ActivatedRoute,  private httpClient: HttpClient, private notifyService:NotifyService, private configService:ConfigService) { }

  ngOnInit()
    {
      let oThis = this;
      this.getTest.aBrandTestResults.subscribe(function(aData) 
        { 
          oThis.aBrandTestResults = aData;
          if(oThis.aBrandTestResults[oThis.sTestName])
            {
              let aData = oThis.aBrandTestResults[oThis.sTestName];
              oThis.aResultTestInfo = aData;
              if(aData.colors)
                  {
                    oThis.nStepconfig = 2;
                    setTimeout(function(){
                      oThis.drawColorWheel(aData.colors);  
                    },500)

                  }
            }
        });
     
    }

  drawColorWheel(RGBList)
    {
      this.RGBBackList = [];
      this.RGBBackListFilter = [];

      let colorWheel = new Colorwheel();
      this.masterMenu.nativeElement.innerHTML = "";
      colorWheel.init(this.masterMenu.nativeElement);

      var cuad = [0,0,0,0];
      var colors = [];
      var nc = 0
      var elem =  this;
      RGBList = colorWheel.sortByKey(RGBList, 2);
      RGBList.forEach(function (color) {
        elem.RGBBackListFilter.push(color);

          var [h, s, v, i] = colorWheel.rgbToHsv(color[0], color[1], color[2]);
          var ifGrey = (Math.abs(color[0]-color[1]) < 40 && Math.abs(color[0]-color[2]) < 40 && Math.abs(color[1]-color[2]) < 40)
          if(!colors.includes(i) && (s > 0.1 && v > 0.1 && !ifGrey) && colors.length < 4)
            {
              elem.RGBBackList.push(color);
              colorWheel.plotHsv(h, 0.88, v, i);
              var c = colorWheel.calculateQuadrant(i);
              if(c == 1) { cuad[0] = cuad[0] + 1; }
              if(c == 2) { cuad[1] = cuad[1] + 1; }
              if(c == 3) { cuad[2] = cuad[2] + 1; }
              if(c == 4) { cuad[3] = cuad[3] + 1; }
          
              colors[nc] = i;
              nc++;    
            }
      });
      
      if(colors.length>1)
        {
          colorWheel.renderConvexHull();      
        }
      
      var ncuad = 0;
      for(var j=0;j<4;j++)
          {
              if(cuad[j]>0)
                  {
                      ncuad++;
                  }
          }

      if(ncuad == 1)
          {
              this.bIfMonchromatic = true;
              document.getElementById('result').innerHTML = '<h3>Monochromatic combination: analogous colors.</h3>';
          }
      else if(ncuad == colors.length)
          {
            this.bIfMonchromatic = false;
              document.getElementById('result').innerHTML = '<h3>Excellent combination, complementary colors, very versatile.</h3>';
          }
      else if(ncuad != colors.length && colors.length > 2 && Math.abs(colors.length-ncuad) == 1)
          {
              this.bIfMonchromatic = false;
              document.getElementById('result').innerHTML = '<h3>Optimal combination, but the color combination can be improved.</h3>';
          }
      else
          {
              this.bIfMonchromatic = false;
              document.getElementById('result').innerHTML = '<h3>The color scheme can be improved.</h3>';
          }  
      
      this.bResultComplete = true;

    }

    onChangeTest(nIdTest)
    {
      this.ponchangetest.emit([nIdTest, this.sTestName]); 
      this.sMainLogoSrc = 'assets/images/no-image.jpg';
    }

}
