import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'tbt-uploadname',
  templateUrl: './uploadname.component.html',
  styleUrls: ['./uploadname.component.css']
})
export class UploadnameComponent implements OnInit {


  @Output() onCancelUpload: EventEmitter<any> = new EventEmitter();
  @Output() onConfirmName: EventEmitter<any> = new EventEmitter();
  @Input()
  pidproject:string;

  nStepConfig = 0;
  nIdProject:string;

  brandnameForm: FormGroup;
  submitted = false;


  constructor(private formBuilder: FormBuilder, private httpService: CallhttpService, private router: Router, private httpClient: HttpClient, private route: ActivatedRoute, private notifyService:NotifyService, private configService:ConfigService) { }




  get f() { return this.brandnameForm.controls; }

  ngOnInit()
    {

      this.brandnameForm = this.formBuilder.group({
        brandname: ['', [Validators.required, Validators.maxLength(30), Validators.minLength(2), Validators.pattern('^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$')]]
      });

    }

  onSubmit()
    {
      this.submitted = true;
      // stop here if form is invalid
      if (this.brandnameForm.invalid) {
        return;
      }
      this.onConfirmName.emit([ this.brandnameForm.value.brandname ]);
    }


  /**************************************************************/
    /*
    * METHOD: on cancel process logo
    * INPUT: event
    * OUTPUT: --
    */
  /*************************************************************/	

  onCancelProccesingName()
    {
      let oThis = this;
      oThis.nStepConfig = 0;
      this.onCancelUpload.emit([]);
    }
}
