import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {

  footerTests01 = ['Test 1', 'Test 2', 'Test 3', 'Test 4', 'Test 5', 'Test 6', 'Test 7'];
  footerTests02 = ['Test 8', 'Test 9', 'Test 10', 'Test 11', 'Test 12', 'Test 13', 'Test 14'];
  footerTests03 = ['Test 15', 'Test 16', 'Test 17', 'Test 18', 'Test 19'];
  footerTests04 = ['Planes y precios', 'Cómo usar TBT', 'Porqué usar TBT'];

  languageImages = {
    'en': './../../../assets/images/langIcon/en.png',
    'es': './../../../assets/images/langIcon/es.png'
  };

  constructor(public translate:TranslateService, private ccService: NgcCookieConsentService) { }

  ngOnInit(): void {

    const storedLanguage = localStorage.getItem('locale');
    if (storedLanguage) {
      this.translate.use(storedLanguage);
    }

    this.translate//
    .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
    .subscribe(data => {

      this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
      // Override default messages with the translated ones
      this.ccService.getConfig().content.header = data['cookie.header'];
      this.ccService.getConfig().content.message = data['cookie.message'];
      this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
      this.ccService.getConfig().content.allow = data['cookie.allow'];
      this.ccService.getConfig().content.deny = data['cookie.deny'];
      this.ccService.getConfig().content.link = data['cookie.link'];
      this.ccService.getConfig().content.policy = data['cookie.policy'];

      this.ccService.destroy();//remove previous cookie bar (with default messages)
      this.ccService.init(this.ccService.getConfig()); // update config with translated messages
    });

  }
  dropdownOpen = false;

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }

  getLanguageImage(): string {
    const lang = this.translate.currentLang;
    return this.languageImages[lang];
  }

  changeLang(language: string) {  
    localStorage.setItem('locale', language);  
    this.translate.use(language);  
    this.dropdownOpen = false; 
    this.ngOnInit(); 
  } 

}
