import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'tbt-category-separator',
  templateUrl: './category-separator.component.html',
  styleUrls: ['./category-separator.component.css']
})
export class CategorySeparatorComponent implements OnInit {

  @Input()
  pclass:string;

  @Input()
  ptitle:string;

  @Input()
  piconimg:string;

  constructor() { }

  ngOnInit() {
  }



}
