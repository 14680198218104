import { Component, OnInit } from '@angular/core';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import {loadStripe} from '@stripe/stripe-js';
import { PaymentService } from '../../core/services/payment.service';

@Component({
  selector: 'app-shoppingcart',
  templateUrl: './shoppingcart.component.html',
  styleUrls: ['./shoppingcart.component.css']
})
export class ShoppingcartComponent implements OnInit {

  rPlanSelected = { reference:"", title:"", quantity:"", price:"" }
  rCartTbt = { subtotal:"", discount:"", total:"", rPromotionalCode:{ active:false, code:"", type:"", value:"" }}

  nGeneratedOrder = 0;

  nPurchasePlanID = "";
  nPurchaseTestID = "";


  bShowCartStep = false;
  bShowPayMethod = false;

  constructor(private route: ActivatedRoute, private router: Router, private httpService: CallhttpService, private configService:ConfigService, private paymentService: PaymentService) { }

  ngOnInit()
    {
      let oThis = this;
      this.httpService.callHttpGET(this.configService.TBT_HOST_API + 'tbt-get-selected-plan/', 
        function(aData)
          {
            if(aData && aData.redirect)
              {
                oThis.router.navigate(['/'+aData.redirect+'/']);
              }

            if(aData && aData.reference)
              {
                oThis.onClickinPlan(aData);
              }
              
          },true
      ); 
      
    }

  onClickinPlan(aData)
    {
      this.rPlanSelected.reference = aData.reference;
      this.rPlanSelected.title = aData.title;
      this.rPlanSelected.quantity = aData.quantity;
      this.rPlanSelected.price =  parseFloat(aData.total_price).toFixed(2);
      if(aData.discount)
        {
          this.rCartTbt.rPromotionalCode.active = true;
          this.rCartTbt.rPromotionalCode.code = aData.discount.code;
          this.rCartTbt.rPromotionalCode.type = aData.discount.type;
          this.rCartTbt.rPromotionalCode.value = aData.discount.value;
        }

      this.calculateTotalPrice();
    }

  calculateTotalPrice()
    {
      //check if have cupon code
      if(this.rCartTbt.rPromotionalCode.active)
        {
          let nDiscountValuePercentage = ((parseFloat(this.rPlanSelected.price)*parseFloat(this.rCartTbt.rPromotionalCode.value))/100);
          let nTotalPrice = parseFloat(this.rPlanSelected.price) - nDiscountValuePercentage;
          
          this.rCartTbt.subtotal = parseFloat(this.rPlanSelected.price).toFixed(2);
          this.rCartTbt.discount = nDiscountValuePercentage.toFixed(2);
          this.rCartTbt.total = nTotalPrice.toFixed(2);
        }
      else
        {
          this.rCartTbt.subtotal = parseFloat(this.rPlanSelected.price).toFixed(2);
          this.rCartTbt.discount = parseFloat("0").toFixed(2);
          this.rCartTbt.total = parseFloat(this.rPlanSelected.price).toFixed(2);
        }  
    }  

  onClickInAddPromotionalCode(sCodePromotional)
    {
      if(sCodePromotional)
        {
          let oThis = this;
          this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'tbt-check-promotional-code/', { promotional_code:sCodePromotional }, 
          function(aData)
            {
              if(aData.type && aData.value)
                {
                  oThis.rCartTbt.rPromotionalCode.active = true;
                  oThis.rCartTbt.rPromotionalCode.code = sCodePromotional;
                  oThis.rCartTbt.rPromotionalCode.type = aData.type;
                  oThis.rCartTbt.rPromotionalCode.value = aData.value;
                  oThis.calculateTotalPrice();
                } 
            }
          );  
        }
      else
        {
          // codigo no valido
        }  
    }

  async onClickInConfirmOrder()
    {
      let oThis = this;
      // Create an instance of the Stripe object with your publishable API key
      const stripe = await loadStripe(this.configService.TBT_STRIPE_PUBLIC_KEY);
      this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'tbt-generate-order/', {  }, 
      function(aData)
        {
          if(aData.session_id)
            {
              return stripe.redirectToCheckout({ sessionId: aData.session_id });
            }
          else if(aData.redirect)
            {
              oThis.router.navigate(['/'+aData.redirect+'/']);
            } 
        }
      );
    }

}
