import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    public loadingSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
    public bIfloading = this.loadingSubject.asObservable();

    constructor() { }
}