import { Component, Input, OnInit } from '@angular/core';
import { GettestService } from '../../../../services/gettest.service';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { ConfigService } from 'src/app/core/helpers/config.service';

@Component({
  selector: 'tbt-result-cshape',
  templateUrl: './cshape.component.html',
  styleUrls: ['./cshape.component.css']
})
export class CshapeComponent implements OnInit {

  nIdTest:string = "last";
  sTestType = "LOGO";
  sShowMode = "project";
  sVisibility = "show";
  
  nProcessPercentage = 0;

  nStepconfig = 0;

  logo_labels = []
  logo_detect = []
  logo_best_label = ""
  visually_similar_images = []
  logo_text_annotations = []
  logo_objects_found = []

  aResultTestInfo:any;

  @Input() set pshowmode (value: string)
    {
      if(value)
        {
          this.sShowMode = value;
        }
    }

  @Input() set pvisibility (value: string)
    {
      if(value)
        {
          this.sVisibility = value;
        }
    }

  constructor(private httpService: CallhttpService, private router: Router, private route: ActivatedRoute,  private httpClient: HttpClient, private notifyService:NotifyService, private configService:ConfigService) { }

  ngOnInit()
    {
        let oThis = this;
        /*this.getResult.callResult(this.nIdTest, this.sTestType, "competition-shape",
          
          // on start check
          function(nIdcheck)
              {
                oThis.nStepconfig = 1;
              },
          // on progress check
          function(nPercentage)
              {
                oThis.nProcessPercentage = nPercentage;
              },         
          // on complete check
            function(aData)
              {
                oThis.aResultTestInfo = aData;
                if(aData)
                  {
                    oThis.logo_labels = aData.logo_labels;
                    oThis.logo_detect = aData.logo_detect;
                    oThis.logo_best_label = aData.logo_best_label;
                    oThis.visually_similar_images = aData.visually_similar_images;
                    oThis.logo_text_annotations = aData.logo_text_annotations;
                    oThis.logo_objects_found = aData.logo_objects_found;

                    
                    //oThis.RGBBackList = [ ...oThis.RGBBackList, ...oThis.RGBBackList2];
                    /*oThis.aCompetitionByColors = aData.competition_by_colors;
                    oThis.sLogoCurrentSrc = oThis.configService.TBT_HOSTIMAGES_API + aData.processed_logo;
                    oThis.nLogoColorPosition = aData.processed_logo_by_colors;
                    
                    oThis.aCompetitionByColorsAvgHsv = aData.competition_by_colors_average_hsv;
                    //oThis.sLogoDistanceSrc = oThis.configService.TBT_HOSTIMAGES_API + aData.processed_logo_d;
                    //oThis.sLogoProblemSrc = oThis.configService.TBT_HOSTIMAGES_API + aData.processed_logo_f;
                    setTimeout(function(){
                      oThis.nStepconfig = 2;
                    },1000)
              
                  }
              }                
        );*/
      }

    onChangeTest(a)
      {
        this.nIdTest = a;
        this.ngOnInit();
      }


}
