import { Component, Input, OnInit } from '@angular/core';
import { GettestService } from '../../../../services/gettest.service';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { ConfigService } from 'src/app/core/helpers/config.service';

@Component({
  selector: 'tbt-result-readability',
  templateUrl: './readability.component.html',
  styleUrls: ['./readability.component.css']
})
export class ReadabilityComponent implements OnInit {

  sColorLogoSrc = 'assets/images/no-image.jpg';
  sBlacwhiteLogoSrc = 'assets/images/no-image.jpg';

  nStepconfig = 0;
  nProcessPercentage = 0;

  nIdTest:string = "last";
  sTestType = "LOGO";
  sShowMode = "project";
  sVisibility = "show";

  aResultTestInfo:any;

  @Input() set pshowmode (value: string)
    {
      if(value)
        {
          this.sShowMode = value;
        }
    }

  @Input() set pvisibility (value: string)
    {
      if(value)
        {
          this.sVisibility = value;
        }
    }

  constructor(private getTest:GettestService, private httpService: CallhttpService, private router: Router, private route: ActivatedRoute,  private notifyService:NotifyService, private configService:ConfigService)
    {
    }

  ngOnInit()
    {
      let oThis = this;
      /*this.getResult.callResult(this.nIdTest, this.sTestType, "shape-readability",
        
        // on start check
        function(nIdcheck)
            {
              oThis.nStepconfig = 1;
            },
        // on progress check
        function(nPercentage)
            {
              oThis.nProcessPercentage = nPercentage;
            },         
        // on complete check
          function(aData)
            {
              oThis.aResultTestInfo = aData;
              if(aData.color_logo && aData.black_white_logo)
                {
                  oThis.sColorLogoSrc = oThis.configService.TBT_HOSTIMAGES_API + aData.color_logo;
                  oThis.sBlacwhiteLogoSrc = oThis.configService.TBT_HOSTIMAGES_API + aData.black_white_logo;
                  setTimeout(function(){
                    oThis.nStepconfig = 2;
                  },1000);
                }  
            }                
      );*/
    }

    onChangeTest(a)
      {
        this.nIdTest = a;
        this.ngOnInit();
      }



}