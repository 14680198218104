import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GettestService } from '../../services/gettest.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { stringify } from 'querystring';
import { IfStmt } from '@angular/compiler';
import { Router } from '@angular/router';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'box-result',
  templateUrl: './box-result.component.html',
  styleUrls: ['./box-result.component.css']
})
export class BoxResultComponent implements OnInit {

  @Input()
  ptitle: string;

  @Input()
  picon: string;

  @Input()
  pscore: string;

  @Input()
  ptypetest: string;

  aResultInfo: any = { id_project: "", result_name: "" };

  nIdProject = 0;

  sShowMode = "project";
  sVisibility = "show";

  bShowBlockSelectOptionsUserTests = false;
  bShowBlockSelectOptionsTbtTests = true;

  bIfEnabledTestToShowResult = "enabled";
  category_bar: string = "";

  /**************************************************************/
  /*
  * METHOD: on change current id_test
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/
  simulado = false;

  @Input() set presultinfo(value: string) {
    if (value) {
      this.aResultInfo = value;
    }

    if (this.aResultInfo) {
      this.onUpdateBoxResultInfo()
    }
  }

  @Input() set pshowmode(value: string) {
    if (value) {
      this.sShowMode = value;
      this.simulado = (this.sShowMode == 'editreport' || this.sShowMode == 'showreport') ? true : false;

    }
  }

  @Input() set pvisibility(value: string) {
    if (value) {
      this.sVisibility = value;
    }
  }


  aBrandTestInfo: any;

  aTestPerformedInfo = [];
  aTestTbtInfo = [];

  rCurrentBrandTestInfo = { test_object: '', id_test: "", test_type: "logo" };

  /**************************************************************/
  /*
  * METHOD: construvtor, get all info of current brandtest.
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/
  closeResult: string;
  title = 'appBootstrap';

  constructor(private router: Router, private httpService: CallhttpService, private getTest: GettestService, public configService: ConfigService, private modalService: NgbModal) {
    let oThis = this;
    oThis.getTest.aBrandTestInfo.subscribe(data => { oThis.aBrandTestInfo = data; oThis.nIdProject = oThis.aBrandTestInfo.id_project; });
  }


  /**************************************************************/
  /*
  * METHOD: onUpdateBoxResultInfo, action on complete get brtand test info.
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/

  onUpdateBoxResultInfo() {
    // set status odf test
    if (this.aResultInfo.result_status) {
      this.bIfEnabledTestToShowResult = this.aResultInfo.result_status;
    }

    // user test
    if (this.aResultInfo.info_tests_performed) {
      let aTestAuxPerformedInfo = this.aResultInfo.info_tests_performed;
      this.aTestPerformedInfo = [];
      for (let rTestAuxPerformedInfo of aTestAuxPerformedInfo) {
        if (rTestAuxPerformedInfo.is_current == false) {
          this.aTestPerformedInfo.push(rTestAuxPerformedInfo);
        }
        else if (rTestAuxPerformedInfo.is_current == true) {
          this.rCurrentBrandTestInfo = rTestAuxPerformedInfo;
        }
      }
    }

    //tbt tests.    
    if (this.aResultInfo.info_tests_tbt) {
      let aTestAuxTbtInfo = this.aResultInfo.info_tests_tbt;
      this.aTestTbtInfo = [];
      for (let rTestAuxTbtInfo of aTestAuxTbtInfo) {
        if (true) {
          this.aTestTbtInfo.push(rTestAuxTbtInfo);
        }
      }
    }

  }


  /**************************************************************/
  /*
  * METHOD: onClickChangeTest, action on complete get brtand test info.
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/

  @Output() clicked: EventEmitter<any> = new EventEmitter();
  onClickChangeTest(nIdTest) {
    this.clicked.emit([nIdTest]);
  }

  /**************************************************************/
  /*
  * METHOD: onClickCompareTest, click on select test to compare.
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/

  @Output() clickedCompare: EventEmitter<any> = new EventEmitter();
  onClickCompareTest(nIdTest, rLastResult) {
    this.clickedCompare.emit([nIdTest, rLastResult]);
  }

  /**************************************************************/
  /*
  * METHOD: onChangeSelectTypeOfTest, change test of user of tbt to compare.
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/

  onChangeSelectTypeOfTest(sValue) {
    if (sValue == "user") {
      this.bShowBlockSelectOptionsUserTests = true;
      this.bShowBlockSelectOptionsTbtTests = false;
    }
    else {
      this.bShowBlockSelectOptionsUserTests = false;
      this.bShowBlockSelectOptionsTbtTests = true;
    }
  }


  ngOnInit() {
    this.category_bar = localStorage.getItem('category_bar');
  }


  /**************************************************************/
  /*
  * METHOD: onclickActivateTestButton, change test of user of tbt to compare.
  * INPUT: event
  * OUTPUT: --
  */
  /*************************************************************/

  onclickActivateTestButton(nIdTest) {
    let oThis = this;
    this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'tbt-activate-test/', { test_id: nIdTest },
      function (aData) {
        if (aData == "OK") {
          let currentUrl = oThis.router.url;
          oThis.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            oThis.router.navigate([currentUrl]);
          });
        }
        else {
          oThis.router.navigate(["/prices/"]);
        }
      }
    );
  }
  
  getCategoryBarIcon(category: string): string {
    switch (category) {
      case 'shape':
        return 'assets/svg/icon_bar_1.svg';
      case 'large':
        return 'assets/svg/icon_bar_2.svg';
      case 'small':
        return 'assets/svg/icon_bar_3.svg';
      case 'digital':
        return 'assets/svg/icon_bar_4.svg';
      case 'survey':
        return 'assets/svg/icon_bar_5.svg';
      default:
        return ''; 
    }
  }

  /**************************************************************/
  /*
  * METHOD: onClickDeleteFromReport, hide result of report (trash).
  * INPUT: --
  * OUTPUT: --
  */
  /*************************************************************/

  onClickShowHideResultFromReport() {
    let oThis = this;
    let sResultName = this.aResultInfo.result_name;
    let nIdProject = this.nIdProject;

    this.httpService.callHttpPOST(this.configService.TBT_HOST_API + 'report-showhide-result/', { project_id: nIdProject, result_name: sResultName },
      function (aData) {
        if (aData == "OK") {
          oThis.sVisibility = (oThis.sVisibility == 'show') ? 'hide' : 'show';
        }
      }
    );
  }


  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


}