import { Component, OnInit } from '@angular/core';
import { PaymentService } from '../core/services/payment.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  transactionId = "";
  constructor(private payment: PaymentService) { }

  ngOnInit() {
    this.transactionId = this.payment.transactionID;
  }

}
