import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';

@Injectable({ providedIn: 'root' })
export class NotifyService {

    private readonly notifier: NotifierService;

    constructor( notifierService: NotifierService ) {
        this.notifier = notifierService;
    }


    /**************************************************************/
        /*
        * METHOD: createNotify: Create a floating notify boostrap.
        * INPUT: type(danger, warning, info, etc. see bostrap doc)
        * OUTPUT: --
        */
    /*************************************************************/	

    createNotify(sType:string, sText:string)
        {
            this.notifier.hideAll();
            this.notifier.notify(sType, sText);
        }

    /**************************************************************/
        /*
        * METHOD: destroyAll: Delete all notifications
        * INPUT: --
        * OUTPUT: --
        */
    /*************************************************************/	        

    destroyAll()
        {
            this.notifier.hideAll();
        }    
}