import { Component, OnInit, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() progressEndValue: string;
  @Input() colorProgressbar: string;
  @Input() imageUrl: string;
  @Input() imageAlt: string;
  @Input() text: string;

  progressValue: number;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    let progressStartValue = 0;
    let endValue = parseInt(this.progressEndValue);
    this.progressValue = progressStartValue;

    const circularProgress = this.elementRef.nativeElement.querySelector('.circular-progress') as HTMLElement;
    circularProgress.style.background = `conic-gradient(${this.colorProgressbar} ${progressStartValue * 3.6}deg, #ededed 0deg)`;

    const intervalDuration = 20; // Duración de cada incremento en milisegundos
    const totalDuration = (endValue - progressStartValue) * intervalDuration; // Duración total de la animación
    
    setTimeout(() => {
      circularProgress.style.background = `conic-gradient(${this.colorProgressbar} ${endValue * 3.6}deg, #ededed 0deg)`;
    }, totalDuration);
  }
}
