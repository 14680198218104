import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'functionality-bar-score',
  templateUrl: './bar-score.component.html',
  styleUrls: ['./bar-score.component.scss'],
})
export class BarScoreComponent implements OnInit {
  @Input() nvalue: string;
  @Input() nmode: string;
  @Input() pcolor: string;

  constructor() {}
  
  ngOnInit() {
    this.getScore()
  }

  getScore() {
    return this.nvalue;
  }
}
