import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GettestService } from '../../../../services/gettest.service';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { ConfigService } from 'src/app/core/helpers/config.service';

@Component({
  selector: 'tbt-result-csimplicity',
  templateUrl: './csimplicity.component.html',
  styleUrls: ['./csimplicity.component.css']
})
export class CsimplicityComponent implements OnInit {

  @Output() ponchangetest: EventEmitter<any> = new EventEmitter();

  sMainLogoSrc = 'assets/images/no-image.jpg';
  nMainLogoValue = 0;
  sSecondLogoSrc = 'assets/images/no-image.jpg';
  nSecondLogoValue = 0;

  nColorLogoValue = 0;


  nDrawProgressValue = 0;
  nTimeAnimation = 1500;
  nDrawProgressWorking = false;
  nCurrentBarValue = 0;
  
  sTestName = "color-simplicity";
  sTestType = "logo";

  sShowMode = "project";
  sVisibility = "show";
  
  nProcessPercentage = 0;

  nStepconfig = 0;

  aResultTestInfo:any;

  @Input() set pshowmode (value: string)
    {
      if(value)
        {
          this.sShowMode = value;
        }
    }

  @Input() set pvisibility (value: string)
    {
      if(value)
        {
          this.sVisibility = value;
        }
    }

    aBrandTestResults:any;

    constructor(private getTest:GettestService, private httpService: CallhttpService, private router: Router, private route: ActivatedRoute,  private httpClient: HttpClient, private notifyService:NotifyService, private configService:ConfigService)
    {
      
    }

  ngOnInit()
    {
      let oThis = this;
      this.getTest.aBrandTestResults.subscribe(function(aData) 
        { 
          oThis.aBrandTestResults = aData;
          if(oThis.aBrandTestResults[oThis.sTestName])
            {
              let aData = oThis.aBrandTestResults[oThis.sTestName];
              oThis.aResultTestInfo = aData;
              if(aData.simplicity_score && aData.transparent_logo)
                  {
                    oThis.nStepconfig = 2;
                    setTimeout(function() { 
                      oThis.sMainLogoSrc = aData.transparent_logo;
                      oThis.nMainLogoValue = aData.simplicity_score;
                      oThis.drawProgressBar("#idBlockMarker", oThis.nMainLogoValue);  
                    },1000)
                  }
            }
        });  
    }

  onChangeTest(nIdTest)
    {
      this.ponchangetest.emit([nIdTest, this.sTestName]); 
      //this.nIdTest = a;
      //this.ngOnInit();
    }


  onCompareTest(a, aData)
    {
      alert("Compare action");
    }


  drawProgressBar(sElem, nValue)
    {
      if(nValue!=0)
        {
          if(nValue > 100) { nValue = 100;}
          if(nValue < 0) { nValue = 0;}
          let nValueDraw = nValue;
          let nDifferenceBarValue = Math.ceil((Math.abs(nValueDraw -  this.nCurrentBarValue)));
          let nTimeAnimationCustom =  this.nTimeAnimation;
          if(nDifferenceBarValue < 50)
            {
              nTimeAnimationCustom =  this.nTimeAnimation / 2;
            }

          if(nDifferenceBarValue < 10)
            {
              nTimeAnimationCustom = this.nTimeAnimation / 3;
            }

          this.nDrawProgressWorking = true;
          this.nCurrentBarValue = nValue;
          $("span", sElem).css({ left: nValueDraw+"%" });
          $("#idBlockProgressBar2").animate({ width: nValueDraw+"%" }, nTimeAnimationCustom, function() { $(sElem).fadeIn("slow").addClass("animated bounce"); });

          this.drawProgressValue(nValue, Math.ceil(nTimeAnimationCustom/Math.abs(nValue-this.nDrawProgressValue)));
    
        }
    } 


  drawProgressValue(nValue, nTime)
    {
      if(nValue > this.nDrawProgressValue) {  this.nDrawProgressValue = this.nDrawProgressValue+1; } else {  this.nDrawProgressValue = this.nDrawProgressValue-1; }
      if(nValue != this.nDrawProgressValue)
        {
          let elem = this
          setTimeout(() => {
            elem.drawProgressValue(nValue, nTime);
          }, nTime);

        }
      else
        {
          this.nDrawProgressWorking = false;
        }  
    }

  onClickInLogoCompare(nValuation, sSrc)
    {
      if(this.nDrawProgressWorking == false && this.nSecondLogoValue != nValuation)
        {
          $(".simplicityComparaLogosContainer2 img").css("opacity", "1");
          let elem = this;
          $("#idBlockMarker2Second").fadeOut(function(){
            elem.sSecondLogoSrc = sSrc;
            elem.nSecondLogoValue = nValuation;
            elem.drawProgressBar("#idBlockMarker2Second", elem.nSecondLogoValue);
          });    
        }
    }  

  onClickInMarker(sMarker)
    {
      if(this.nDrawProgressWorking == false)
        {
          if(sMarker == "main")
            {
              this.drawProgressBar("#idBlockMarker2", this.nColorLogoValue);
            }
          else
            {
              this.drawProgressBar("#idBlockMarker2Second", this.nSecondLogoValue);
            }  
        }
    }



}
