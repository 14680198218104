import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-successful-appointment',
  templateUrl: './successful-appointment.component.html',
  styleUrls: ['./successful-appointment.component.scss']
})
export class SuccessfulAppointmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
