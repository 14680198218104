import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GettestService } from '../../../../services/gettest.service';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { ConfigService } from 'src/app/core/helpers/config.service';

@Component({
  selector: 'tbt-result-balancing',
  templateUrl: './balancing.component.html',
  styleUrls: ['./balancing.component.css']
})
export class BalancingComponent implements OnInit {

  @Output() ponchangetest: EventEmitter<any> = new EventEmitter();
  @Output() ponchangetosave: EventEmitter<any> = new EventEmitter();

  sMainLogoSrc = 'assets/images/no-image.jpg';
  nMainLogoValue = 0;
  nMainLogoValue2 = 0;
  nMainLogoValueBalanceLR = 50;

  nBalancingStatus = "";
  
  sTestName = "shape-balancing";
  sTestType = "logo";

  sShowMode = "project";
  sVisibility = "show";
  
  nProcessPercentage = 0;

  nStepconfig = 0;

  aResultTestInfo:any;

  @Input() set pshowmode (value: string)
    {
      if(value)
        {
          this.sShowMode = value;
        }
    }

  @Input() set pvisibility (value: string)
    {
      if(value)
        {
          this.sVisibility = value;
        }
    }

  aBrandTestResults:any;

  constructor(private getTest:GettestService,private httpService: CallhttpService, private router: Router, private route: ActivatedRoute,  private httpClient: HttpClient, private notifyService:NotifyService, private configService:ConfigService) { }

  ngOnInit()
    {
        let oThis = this;
        this.getTest.aBrandTestResults.subscribe(function(aData) 
          { 
            oThis.aBrandTestResults = aData;
            console.log(oThis.aBrandTestResults);
  
            if(oThis.aBrandTestResults[oThis.sTestName])
              {
                let aData = oThis.aBrandTestResults[oThis.sTestName];
                oThis.aResultTestInfo = aData;
                if(aData.processed_logo_balancing)
                  {
                    oThis.nStepconfig = 2;
                    setTimeout(function(){
                      oThis.sMainLogoSrc = aData.processed_logo_balancing;
                      oThis.nMainLogoValue = aData.processed_balancing_score;
                      oThis.nBalancingStatus = aData.processed_balancing_lr_status;
                      oThis.nMainLogoValue2 = aData.processed_balancing_lr_score;

                      if(oThis.nMainLogoValue > 98)
                        oThis.nMainLogoValue = 98.5

                      if(oThis.nMainLogoValue < 2)
                        oThis.nMainLogoValue = 1.5

                      if(oThis.nBalancingStatus == "right")
                        {
                          if(oThis.nMainLogoValue2 == 0)
                            {
                              oThis.nMainLogoValueBalanceLR = 50
                            }
                          if(oThis.nMainLogoValue2 == 25)
                            {
                              oThis.nMainLogoValueBalanceLR = 62
                            }
                          if(oThis.nMainLogoValue2 == 50)
                            {
                              oThis.nMainLogoValueBalanceLR = 75
                            }
                          if(oThis.nMainLogoValue2 == 75)
                            {
                              oThis.nMainLogoValueBalanceLR = 87
                            }
                          if(oThis.nMainLogoValue2 == 100)
                            {
                              oThis.nMainLogoValueBalanceLR = 98.5
                            }
                        }  

                      if(oThis.nBalancingStatus == "left")
                        {
                          if(oThis.nMainLogoValue2 == 0)
                            {
                              oThis.nMainLogoValueBalanceLR = 50
                            }
                          if(oThis.nMainLogoValue2 == 25)
                            {
                              oThis.nMainLogoValueBalanceLR = 38
                            }
                          if(oThis.nMainLogoValue2 == 50)
                            {
                              oThis.nMainLogoValueBalanceLR = 25
                            }
                          if(oThis.nMainLogoValue2 == 75)
                            {
                              oThis.nMainLogoValueBalanceLR = 13
                            }
                          if(oThis.nMainLogoValue2 == 100)
                            {
                              oThis.nMainLogoValueBalanceLR = 1.5
                            }
                        }  


                      $("#idBlockProgressBarColor").animate({ width: oThis.nMainLogoValue+"%" }, 1000);

                    },1000)
                  }
                }
          });     
    }
      
    onChangeTest(nIdTest)
    {
      this.ponchangetest.emit([nIdTest, this.sTestName]); 
    }

}
