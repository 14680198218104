import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CallhttpService } from 'src/app/core/helpers/callhttp.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NotifyService } from 'src/app/core/helpers/notify.service';
import { ConfigService } from 'src/app/core/helpers/config.service';
import { isNumber } from 'util';
import { GettestService } from 'src/app/brandtest/services/gettest.service';
import { toBase64String } from '@angular/compiler/src/output/source_map';

declare var $: any;

@Component({
  selector: 'tbt-result-pvisibility',
  templateUrl: './pvisibility.component.html',
  styleUrls: ['./pvisibility.component.css']
})
export class PvisibilityComponent implements OnInit {

  @Output() ponchangetest: EventEmitter<any> = new EventEmitter();
  @Output() ponchangetosave: EventEmitter<any> = new EventEmitter();
  
  sTestName = "proportions-visibility";
  sTestType = "logo";

  sShowMode = "project";
  sVisibility = "show";
  
  aResultTestInfo:any;

  aTestPerformed:any;
  aTestPerformedTBT:any;

  nProcessPercentage = 0;

  nStepconfig = 0;

  nProcessPercentage1Distances = 0;
  nProcessPercentage2Distances = 0;
  nProcessPercentage3Distances = 0;
  nProcessPercentage4Distances = 0;

  adatos = [
    {
        "title": "Small sizes",
        "nomenclature": "mm",
        "range_min": "5",
        "range_max": "100",
        "step": "5",
        "result_nomenclature": "mt",
        "result_range_min": "0.1",
        "result_range_max": "10",
        "values": [
            0.4,
            0.8,
            1.2,
            1.6,
            1.9,
            2.3,
            2.7,
            3.1,
            3.5,
            3.9,
            4.3,
            4.7,
            5,
            5.4,
            5.8,
            6.2,
            6.6,
            7,
            7.4,
            7.8
        ],
        "reproduction_mode": [
            {
                "icon": "https://betester.com/admin-panel/public/tbt/ftp/1653395295_.png",
                "title": "Products",
                "position": "1"
            },
            {
                "icon": "https://betester.com/admin-panel/public/tbt/ftp/1653395295_.png",
                "title": "Press",
                "position": "5"
            },
            {
                "icon": "https://betester.com/admin-panel/public/tbt/ftp/1653395295_.png",
                "title": "Posters",
                "position": "9"
            }
        ]
    },
    {
        "title": "Medium sizes",
        "nomenclature": "cm",
        "range_min": "15",
        "range_max": "45",
        "step": "10",
        "result_nomenclature": "mt",
        "result_range_min": "10",
        "result_range_max": "40",
        "values": [
            11.6,
            19.4,
            27.2,
            34.9
        ],
        "reproduction_mode": []
    },
    {
        "title": "Large sizes",
        "nomenclature": "cm",
        "range_min": "50",
        "range_max": "100",
        "step": "1",
        "result_nomenclature": "mt",
        "result_range_min": "30",
        "result_range_max": "100",
        "values": [
            38.8,
            39.6,
            40.4,
            41.2,
            41.9,
            42.7,
            43.5,
            44.3,
            45,
            45.8,
            46.6,
            47.4,
            48.2,
            48.9,
            49.7,
            50.5,
            51.3,
            52,
            52.8,
            53.6,
            54.4,
            55.1,
            55.9,
            56.7,
            57.5,
            58.2,
            59,
            59.8,
            60.6,
            61.4,
            62.1,
            62.9,
            63.7,
            64.5,
            65.2,
            66,
            66.8,
            67.6,
            68.3,
            69.1,
            69.9,
            70.7,
            71.5,
            72.2,
            73,
            73.8,
            74.6,
            75.3,
            76.1,
            76.9,
            77.7
        ],
        "reproduction_mode": []
    }
]

  @Input() set pshowmode (value: string)
    {
      if(value)
        {
          this.sShowMode = value;
        }
    }

  @Input() set pvisibility (value: string)
    {
      if(value)
        {
          this.sVisibility = value;
        }
    }

  aBrandTestResults:any;

  aSizesEnabled = [];
  sMainLogoSrc = 'assets/images/no-image.jpg';
  sMainLogoPosition = "vertical";
  constructor(private getTest:GettestService,private httpService: CallhttpService, private router: Router, private route: ActivatedRoute,  private httpClient: HttpClient, private notifyService:NotifyService, private configService:ConfigService) { }
  
  ngOnInit()
    {
      let oThis = this;
      this.getTest.aBrandTestResults.subscribe(function(aData) 
        { 
          oThis.aBrandTestResults = aData;
          if(oThis.aBrandTestResults[oThis.sTestName])
            {
              let aData = oThis.aBrandTestResults[oThis.sTestName];
              oThis.aResultTestInfo = aData;
              if(aData.distance_results)
                {
                  let aDistancesResult = aData.distance_results;
                  oThis.sMainLogoSrc = aData.logo_without_border;

                  oThis.sMainLogoPosition = aData.position_logo;

                  /* TO PUT INSIDE */
                  //console.log(this.adatos);

                  for (let rSize of aDistancesResult)
                    {
                      /*calculo la poscion de los reproduction mode*/
                      let rReproductionMode = [{},{},{},{},{},{},{},{},{},{}];
                      for(var rAuxReprMode of rSize.reproduction_mode)
                        {
                          for (var i = 1; i < 11; i++)
                            {
                              if(parseInt(rAuxReprMode.position) == i)
                                {
                                  rReproductionMode[i-1] = rAuxReprMode;
                                  break;
                                }
                            }
                        }

                      /* calculo la posicion dela barra de resutados */
                      let rAuxSize = {
                        /* fixed values */
                        "title" : rSize.title,
                        "nomenclature" : rSize.nomenclature,
                        "reproduction_mode" : rReproductionMode,
                        "values" : rSize.values,

                        "size_range_min" : parseInt(rSize.range_min),
                        "size_range_max" : parseInt(rSize.range_max),
                        "size_step" : parseInt(rSize.step),

                        "result_nomenclature" : rSize.result_nomenclature,
                        "result_value_min" : rSize.result_range_min,
                        "result_value_max" : rSize.result_range_max,


                        /* variable values */
                        "current_size_position" : 0,
                        "current_bar_position" : 0,
                        "current_bar_value" : 0,
                      };

                      rAuxSize = oThis.calculatePositionBarAndSize(rAuxSize);

                      oThis.aSizesEnabled.push(rAuxSize);
                    }
                }    

            }


            setTimeout(function(){
              $('.idBlockToolTipHelp').tooltip();
            },500);
          });     





    } 

  onChangeTest(nIdTest)
    {
      this.ponchangetest.emit([nIdTest, this.sTestName]); 
      this.sMainLogoSrc = 'assets/images/no-image.jpg';
      this.aSizesEnabled = [];
    }



  calculatePositionBarAndSize(rSize)
    {
      let aGetValuesResult = rSize.values; 
      let nFirstValue = (aGetValuesResult[rSize.current_size_position]); 
      let nMaxValueResult = (rSize.result_value_max);
      let nPositionBar = Math.floor((nFirstValue * 100) / parseFloat(nMaxValueResult));

      if(nPositionBar > 95) { nPositionBar = 95; }
      rSize.current_size_value = rSize.size_range_min + (rSize.size_step * (rSize.current_size_position));
      rSize.current_bar_position = nPositionBar;
      rSize.current_bar_value = nFirstValue.toFixed(1);

      return rSize;

    } 

  onClockInChangeSize(rSize, sType)
    { 
      let aValues = rSize.values;
      let nCurrentSizePosition = rSize.current_size_position;

      if(sType == "more" && nCurrentSizePosition < (aValues.length-1))
        {
          rSize.current_size_position = rSize.current_size_position + 1;
        }

      if(sType == "minus" && nCurrentSizePosition > 0)
        {
          rSize.current_size_position = rSize.current_size_position - 1;
        }

        this.calculatePositionBarAndSize(rSize);
        console.log(rSize.current_size_position);
    } 




}
