import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { UsersService, User } from './core/services/users.service';
import { AuthService } from './core/services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentService } from 'ngx-cookieconsent';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  
  title = 'tbt';
  aLoginUser:User;

  bShowHeader = true;
  bShowFooter = true;
  bShowYellowBackground = true;
  sLastUrl = "";

  constructor(public authService:AuthService, private router: Router, public translate:TranslateService)
    {
      this.authService.currentUser.subscribe(x => this.aLoginUser = x);
      translate.addLangs(['en', 'es']);  
      if (localStorage.getItem('locale')) {  
        const browserLang = localStorage.getItem('locale');  
        translate.use(browserLang.match(/en|es/) ? browserLang : 'en');  
      } else {  
        localStorage.setItem('locale', 'en');  
        translate.setDefaultLang('en');  
      }  
    } 

  ngOnInit()
    {
      this.router.events.subscribe((url:any) => this.onChangeUrl(url));
    }

  onChangeUrl(url)
    {
      if(this.sLastUrl != this.router.url)
        {
          this.sLastUrl = this.router.url
          //console.log(this.router.url);

          //if landing hide footer and header
          /*if(this.sLastUrl == "/" || this.sLastUrl == "/login" ||this.sLastUrl == "/register" || this.sLastUrl == "/forgotpassword")
            {
              this.bShowHeader = false;
              this.bShowFooter = false;
              this.bShowYellowBackground = true;
            }
          else
            {*/
              this.bShowHeader = true;
              this.bShowFooter = true;
              this.bShowYellowBackground = false;
            /*}*/  

          //if show report hide footer and header
          var res = this.sLastUrl.substring(0, 4);
          if(res == "/rep")
            {
              this.bShowHeader = false;
              this.bShowFooter = false;
            }


        }
    }  

  onClickLogOut()
    {
      this.authService.logout();
    } 


}
