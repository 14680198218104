import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-analizamos',
  templateUrl: './analizamos.component.html',
  styleUrls: ['./analizamos.component.scss']
})
export class AnalizamosComponent implements OnInit {

  btnArray = [
    './../../../assets/svg/barra-02.svg',
    './../../../assets/svg/barra-03.svg',
    './../../../assets/svg/barra-04.svg',
    './../../../assets/svg/barra-05.svg',
    './../../../assets/svg/barra-06.svg'
  ];
  lineArray = [
    './../../../assets/svg/lines/line-02.svg',
    './../../../assets/svg/lines/line-03.svg',
    './../../../assets/svg/lines/line-04.svg',
    './../../../assets/svg/lines/line-05.svg',
    './../../../assets/svg/lines/line-06.svg'
  ];
  lineBigArray = [
    './../../../assets/svg/lines/lineBig-02.svg',
    './../../../assets/svg/lines/lineBig-03.svg',
    './../../../assets/svg/lines/lineBig-04.svg',
    './../../../assets/svg/lines/lineBig-05.svg',
    './../../../assets/svg/lines/lineBig-06.svg'
  ];
  textArray = [
    {
      title: "Naming",
      text: "Now you can know the interpretation, pronunciation and positive or negative meanings of a brand name by country or region, as well as its legal viability, before investing in its development (EXPECT IT SOON)"
    },
    {
      title: "Shape",
      text: "The analysis of the body and contour of a logo allows to determine how easy or difficult its identification will be and how expensive or economical its reproduction will be (AVAILABLE NOW)"
    },
    {
      title: "Color", 
      text: "It compares the color of a logo with different backgrounds, analyzes its weaknesses and strengths in the chromatic range, determines the results of a good or bad combination (WAIT FOR IT SOON)"
    },
    {
      title: "Proportions",
      text: "With this test you will be able to know the density and balance, the minimum reproduction sizes and the optimal visibility distances (WAIT FOR IT SOON)"
    },
    {
      title: "Competitors",
      text: "In-depth analysis of the color of your brand and its competitors in the web environment and in the category to which they belong (EXPECT IT SOON)"
    },
    {
      title: "Simulation",
      text: "Visual representation and data on the behavior of your logo in digital reproductions as well as in different physical materials (EXPECT IT SOON)"
    }
  ];
  lineActive = './../../../assets/svg/lines/line-02.svg';
  lineBigActive = './../../../assets/svg/lines/lineBig-02.svg';
  btnActiveIcon = './../../../assets/svg/barra-02.svg';
  btnActive = 1;
  sSection = "";

  constructor(private authService:AuthService, private route: ActivatedRoute, public translate:TranslateService) { }

  ngOnInit(): void {
    let oThis = this;
    this.route.paramMap.subscribe(params => {
      this.sSection = params.get("section")
      if(this.sSection!=undefined && this.sSection != "-1")
        {
            oThis.changeContent(oThis.btnArray, Number(oThis.sSection));
        }
    });    
  }

  changeContent(btnArray:any, index: any) {
    const that = this;
    const line = document.getElementById("analiz__curve-normal");
    const lineBig = document.getElementById("analiz__curve-big");
    const title = document.getElementById("analiz__data-title");
    const text = document.getElementById("analiz__data-text");
    const btn = document.getElementById("analiz__data-btn");
    const icon = document.getElementById("analiz__data-icon");

    title.classList.add("hide");
    text.classList.add("hide");
    btn.classList.add("hide");
    line.classList.add("hide");
    lineBig.classList.add("hide");
    icon.classList.add("hide");

    setTimeout( function(){ 
      title.classList.remove("hide");
      text.classList.remove("hide");
      btn.classList.remove("hide");
      line.classList.remove("hide");
      lineBig.classList.remove("hide");
      icon.classList.remove("hide");

      that.btnActive = (index + 1); 
      that.lineActive = that.lineArray[index]!=undefined?that.lineArray[index]:"./../../../assets/svg/lines/line-01.svg" ;
      that.lineBigActive = that.lineBigArray[index]!=undefined?that.lineBigArray[index]:"./../../../assets/svg/lines/lineBig-01.svg" ;
      that.btnActiveIcon = btnArray[index]!=undefined?btnArray[index]:btnArray;
    }, 300);
  }

}
